<template>
<div style="background-image: url(/assets/images/sfondo.jpg);background-size: cover;background-attachment: fixed; width: 100vw; height: 100vh;">
    <div v-if="!sendemail" class="card col-12 box-position login-box p-5" >
    <div v-if="error" class="card text-center py-4 m-0 ">
                <h3 class="text-danger fw-bold">{{ texterror }}</h3>
                </div>
    <h3 class="text-center text-primary fw-bold py-4">{{ $t('credential-resetPsw-Title') }}</h3>
    <div class="card-body">
        <form class="form form-material d-flex flex-column" style="row-gap: 40px; display:flex!important;" id="new-password-form" @submit.prevent="sendPsw()">
            <div class="form-group d-flex flex-column justify-content-center flex-lg-row gap-5"  style="display:flex!important;">
                    <div class="col-12 col-lg-5">
                        <label for="password">{{ $t('credential-resetPsw-newPSW') }}</label>
                        <div class="d-flex" style="display:flex!important;">
                                    <input id="password" type="text"  class="form-control" v-model="password"
                                required autocomplete="off" placeholder="Enter your password" @input="validatePsw()">
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <label for="confirmPassword">{{ $t('credential-resetPsw-confPSW') }}</label>
                        <div class="d-flex" style="display:flex!important;">
                            <input id="confirmPassword" type="text" class="form-control" v-model="confirmPassword"
                                required autocomplete="off" placeholder="Confirm your password"  @input="validatePsw()">
                        </div>
                        <span v-if="pswError" class="error text-danger">{{ pswError }}</span>
                    </div>
            </div>
            <button v-if="passcheck" style="border-radius: 15px;"  class="btn btn-outline-primary align-self-end">{{ $t('credential-resetPsw-reset') }}</button>
        </form>
    </div>
</div>
<div v-if="sendemail" class="card box-position text-center py-4 login-box">
                <h2 class="text-success fw-bold">{{ $t('credential-resetPsw-succ') }}</h2>
                <p>{{ $t('credential-goLogin') }}</p>
                <a class="btn btn-primary m-auto" href="/" style="width: 30%;">{{ $t('credential-Login') }} </a>
            </div>
</div>
</template>

<script>
export default {
    data(){return{
        password:'',
        confirmPassword:'',
        passcheck:false,
        sendemail:false,
        error:false,
        texterror:''
    }},
    mounted(){

    },
    methods:{
        sendPsw(){
            if(this.passcheck){
                axios.post(`${this.$baseUrl}/customers/set-new-password`, {
                    'password': this.confirmPassword,
                    "token": this.$route.params.token,
            })
            .then((response) => {
            console.log(response.data);
            if(response.data.status==200){
                this.sendemail=true
            }else{
                this.error=true
                this.texterror="Set Failed"
            }
            })
            .catch((error) => {
            console.error(error);
            });
            }
        },
        validatePsw() {
                if (this.password !== this.confirmPassword) {
                    this.pswError = 'Password do not match';
                } else {
                    this.pswError = '';
                    this.passcheck=true
        }},
    },
    watch:{
        password(){
            this.validatePsw()
        },
        confirmPassword(){
            this.validatePsw()
        }
    }
}
</script>

<style scoped>
.box-position{
    position: absolute;
    width: 50%;
     top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}
.login-box{
    border-radius: 10px;
}
input{
    background-color: transparent!important;
}
@media only screen and (max-width: 600px) {
    .box-position{
        width:100%;
    }
    .box-position>.login-box{
        margin: 0 auto;

    }
}
</style>
