<template>
    <router-view ></router-view>
</template>
<script>
import { useCookies } from "vue3-cookies";
import {useRoute, useRouter} from "vue-router";
export default {
    name: "App",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data() {
        return {
            isLoggedIn: false,
            caricamento:false,
        }
    },
    created() {
        if (window.Laravel.isLoggedin) {
            this.isLoggedIn = true
        }
        this.$gtm.enable(true);
    },
    mounted() {
        this.$router.isReady().then(() => {
            this.checkAndStoreAnalyticsSource();
        })
    },
    methods: {
        checkAndStoreAnalyticsSource() {
            const utm_source = this.$route.query.utm_source;
            const utm_campaign = this.$route.query?.utm_campaign || null;
            const utm_medium = this.$route.query?.utm_medium || null;
            const referred = document.referrer;

            if (utm_campaign) {
                this.$cookies.set('utm_campaign', utm_campaign, '7d');
            }

            if (utm_medium) {
                this.$cookies.set('utm_medium', utm_medium, '7d');
            }

            if (utm_source) {
                this.$cookies.remove('ref_origin');
                sessionStorage.setItem('ref_origin', utm_source);
            }
            else if (!utm_source && referred && referred.includes('medium')) {
                this.$cookies.remove('ref_origin');
                sessionStorage.setItem('ref_origin', 'Medium');
            }
        },
        logout(e) {
            e.preventDefault()
            this.$axios.get('/sanctum/csrf-cookie').then(response => {
                this.$axios.post('/api/logout')
                    .then(response => {
                        if(response.data.success) {
                            window.location.href = "/"
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            })
        }
    },
}


</script>
<style>
.btn-primary:hover{
    border-color: #fb9678!important;
}
.btn-primary:focus{
    box-shadow:none!important;
    color: white;
}
.btn-success:hover{
    color: #ffffff!important;
}
.btn-primary:active{
    border-color: #fb9678!important;
    background-color: #fb9678!important;
}
.btn-outline-primary:active{
    border-color: #fb9678!important;
    background-color: #fb9678!important;
}
.btn-outline-primary:hover{
    border-color: #fb9678!important;
}
.btn-outline-primary:active:hover {
    color: black!important ;

}
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track:vertical {
    background: #303030;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
    background: #888;
    border-radius: 3px;
    height:50px ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:vertical:hover {
    background: #ffffff;
}
/* Track */
::-webkit-scrollbar-track:horizontal {
    background: #303030;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
    background: #888;
    border-radius: 3px;
    height:5px ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
    background: #ffffff;
}

.topbar{
    background-color: #3c4452!important;
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-color: #03081c!important;
}
.card{
    background-color:rgba(255, 255, 255,0.1);
    border: 1px solid rgb(28, 29, 42);
}
.left-sidebar{
    background-color: #303641!important;
    padding-top: 65px!important;
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    background-color: #03081c!important;
}
.flexdisplay{
    display: flex!important;
}
.scroll-sidebar{
    height: 100%!important;
    background-color: rgba(255, 255, 255, 0.1)!important;
}
.page-wrapper{
    padding-bottom:15px!important;
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    background-color: #03081c!important;
}
#wrapper{
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    height: 100vh;
    background-color: #03081c!important;
}
.mini-sidebar .scroll-sidebar{
    background-color: rgba(255, 255, 255, 0)!important;
}
body.show-sidebar .scroll-sidebar{
    background-image: url('/assets/images/sfondo.jpg')!important;
    background-size: cover!important;
    background-attachment: fixed!important;
    background-color: none

}
.veriff-description {
        color: white!important;
    }
</style>
