<template>

    <footer v-if="promo30" :class="{
        'footer': true,
        'd-none': true,
        'd-md-flex': true,
        'justify-content-between': true,
        'promo-padding-x': promo30,
        'promo-padding-y': promo30,
        'bg-off-30': promo30
    }" @click="goBuy()">
        <div class="d-block align-items-center justify-content-center flex-grow-1">
            <div class="gradient-box-off-30" v-if="promo30">
                <span>{{ $t('Footer1-promo30') }}</span>
            </div>
        </div>
        <div class="d-none d-lg-flex justify-content-center align-items-center flex-grow-1">
            <img src="assets/images/flash-sale.png" alt="" style="width: 203px;" v-if="promo30 && !promo50">
        </div>
        <div class="d-block align-items-center justify-content-center flex-grow-1">
            <div class="gradient-box-off-30 gradient-reverse" v-if="promo30">
                <span>{{ $t('Footer2-promo30') }}</span>
            </div>
        </div>
    </footer>

    <footer class="footer px-0 d-md-none bg-off-30" v-if="promo30" @click="goBuy()">
        <marquee>
            <div class="d-flex flex-row align-items-center promo-padding-x promo-padding-y" style="width: 100% !important; display: flex !important;">
                <div class="d-flex align-items-center">
                    <div class="gradient-box-off-30">
                        <span>{{ $t('Footer1-promo30') }}</span>
                    </div>
                </div>
                <div class="flex-column align-items-center" >
                    <img src="assets/images/flash-sale.png" alt="" style="width: 203px;">
                </div>
                <div class="d-flex align-items-center">
                    <div class="gradient-box-off-30 gradient-reverse">
                        <span>{{ $t('Footer2-promo30') }}</span>
                    </div>
                </div>
            </div>
        </marquee>
    </footer>
    <footer class="footer d-none d-md-flex justify-content-center" v-if="!promo30 && !promo50">
        <div class="d-flex align-items-center">
            <img src="assets/images/OFP_white.png" alt="" style="width: 50px;">
        </div>
        <div class="w-75 d-none d-lg-flex flex-column align-items-center" @click="goShop()" >
            <h4 class="text-center text-white m-0">{{ $t('Footer1-80%') }}</h4>
            <h4 class="text-center text-white m-0">{{ $t('Footer2-80%') }}</h4>
        </div>
        <div class="d-flex align-items-center">
            <img src="assets/images/visa_logo_white.png" alt="" style="width: 50px;">
        </div>
    </footer>
    <footer class="footer px-0 d-flex flex-column align-items-center d-md-none" v-if="!promo30 && !promo50">
        <div class="d-block d-lg-none w-75 mx-auto text-center" @click="goShop()" >
            <h6 class="text-white m-0">{{ $t('Footer1-80%') }}</h6>
            <h6 class="text-white m-0">{{ $t('Footer2-80%') }}</h6>
        </div>
       <div class="w-50 mx-auto displayFlex justify-content-between">
            <div class="displayFlex align-items-center ">
                <img src="assets/images/OFP_white.png" alt="" style="width: 50px;">
            </div>
            <div class="displayFlex align-items-center ">
                <img src="assets/images/visa_logo_white.png" alt="" style="width: 50px;">
            </div>
       </div>
    </footer>
</template>

<script>
export default {
    name: "footer",
    data(){
        return{
            promo30:false,
            promo50:false
        }
    },
    methods:{
        goBuy(){
            this.$router.push({ path: '/buy-account',})
        },
        async getClient(){
            const now = new Date();
            const year = new Date().getUTCFullYear();
            const startDate30 =new Date(Date.UTC(year, 1, 21,14,30));
            const endDate30 = new Date(Date.UTC(year, 2, 7, 23, 59));
            const startDate50=new Date(year, 2,11)
            const endDate50 = new Date(Date.UTC(year, 2, 25, 23, 59));
            if(now>=startDate30 && now<=endDate30){
                this.promo30=true
                this.promo50=false
            }else if(now>=startDate50 && now<=endDate50){
                this.promo50=true
                this.promo30=false
            }
        },
        goShop(){
            this.$router.push({ path: '/buy-account',})
        }
    },
    mounted(){
        this.getClient()
    }
}
</script>

<style scoped>
.footer{
    max-height: 8.5%;
    position: fixed;
    background-image: url("/assets/images/banner-ofp-80-04.png");
    background-size: cover;
    z-index: 999;
    display: flex; /* Use flex display */
    justify-content: space-between; /* Space between the columns */
    align-items: center; /* Align items vertically */
}
div{
    cursor: pointer;
}
h4{
    font-size: 1.35rem!important;
    font-weight: 600;
    font-family: "Roboto", Sans-serif;
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}
h6{
    font-family: "Roboto", Sans-serif;
    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
}
.displayFlex{
    display: flex!important;
}
.gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3%;
    gap: 24px;

    background: linear-gradient(90deg, #FF6844 4.06%, #241D80 97.81%);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 1;
    z-index: 3;
    flex-grow: 1; /* Allow boxes to grow */
    z-index: 2; /* Keep z-index lower than the flash-sale image */
}

.gradient-box-off-30 span {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;

    color: #FFFFFF;
}
.promo-padding-x {
    padding-left: 32px;
    padding-right: 32px;
}
.bg-off-30 {
    background-image: url("/assets/images/off30_footer_bg.png");
}

.d-none.d-lg-flex {
    display: flex!important; /* Override display none on large screens */
}
@media (min-width: 992px) { /* Adjust to your breakpoint for desktop */
    .d-none.d-lg-flex {
        display: flex;
    }
}

@media (max-width: 991px) {
    .footer {
        padding-top: 10px;
        padding-bottom: 5px;
        /* Altri stili di adattamento */
    }

    .gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
        padding: 18px; /* Riduci il padding */
        /* Altri stili di adattamento */
    }

    /* Ridimensiona l'immagine flash-sale per schermi più piccoli */
    .flash-sale {
        width: 150px; /* Riduci la larghezza */
        /* Altri stili di adattamento */
    }
}

/* Media query per schermi piccoli (es. dispositivi mobili) */
@media (max-width: 767px) {
    .footer {
        padding-top: 5px;
        padding-bottom: 5px;
        /* Altri stili di adattamento */
    }

    .gradient-box-off-30, .gradient-box-off-30.gradient-reverse {
        padding: 12px; /* Riduci ancora il padding */
        /* Altri stili di adattamento */
    }

    /* Ridimensiona ulteriormente l'immagine flash-sale per schermi molto piccoli */
    .flash-sale {
        width: 100px; /* Riduci ulteriormente la larghezza */
        /* Altri stili di adattamento */
    }
}

</style>
