<template>

    <body  class="skin-default card-no-border" >
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
        <div v-if="loading" class="position-absolute top-50 start-50 translate-middle fw-bold text-uppercase text-primary">Loading</div>
        <div v-if="!loading" class="row h-100 justify-content-start">
            <div class="w-100 d-flex">
              <div class="d-flex flex-column col-12 col-lg-7">
                <div class="mt-2 ms-2 mb-5 mb-lg-0 mt-lg-5 ms-lg-5"> <img src="/assets/images/logoScritte.png" ></div>
                    <div class="w-100 d-flex flex-column align-items-center my-auto " >
                    <div class="w-100" >
                        <div v-if="error" class="text-center pb-5 ">
                            <h3 class="text-danger fw-bold">{{ texterror }}</h3>
                        </div>
                        <form  class="form-horizontal form-material d-flex flex-column gap-5 col-11 col-lg-7 mx-2 mx-lg-auto" id="loginform" @submit.prevent="logIn()" >
                            <div class="m-b-20">
                                <h1 class="text-start fw-bold text-white">Sign In</h1>
                                <p>Welcome back! Please enter your details</p>
                            </div>
                            <div class="form-group ">
                                <div class="col-xs-12">
                                    <label class="text-white mb-1">Email</label>
                                    <input class="form-control border border-1 border-white rounded" type="email" v-model="email" required placeholder="Email"> </div>
                            </div>
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <label class="text-white mb-1">Password</label>
                                    <div class="d-flex border border-1 border-white rounded pe-2 displayFlex">
                                        <input class="form-control" :type="typePsw?'password':'text'" v-model="password" required placeholder="Password">
                                        <i class="icons-Eye-Visible my-auto" style="justify-self:  end; align-self: flex-end;" @click="changeTipePsw()" ></i>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="d-flex no-block align-items-center justify-content-between">
                                        <div class="d-flex align-items-center gap-2 displayFlex">
                                            <input type="checkbox" name="" id="">
                                            <label for="">Remember credentials</label>
                                        </div>
                                        <div class="text-primary">
                                            <a href="/reset" id="to-recover" class="text-primary fw-bold"><i class="fas fa-lock m-r-5"></i> Forgot password</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="p-b-20">
                                    <button class="btn w-100 btn-lg btn-primary rounded text-white"
                                    >Log In</button>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-12 text-center fw-bold">
                                    Don't have an account? <a href="/register" class="text-primary m-l-5"><b>Sign Up</b></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
              </div>
                <div class="col-5 d-none d-lg-flex loginDx position-relative">
                    <div class="position-absolute posizioneImg w-100 rounded">
                        <img class="img-fluid" src="/assets/images/imgLoginRegister.jpg" alt="">
                    </div>
                    <div class="text-center">
                        <h1>Sign in </h1>
                        <h6>Welcome back! Please enter your details</h6>
                    </div>
                </div>
            </div>
        </div>

    </section>

</body>
</template>

<script>
import Spinner from "../components/Spinner"
    export default {
        components:{
        Spinner
    },
        data() {
            return {
                email: "",
                password: "",
                error: false,
                texterror:'',
                typePsw:true,
                loading:true
            }
        },
        mounted(){
            this.checkCredentials()
        },
        methods: {
            checkCredentials(){
                if(this.$cookies.get('login')){
                    window.location.replace('/home')
                }else{
                    if(  this.$cookies.get('emailAccount')){
                    this.loading=false
                    this.email=this.$cookies.get('emailAccount')
                }else{
                    this.loading=false
                }
                }

            },
            goBuy(){
                window.location.replace('https://ofpfunding.com/account-types/')
            },
            changeTipePsw(){
                this.typePsw=!this.typePsw
            },
            logIn(){
                axios.post(`${this.$baseUrl}/customers/login`,{
                    "email":this.email,
                    "password":this.password
                }).then((response) => {
                    console.log(response)
                    if(response.data.status==200){
                        this.error=false
                        this.$cookies.set('login', true, Infinity)
                        this.$cookies.set('emailAccount',this.email,Infinity)
                        window.Laravel.isLoggedin
                        window.location.replace('/home')
                    }else{
                        this.error=true
                        if(response.data.status==401){
                            this.texterror='Wrong Password'
                        }else if(response.data.status==400){
                            this.texterror='User not found'
                        }else if(response.data.status == 403){
                            this.texterror="User not enabled for Login"
                        }else{
                            this.texterror="User is not authorized to perform login"
                        }
                    }
                })
            .catch((error) => {
            console.error(error);
            });
            }
        },
    }
</script>
<style scoped>
.displayFlex{
    display: flex!important;
}
.form-group{
    margin-bottom:20px ;
}
input{
    background-color: transparent!important;
    padding: 10px!important;
}
.loginDx{
    background-color:rgba(255, 255, 255,0.1);
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 200px;
}
.posizioneImg{
    transform: translate(-15%,-35%);
}
</style>
