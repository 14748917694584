<template>
    <body class="skin-default card-no-border">
        <section id="wrapper" style="width: 100vw; height: 100vh; background-image: url(/assets/images/sfondo.jpg);background-size: cover;background-attachment: fixed ">
            <div v-if="!send" class="login-register box-position" style="">
                <div v-if="error" class="card text-center py-4 m-0 ">
                <h3 class="text-danger fw-bold">{{ textError }}</h3>
                </div>
            <div class="login-box card">
                <div class="card-body" >
                    <form class="form-horizontal form-material" id="loginform" @submit.prevent="sendEmail()">

                        <h3 class="text-center text-uppercase fw-bold text-primary m-b-20">Reset your Password</h3>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input class="form-control" v-model=email type="email" required placeholder="Enter Email Address"> </div>
                        </div>
                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <button  class="btn btn-success btn-lg w-100 text-uppercase waves-effect waves-light text-white" >Reset</button>
                            </div>
                        </div>

                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                Back to <a  href="/" class="text-primary m-l-5" ><b> Login</b></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            <div v-if="send" class="card box-position text-center py-4 login-box">
                <h2 class="text-success fw-bold">Request sent successfully</h2>
                <h4>Check your email to reset Password</h4>
            </div>

    </section>
        <!-- ============================================================== -->
        <!-- End Wrapper -->
        <!-- ============================================================== -->

    </body>
</template>

<script>
export default {
name: 'LostPsw',
data(){
    return{
        email:'',
        send:false,
        error:false,
        textError:''
    }
},
methods:{
    sendEmail(){
            if(this.email!=''){
                axios.post(`${this.$baseUrl}/customers/reset-password`, {
                    "customer_email": this.email
            })
            .then((response) => {
            console.log(response);
            if(response.data.status===200){
                this.send=true
                this.error=false
            }else{
                this.error=true
                this.textError="Reset Failed"
            }
            })
            .catch((error) => {
            console.error(error);
            });
            }
        },
}
}
</script>

<style scoped>
.box-position{
    position: absolute;
    width: 50%;
     top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}
input{
    background-color: transparent!important;
}
.login-box{
    border-radius: 10px;
}
@media only screen and (max-width: 600px) {
    .box-position{
        width:100%;
    }
    .box-position>.login-box{
        margin: 0 auto;

    }
}
</style>
