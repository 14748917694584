<template>
    <nav class="navbar top-navbar navbar-expand-md navbar-dark" style="background-color: rgba(255, 255, 255, 0.1);">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header d-none d-md-flex" style="background-color: transparent;">
            <ul class="navbar-nav me-auto">
                <!-- This is  -->
                <li class="nav-item">
                    <a :class="stop || stopnav?'disabled':''"
                       class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"
                       href="javascript:void(0)"><i class="ti-menu"></i></a></li>
                <li class="nav-item">
                    <a
                        class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"
                        href="javascript:void(0)"><i class="icon-menu"></i></a></li>
            </ul>
            <a class="navbar-brand" href="/home">
                <!-- Logo icon --><b>
                <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                <!-- Dark Logo icon -->

                <img src="/assets/images/OFP.png" alt="" style="width: 40%;">
                <!-- Light Logo icon -->

            </b>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
      <div class="d-flex flex-column w-100 " v-if="isLoggin">
        <div class="navbar-collapse py-2">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav me-1 me-lg-auto d-block d-md-none" >
                <!-- This is  -->
                <li class="nav-item nav-toggler ">
                    <a :class="stop || stopnav?'disabled':''"
                       class="nav-link d-block d-md-none waves-effect waves-dark"
                       href="javascript:void(0)"><i class="ti-menu"></i></a></li>
                <li class="nav-item sidebartoggler">
                    <a
                        class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"
                        href="javascript:void(0)"><i class="icon-menu"></i></a></li>
            </ul>
            <ul class="navbar-nav my-lg-0 me-2 me-lg-5 ms-0 ms-lg-auto d-flex gap-2 gap-xl-4 align-items-center" style="display: flex!important;">
                <bannerFidelity ref="bannerFidelity" />
                <li class="nav-small-cap d-none d-lg-block ">
                    <a  class="btn btn-primary text-white mx-2 me-1 text-center p-2" href="/buy-account" style="border-radius: 10px;">{{ $t('navbar-buy') }}</a>
                </li>
                 <li role="button">
                        <div role="button" class="btn btn-outline-primary position-relative" @click="goShopCheckout()" >
                            <i class="mdi mdi-cart fs-5" style="position: relative;"></i>
                            <span v-if="itemsInCar!=''" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
                                {{ itemsInCar }}
                                <span class="visually-hidden">unread messages</span>
                            </span>
                        </div>
                </li>
                <li class="nav-small-cap postion-relative ">
                    <img @click="strategyOpen=true" role="button" class="" src="/assets/images/violation.png" alt="">
                    <div v-if="strategyOpen" class="position-absolute boxPosition p-4">
                        <div class="">
                            <div class=" displeyFlex d-flex justify-content-between">
                                <h1 class="fs-3 text-primary" >{{ $t('navbar-Strategies-Title') }}</h1>
                                <button @click="strategyOpen=false" class="btn btn-outline-white">X</button>
                            </div>
                            <hr>
                            <div class="d-flex align-items-center flex-column">
                                <a href="https://www.youtube.com/watch?v=EdODxOFxi2k" class="d-none d-lg-block" target="_blank">
                                    <img src="https://img.youtube.com/vi/EdODxOFxi2k/hqdefault.jpg" alt="Anteprima video">
                                </a>
                                <p>{{ $t('navbar-Strategies-Text1') }} <a target="_blank" href="https://www.youtube.com/watch?v=EdODxOFxi2k">https://www.youtube.com/watch?v=EdODxOFxi2k</a></p>
                                <p>{{ $t('navbar-Strategies-Text2') }}</p>
                                <p>{{ $t('navbar-Strategies-Text3') }}</p>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle py-0 pt-1 text-white" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-bell-ring-outline fs-3 position-relative">
                            <span v-if="newToRead==true" class="position-absolute top-0 start-100 translate-middle p-1 bg-primary border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                            </span>
                        </i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-center dropdown-menu-lg-end " style="width: 50vw;">
                       <div class="p-4 d-flex justify-content-between">
                            <h4 class="text-white fw-bold ">Notifications ({{ notifNew }})</h4>
                            <p role="button" class="text-primary">&#10003;&#10003; Check all read</p>
                       </div>
                        <li v-for="not in notifications" :key="not" class="d-flex" style="display: flex!important; width: 100%;">
                            <div class="dropdown-item text-white d-flex gap-4  align-items-center" aria-expanded="false"  style="display: flex!important;">
                                <font-awesome-icon icon="fa-solid fa-envelope" class="text-primary" v-if="!not.read" />
                                <font-awesome-icon icon="fa-solid fa-envelope-open" v-if="not.read" />
                                <div class="d-flex flex-column gap-2"  style="display: flex!important; width: 100%;">
                                    <div class="d-flex justify-content-between">
                                        <h6 class="hide-menu mb-0">{{not.title}}</h6>
                                        <p class="mb-0">{{ FormatData(not.createdAt) }}</p>
                                    </div>
                                    <p class="mb-0 w-100 d-none d-lg-block text-dark">{{ not.message.substr(0,50) }} ...</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li> -->
                <div class="custom-dropdown-container">
                    <div @click="toggleDropdown" class="custom-dropdown">
                        <img :src="'/assets/images/' + selectedLanguage + '.png'" class="rounded-circle me-2" alt="Flag" style="width: 20px; height: 20px;">
                        <!-- <span>{{ selectedLanguage.name }}</span> -->
                    </div>
                    <ul v-show="showDropdown" class="dropdown-list">
                    <li v-for="lang in languages" :key="lang.code" @click="saveLanguagePreference(lang)">
                        <img :src="'/assets/images/' + lang.code + '.png'" class="rounded-circle me-2" alt="Flag" style="width: 20px; height: 20px;">
                        {{ lang.name }}
                    </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center dropdown-toggle position-relative" role="button" data-bs-toggle="dropdown" style="display: flex!important;">
                    <div class="box-icona nav-small-cap me-2">
                        <div v-if="image== undefined || image == ''" class=" d-flex align-items-center justify-content-center user-img bg-success text-white fs-3 text-uppercase fw-bold lettershadow" style="display: flex!important; "> {{ profileLetter }}</div>
                        <img v-else  :src="image" alt="user-img"  style="display: flex!important;" aria-expanded="false" class="d-flex align-items-center justify-content-center img-circle user-img">
                    </div>
                    <li class="nav-item d-none d-md-block">
                        {{ client ? (client.firstName || '') : '' }} {{ client ? (client.lastName || '') : '' }}
                    </li>

                    <ul class="dropdown-menu" style="right: 0!important;">
                        <li> <a class="dropdown-item text-white" @click="User()"
                                aria-expanded="false"><font-awesome-icon icon="fa-solid fa-user-gear "  /><span
                                class="hide-menu">{{$t('navbar-settings')}}</span></a>
                        </li>
                        <li>
                            <a class="dropdown-item text-white" target="_blank" @click="Mt(4)" href="https://ofpfunding.com/faq/metatrader-4/"><font-awesome-icon icon="fa-solid fa-cloud-arrow-down" />{{ $t('navbar-MT4') }}</a>
                            <a class="dropdown-item text-white" target="_blank" @click="Mt(5)" href="https://ofpfunding.com/faq/metatrader-5/"><font-awesome-icon icon="fa-solid fa-cloud-arrow-down" />{{ $t('navbar-MT5') }}</a>
                        </li>
                        <li><hr></li>
                        <li> <a class="dropdown-item text-white"  @click="Logout()"
                                aria-expanded="false"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket " class="text-danger"/><span class="hide-menu text-danger">{{ $t('navbar-Logout') }}</span></a>
                        </li>
                    </ul>
                </div>
            </ul>
        </div>
      </div>
    </nav>
</template>

<script>
import bannerFidelity from './bannerFidelity'

export default {
    components:{bannerFidelity},
    name: "navbar",
    data
       () {
        return {
            showDropdown: false,
            selectedLanguage: {},
            strategyOpen:false,
            mtPlat: false,
            client: {},
            profileLetter: '',
            image: '',
            id: '',
            order: [],
            isLoggin:false,
            itemsInCar:'',
            languages: [
                { code: 'en', name: 'English', formatData: 'en-US' },
                { code: 'it', name: 'Italiano', formatData: 'it-IT'  },
                { code: 'es', name: 'Español', formatData: 'es-ES'  },
                { code: 'pt', name: 'Português', formatData: 'pt-PT'  },
            ],
        }
    },
    props:{
        stop:Boolean,
        stopnav:Boolean,
    },
    watch: {
        stop() {
            console.log(this.stop)
        },
        stopnav() {
            console.log(this.stopnav)
        },
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        saveLanguagePreference(value){
            this.selectedLanguage=value.code
            this.$i18n.locale=value.code
            console.log(this.$cookies.get('language'))
            axios.patch(`${this.$baseUrl}/customers/update/details?customer_id=${this.id}`,{language: value.code}).then((response)=>{
                console.log(response)
            })
            this.showDropdown=false
        },
        Logout() {
            this.$cookies.keys().forEach(key => {
                if (key != 'emailAccount' || key !='language')
                    this.$cookies.remove(key)
            });
            this.$cookies.remove()
            window.location.replace('/')
        },
        User(){
            window.location.replace('/user')
        },
        Mt(n){
           window.open(`https://ofpfunding.com/faq/metatrader-${n}/`)
        },
        setClient() {
            this.isLoggin=this.$cookies.get('login')
            console.log(this.isLoggin)
            console.debug('setClient @ navbar');
            this.client = this.$cookies.get('client');

            if (this.client) {
                if(this.client.language && this.client.language.length!=''){
                    this.$i18n.locale = this.client.language.toLowerCase()
                    this.selectedLanguage=this.$i18n.locale
                }else{
                    this.$i18n.locale ='en';
                    this.selectedLanguage=this.$i18n.locale
                }

                let firstNameInitial = this.client && this.client.firstName ? this.client.firstName[0] : '';
                let lastNameInitial = this.client && this.client.lastName ? this.client.lastName[0] : '';

                this.profileLetter = firstNameInitial + lastNameInitial;
                this.image = this.client.image;
                this.id = this.client._id;
                this.getCart(this.id)
                this.$refs.bannerFidelity?.setClient();
            } else {
                this.profileLetter = '';
                this.image = '';
                this.id = '';
            }
        },
        getCart(id){
            try {
                axios.get(`${this.$baseUrl}/ecommerce/cart/getCart?customer_id=${id}`).then((response)=>{
                    let cart=response.data
                    if(cart.items.length>0){
                        this.itemsInCar=cart.items.length
                    }else{
                        this.itemsInCar=''
                    }
                })
            } catch (error) {

            }
        },
        goShopCheckout(){
            if(this.itemsInCar!=''){
                this.$router.push({ path: '/checkout',})
            }else{
                this.$router.push({ path: '/buy-account',})
            }
        },
    },
    beforeMount() {
        this.setClient()
    },
    expose: [ 'setClient' ]
}
</script>

<style scoped>
.user-img {
    border-radius: 100%;
    width: 50px !important;
    height: 50px;

}

select{
    background-color: #462f39!important ;
    color: white!important;
    border: none!important;
}
.iconaFoto {
    display: none;
    opacity: 100%;
}

.dropdown-menu {
    background-color:  rgba(1, 9, 22, 1)!important;
}

.m10 {
    margin-right: 10px;
}

.boxPosition{
    width: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #010916;
}
.displeyFlex{
    display: flex!important;
}
.custom-dropdown-container {
  position: relative;
}

.custom-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  top: 150%;
  left: -10px;
  width: max-content;
  background-color: #3e2e39;
}

.dropdown-list li {
  cursor: pointer!important;
  padding: 5px;
}

@media (max-width: 992px){
    .boxPosition{
        width: 90%;
        transform: translate(-50%, 0%);
        background-color: #010916;
        height: 75vh;
        overflow-y:auto ;
        margin: auto;
        z-index: 999;
    }
}
</style>
