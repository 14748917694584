<template>

    <div id="main-wrapper " >
        <Spinner v-if="loading" />
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar backgroundImage"  :class="modalOn||loading?'opacity-25':''"  >
            <Navbar ref="navbarRef" />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar backgroundImage"  :class="modalOn||loading?'opacity-25':''" >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" style="background-color: rgba(255, 255, 255, 0.1);">
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->

        <div class="page-wrapper backgroundImage" :class="modalOn || showRank?'opacity-25':''" s>

            <PriceCarousel />
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <bannerKyc/>
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                    <!--modal PROMO-->

                    <!-- Button trigger modal -->
                    <button id="promo30" type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        PROMO30
                    </button>

                    <!-- Modal -->
                    <div class="modal fade p-0" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                        <div class="modal-dialog d-lg-none" >
                            <div class="modal-content" style="background-color: transparent!important;">
                            <div class="modal-body" v-if="client.language">
                                <button style="background-color:transparent!important;color: white!important; position: absolute; left: 90%;" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">x</button>
                                <img  @click="goBuy()" class="img-fluid" :src="'assets/images/Banner'+ client.language +'.png'" alt="">
                            </div>
                            <div class="modal-body" v-else>
                                <button style="background-color:transparent!important;color: white!important; position: absolute; left: 90%;" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">x</button>
                                <img @click="goBuy()" src="assets/images/Banneren.png" alt="">
                            </div>
                            </div>
                        </div>
                        <div class="modal-dialog d-none d-lg-block" style="max-width: 40%!important;">
                            <div class="modal-content" style="background-color: transparent!important; text-align: center;">
                            <div class="modal-body" v-if="client.language">
                                <button style="background-color:transparent!important;color: white!important; position: absolute; right: 20px;" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">x</button>
                                <img  @click="goBuy()" class="w-100" :src="'assets/images/Banner'+ client.language +'.png'" alt="">
                            </div>
                            <div class="modal-body" v-else @click="goBuy()">
                                <button style="background-color:transparent!important;color: white!important; position: absolute; right: 20px;" id="closePromo" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">x</button>
                                <img class="w-100" src="assets/images/Banneren.png" alt="">
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- END modal PROMO-->
                <!--- Start Content --->
                <div v-if="!loading" class="w-100 mx-auto row " style="margin-bottom: 100px;"  >



                    <!--Profile info-->
                    <div class="row">
                        <div class="col-12 ">
                        <div class="my-2">
                            <div class="">
                                <div class="d-flex align-items-center gap-3 mt-2" style="display: flex!important;">
                                    <h1 class="text-white fw-bold">Dashboard</h1>
                                    <i @click="showDelay()" class="curPointer align-self-start mdi mdi-information-outline"></i>
                                </div>
                                <div v-if="delay" class="text-white">
                                    <span class=" fw-bold text-danger">{{ $t('Warning') }}:</span> {{ $t('profile-delay') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <!--4 box info trading-->
                    <div class="row d-xl-flex justify-content-between p-0 m-auto m-lg-0" v-if="accountActive.length!=0 && !onlyOnDemandAccounts">
                        <div class=" col-12 ">
                            <div class="card " >
                                <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="!nextPayout || nextPayout.includes('Invalid')">{{ $t('profile-noDate') }}</h2>
                                                <h2 v-else class="my-auto fw-bold text-primary">{{ formatData(nextPayout.slice(nextPayout.indexOf(' '))) }}</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10">
                                                <h6 class="text-white font-medium">{{ $t('profile-DatePayout') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <!--Stats full screen-->
                    <div class=" row d-none d-xl-flex justify-content-between p-0 m-auto m-lg-0" >
                    <div class="col-sm-3 " >
                        <div class="card lossBox"  id="card-primary">
                            <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class="px-0">
                                        <h3 v-if="profitAccounts" class="fw-bold text-primary">{{ (profitAccounts/accountActive.length).toFixed(2)}}</h3>
                                        <h3 v-else class="fw-bold text-primary">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-APF') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 "  >
                        <div class="card expBox"   id="card-aqua">
                            <div class="card-body flex-column active">
                                <div class="text-primary mt-3">
                                    <div class="px-0">
                                        <h3 v-if="expAccounts" class="fw-bold">{{ currSymbol }} {{ (expAccounts/accountActive.length).toFixed(2)}}</h3>
                                        <h3 v-else class="fw-bold">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-AE') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 " >
                        <div class="card winBox"  id="card-pink">
                            <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class="px-0">
                                        <h3 v-if="wonTradesPercent" class="fw-bold text-primary">{{ (wonTradesPercent/accountActive.length).toFixed(2)}} %</h3>
                                        <h3 v-else class="fw-bold text-primary">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-AWR') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3" >
                        <div class="card totalBox" id="card-azure">
                            <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class=" px-0">
                                        <h3 v-if="rr>0" class="fw-bold text-primary">1 : {{ (rr/accountActive.length).toFixed(2) }}</h3>
                                        <h3 v-else class="fw-bold text-primary">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-ARR') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <!--Fine stas per full screen-->
                  <!--Stats carousel mobile-->
                  <div id="carouselExampleInterval" class="carousel slide d-sm-block d-xl-none " data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="10000">
                                <div class="card lossBox"   id="card-primary">
                                    <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class="px-0">
                                        <h3 v-if="profitAccounts" class="fw-bold text-primary">{{ (profitAccounts/accountActive.length).toFixed(2)}}</h3>
                                        <h3 v-else class="fw-bold text-primary">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-APF') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="2000">
                                <div class="card expBox"  id="card-aqua">
                                    <div class="card-body flex-column active">
                                <div class="text-primary mt-3">
                                    <div class="px-0">
                                        <h3 v-if="expAccounts" class="fw-bold">{{ currSymbol }} {{ (expAccounts/accountActive.length).toFixed(2)}}</h3>
                                        <h3 v-else class="fw-bold">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-AE') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                            <div class="carousel-item">
                                <div class="card winBox"  id="card-pink">
                                    <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class="px-0">
                                        <h3 v-if="wonTradesPercent" class="fw-bold text-primary">{{ (wonTradesPercent/accountActive.length).toFixed(2)}} %</h3>
                                        <h3 v-else class="fw-bold text-primary">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-AWR') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                            <div class="carousel-item">
                                <div class="card totalBox" id="card-azure">
                                    <div class="card-body flex-column active">
                                <div class=" mt-3">
                                    <div class=" px-0">
                                        <h3 v-if="rr" class="fw-bold text-primary">1 : {{ (rr/accountActive.length).toFixed(2) }}</h3>
                                        <h3 v-else class="fw-bold text-primary">-</h3>
                                    </div>
                                </div>
                                <div class="d-flex no-block al m-r-15ign-items-center">
                                    <div class="m-t-10">
                                        <h5 class="text-white font-medium">{{ $t('profile-ARR') }}</h5>
                                        <p class='text-dark'>{{ $t('profile-ActAcc') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('Prev') }}</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('Next') }}</span>
                        </button>
                    </div>
                    <!--End Stats carousel mobile-->
                    <!--end 4 box-->
                    <!--Area Chart-->
                    <div v-if="accountActive.length!=0" class="row m-auto d-none d-md-block mt-2 px-2" id="chart">
                        <h5 class="text-start my-2 text-white">{{ $t('profile-AccsBalance') }}</h5>
                        <div class="loading" v-if="!showAreaChart">
                            {{ $t('Load') }}<span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span>
                        </div>
                        <div class="card">
                             <AreaChart v-if="showAreaChart"/>
                        </div>
                    </div>
                    <div v-else class="row m-auto d-none d-md-block mt-2 px-2" id="chart">
                        <h5 class="text-start my-2 text-white">{{ $t('profile-AccsBalance') }}</h5>
                        <p>{{ $t('profile-NodataChart') }}</p>
                    </div>
                    <div v-if="accountActive.length!=0" class="d-sm-block d-md-none text-center mb-5" >
                                <a href="/chartareamobile" class="btn btn-outline-primary w-100">{{ $t('ShowChart') }}</a>
                    </div>
                    <!--End areaChart-->
                    <!--List Accounts-->
                    <div class="col-12 p-0 mt-3" id="active" v-if="accountActive.length>0 || accountInactive.length>0">
                       <div class="w-100">
                        <div class=" ms-2 card-body p-0">
                            <div class=" d-flex justify-content-between align-items-center card-body px-0" style="display: flex!important;">
                                <div>
                                    <h3 class="fw-bold text-white m-0">Accounts</h3>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" v-model="this.client.hide_inactive_accounts" @change="hideInactiveAccounts">
                                            <span class="form-check-sign">
                                                <span class="check">{{ $t('profile-HideInact') }}</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class=" rounded"  >
                                    <div class="d-flex gap-5 justify-content-center h-100 align-items-center mt-2"  >
                                        <div class=" w-100">
                                            <div class="card-body d-flex align-items-center justify-content-center" >
                                                <a  href="/buy-account" class="btn btn-primary text-center rounded"  >+ {{ $t('NewAcc') }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-start my-3 flex-wrap overflow-auto">
                                <table class="table" style=" overflow:auto ;">
                                    <thead>
                                        <tr class="text-white fw-semibold">
                                        <th scope="col">{{ $t('AccNumb') }}</th>
                                        <th scope="col">{{ $t('Status') }}</th>
                                        <th scope="col">{{ $t('Password') }}</th>
                                        <th scope="col">{{ $t('Platform') }}</th>
                                        <th scope="col">{{ $t('Server') }}</th>
                                        <th scope="col">{{ $t('Type') }}</th>
                                        <th scope="col">{{ $t('Currency') }}</th>
                                        <th scope="col">{{ $t('InitBalance') }}</th>
                                        <th scope="col">{{ $t('More') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="account in accountActive" :key="account._id_" class="text-white">
                                        <th scope="row">{{ account.username}}</th>
                                        <td ><button :class="account.status=='active'?'btn-success':'btn-danger'" class="text-capitalize text-white rounded-5 btn cur" >{{account.status}}</button></td>
                                        <td>{{account.password}}</td>
                                        <td class="text-uppercase">{{account.platform}}</td>
                                        <td>{{account.server}}</td>
                                        <td>{{account.type_account}}</td>
                                        <td>{{account.currency}}</td>
                                        <td>{{ parseInt(account.initial_balance).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                                        <td>
                                            <router-link :to="'/trial/' + account._id + '?currency=' + account.currency" :class="account.status=='active'?'btn-success':'btn-primary'" class="card-link btn text-white w-95 open rounded"  >
                                                {{ $t('Open') }}
                                            </router-link>
                                        </td>
                                        </tr>
                                        <template v-if="!this.client.hide_inactive_accounts">
                                        <tr  v-for="account in accountInactive" :key="account._id_" class="text-white">
                                        <th scope="row">{{ account.username}}</th>
                                        <td ><button  class="text-white rounded-5 btn btn-danger cur" >Inactive</button></td>
                                        <td>{{account.password}}</td>
                                        <td class="text-uppercase">{{account.platform}}</td>
                                        <td>{{account.server}}</td>
                                        <td>{{account.type_account}}</td>
                                        <td>{{account.currency}}</td>
                                        <td>{{ parseInt(account.initial_balance).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                                        <td>
                                            <router-link :to="'/trial/' + account._id + '?currency=' + account.currency" class="card-link btn btn-primary text-white w-95 open rounded"  >
                                               {{ $t('Open') }}
                                            </router-link>
                                        </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                           </div>
                        </div>
                       </div>
                    </div>
                    <!--End List Accounts-->
                </div>
                <!--- End Content --->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!--Pop up no accounts profile-->
        <RankModal :showRank="showRank" @close="closeRank"/>

        <!--End Pop up no accounts profile-->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer />
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import AreaChart from "./AreaChart";
import PriceCarousel from "./PriceCarousel";
import Spinner from './Spinner'
import bannerKyc from './statics/bannerKyc';
import RankModal from "./RankModal";
export default {
    name: "Profile",
    components: {Footer, Navbar, Sidebar, AreaChart, PriceCarousel,Spinner,bannerKyc,RankModal},
    data(){
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 1000000);
        const key = `${timestamp}-${random}`;
        return{
            profileLetter:'',
            rr:0,
            accountLen:0,
            idAccounts:[],
            accountActive:[],
            accountInactive:[],
            accountIdColl:[],
            client:{},
            account:null,
            openInactive:false,
            loading:true,
            key,
            nextPayout:'',
            minday:0,
            payTotal:'',
            mediaElement:0,
            popupVisible:false,
            profitAccounts:0,
            expAccounts:0,
            badTrades:[],
            wonTradesPercent:0,
            giorno:'',
            currAccounts:[],
            currSymbol:'',
            modalOn:false,
            showAreaChart: false,
            showRank:false,
            delay:false,
            onlyOnDemandAccounts: false
        }
    },
    methods:{
        goBuy(){
            document.getElementById("closePromo").click()
            this.$router.push({ path: '/buy-account',})
        },
        showDelay(){
            this.delay= !this.delay
        },
        closeRank(){
            this.showRank=false
        },
        watchRank(value){
            console.log(value)
            this.showRank=value
        },
         trovaValorePiuFrequente(array) {
            let valoreMassimo = null;
            let conteggioMassimo = 0;
            for (let i = 0; i < array.length; i++) {
                let valore = array[i];
                let conteggio = array.filter((v) => v === valore).length;

                if (conteggio > conteggioMassimo) {
                valoreMassimo = valore;
                conteggioMassimo = conteggio;
                }
            }

            let currSymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: valoreMassimo }).formatToParts(1)[0].value;

            return currSymbol;
            },
        // get The Profile Informartion
        async infoAccount() {
            try {
                let customer_email = this.$cookies.get('emailAccount');
                let affiliateFromCookie = this.$cookies.get('pap_custom_tracker');

                const response = await axios.get(`${this.$baseUrl}/customers/getCustomerInfo/${this.$cookies.get('emailAccount')}`);

                if ((!response.data.affiliate && affiliateFromCookie) || (response.data.affiliate && new Date(response.data.affiliate_expire) < new Date() && affiliateFromCookie)) {
                    await axios.post(`${this.$baseUrl}/customers/add-affiliate`, { email: customer_email, affiliate: affiliateFromCookie });

                    // Rimuovi l'oggetto dai cookies
                    this.$cookies.remove('pap_custom_tracker', '/', '.ofpfunding.com');

                    // Aggiorna response.data con l'affiliate appena aggiunto
                    response.data.affiliate = affiliateFromCookie
                }

                this.$cookies.set('client', JSON.stringify(response.data));
                this.client = this.$cookies.get('client');
                console.log('client info', this.client)
                this.modalPromo()
                this.client.hide_inactive_accounts = this.client.hide_inactive_accounts || false;
                this.profileLetter = (this.client.firstName[0] + this.client.lastName[0]);
                this.$refs.navbarRef.setClient();

                const accountsResponse = await axios.get(`${this.$baseUrl}/accounts/getAllCustomerAccounts/${this.client._id}`);
                if(accountsResponse.data.accounts<1){
                    window.location.replace('/buy-account')
                }
                let list_account = accountsResponse.data.accounts.map(item => item._id);
                let accounts = {
                    accounts: accountsResponse.data.accounts.length,
                    list_account: list_account
                };
                accountsResponse.data.accounts.forEach((item) => {
                    let name = item._id;
                    if (item.bad_trades) {
                        this.badTrades = item.bad_trades;
                        item.bad_trades = '';
                    }
                    let value = JSON.stringify(item);
                    sessionStorage.setItem(name, value);
                });

                sessionStorage.setItem('accounts', JSON.stringify(accounts));
                let list = sessionStorage.getItem('accounts');
                this.accountLen = JSON.parse(list).accounts;

            } catch (error) {
                console.error("Si è verificato un errore durante l'esecuzione di infoAccount:", error);
            }
            await this.setClient().then(()=>{this.showAreaChart = true;})

        },
        // get Info Customer's accounts, and metrics
        async setClient() {
            try {
                let list = sessionStorage.getItem('accounts');
                let accountsCookie = JSON.parse(list).list_account;
                accountsCookie.forEach(element => {
                    let acc = sessionStorage.getItem(element);
                    let elemento = JSON.parse(acc);
                    var account = elemento;
                    if (account.status != 0) {
                        this.accountActive.push(account);
                    } else {
                        this.accountInactive.push(account);
                    }
                    this.idAccounts.push(account.userID);
                    this.accountIdColl.push(account._id);
                });
                this.onlyOnDemandAccounts = [...this.accountActive, ...this.accountInactive].every(account => account.type_account.includes('On Demand'));

                let days = [];
                let sommaRR = 0;

                for (let i = 0; i < this.idAccounts.length; i++) {
                    console.log(this.idAccounts[i], i)
                    if (this.idAccounts.length>0 && this.idAccounts[i] != '' && (this.idAccounts[i] != undefined || this.accountIdColl[i] != undefined)) {
                        let response = await axios.get(`${this.$baseUrl}/accounts/getAccountInfo/${this.idAccounts[i] || this.accountIdColl[i]}`);
                        if (response.data.nextReview && !days.includes(response.data.nextReview) && (response.data.status != '0' && response.data.status != 'violated')) {
                            days.push(response.data.nextReview);
                        }
                        console.log(response.data)
                        if (response.data.currency) {
                            let curr = response.data.currency;
                            console.log(curr)
                            this.currAccounts.push(curr);
                        }
                        this.min = Math.min(...days);
                        const newDate = new Date();
                        newDate.setDate(newDate.getDate() + this.min);
                        this.nextPayout = newDate.toDateString();
                        var options = {weekday: 'long'};
                        var dayName = newDate.toLocaleDateString('en-US', options);
                        this.giorno = dayName;
                        console.log(this.currAccounts)
                        if(this.accountActive.length>0){
                            this.currSymbol = this.trovaValorePiuFrequente(this.currAccounts);
                        }
                        console.log(response.data.status)
                        if(response.data.status != '0' && response.data.status != 'violated' && response.data.status!=undefined){
                            if ((!localStorage.getItem(this.idAccounts[i]) || new Date() > JSON.stringify(localStorage.getItem(this.idAccounts[i]).expire))) {
                            let metricsResponse = await axios.get(`${this.$baseUrl}/getUserMetrics?user_id=${this.idAccounts[i]}`)
                            metricsResponse = metricsResponse.data;
                            metricsResponse.expire = Date.now() + 5 * 60 * 1000; // 5 minuti in millisecondi

                            localStorage.setItem(this.idAccounts[i], JSON.stringify(metricsResponse))
                            if (metricsResponse.profitFactor) {
                                let element = metricsResponse.profitFactor
                                console.log(element)
                                this.profitAccounts += parseFloat(element)
                            }
                            if (metricsResponse.expectancy) {
                                let element = metricsResponse.expectancy
                                console.log(element)
                                this.expAccounts += parseFloat(element)
                            }

                            if (metricsResponse.wonTradesPercent) {
                                let element = metricsResponse.wonTradesPercent
                                console.log(element)
                                this.wonTradesPercent += parseFloat(element)
                            }
                            if (metricsResponse && metricsResponse.monthlyAnalytics && metricsResponse.monthlyAnalytics.length > 0) {
                                let monthlyAnalytics = metricsResponse.monthlyAnalytics
                                let mediaElement = []
                                monthlyAnalytics.forEach(element => {
                                    element.currencies.forEach(item => {
                                        mediaElement.push(parseFloat(item.rewardToRiskRatio.toFixed(2)))
                                    })
                                })
                            }
                            let averageRR = 0
                            if (metricsResponse.averageWin && metricsResponse.averageLoss) {
                                averageRR = Math.abs(metricsResponse.averageWin / metricsResponse.averageLoss).toFixed(2)
                            }
                            sommaRR = (sommaRR + parseFloat(averageRR))

                            this.rr = sommaRR
                        } else {
                            let metricheAcc = JSON.parse(localStorage.getItem((this.idAccounts[i])))
                            if(metricheAcc){
                                if (metricheAcc.profitFactor) {
                                let element = metricheAcc.profitFactor
                                console.log(element)
                                this.profitAccounts += parseFloat(element)
                            }
                            if (metricheAcc.expectancy) {
                                let element = metricheAcc.expectancy
                                console.log(element)
                                this.expAccounts += parseFloat(element)
                            }

                            if (metricheAcc.wonTradesPercent) {
                                let element = metricheAcc.wonTradesPercent
                                console.log(element)
                                this.wonTradesPercent += parseFloat(element)
                            }
                            if (metricheAcc.monthlyAnalytics && metricheAcc.monthlyAnalytics.length > 0) {
                                let monthlyAnalytics = metricheAcc.monthlyAnalytics
                                let mediaElement = []
                                monthlyAnalytics.forEach(element => {
                                    element.currencies.forEach(item => {
                                        mediaElement.push(parseFloat(item.rewardToRiskRatio.toFixed(2)))
                                    })
                                })
                            }
                            let averageRR = 0
                            if (metricheAcc.averageWin && metricheAcc.averageLoss) {
                                averageRR = Math.abs(metricheAcc.averageWin / metricheAcc.averageLoss).toFixed(2)
                            }
                            sommaRR = (sommaRR + parseFloat(averageRR))

                            this.rr = sommaRR
                            }

                        }
                     }
                    }
                    this.loading = false;
                }
                this.loading = false;
            return true
            } catch (error) {
                console.error("Si è verificato un errore durante l'esecuzione di setClient:", error);
                // Qui puoi gestire ulteriormente l'errore, ad esempio mostrando un messaggio all'utente.
            }
        },
        formatData(value){
            if (!value) {
                return;
            }

            let date = new Date(value)
            let local = this.$languages[this.client.language]||this.$languages['en']
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var formatter = new Intl.DateTimeFormat(local, options);
            return formatter.format(date);
        },
        // go page detail account
        goTrial($event, currency) {
            this.$router.push({ path: '/trial', params: { id }, query: { currency } });

            let id=$event
            localStorage.setItem('id',id)
            localStorage.setItem('accountCurrency',currency)
        },
        formatCurrency(value){
            const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'GBP',
                        minimumFractionDigits: 2,
                        });
                        return formatter.format(value);
        },
        async hideInactiveAccounts() {
            if (!this.client._id) {
                return;
            }

            const data = {
                hide_inactive_accounts: this.client.hide_inactive_accounts
            }
            await axios.patch(`${this.$baseUrl}/customers/update/details?customer_id=${this.client._id}`, data);
        },
        modalPromo() {
            const now = new Date();
            const year = now.getFullYear();
            const startDate30 = new Date(Date.UTC(year, 1,21,14,30));
            const endDate30 = new Date(Date.UTC(year, 2,7,23,59));
            const promoShown = this.$cookies.get('promoShown');

            if(!promoShown && now>=startDate30 && now<=endDate30){
                document.getElementById('promo30').click();
                const tomorrow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1);
                this.$cookies.set('promoShown', 'true', {
                    expires: tomorrow
                });
            }
        }
    },
    mounted() {
        this.infoAccount()
    },
    // before enter check the cookies for the email -> no email -> login
    beforeMount(){
        if(!this.$cookies.get('emailAccount')){
            this.$router.push({ path: '/',})
        }
    }
}
</script>

<style scoped>

.modal{
    top: 30%!important;
    transform: translate(0px,-50px);
}
.modal-body{
    cursor: pointer!important;
}
.user-img{
    border-radius: 100%;
    width: 100px;
    height: 100px;
}
.card{
 border-radius: 10px;
 overflow: hidden;

}

li{
    margin: 10px;
}
.btn_green{
   background-color: #12FCA8!important;
}

.open:hover{
    transform: scale(1.1);
}
.carousel-control-prev{
    width: 2%;
}
.carousel-control-next{
    width: 2%;
}
h5.font-medium{
    font-size: 1.3rem;
}
.popup-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.popup:hover{
    box-shadow:none;
}
.popup {
  padding: 30px;
  border: 2px solid rgb(207, 97, 70);
  border-radius: 10px;
  display: none;
  width: 30%;
}

.popup.active {
  display: block;
}
.popup-0{
    position: absolute;
    top: 25%;
    right: 5%;
}
.popup-1{
    position: absolute;
    top: 55%;
    left: 65%;
}
.popup-2{
    position: absolute;
    top: 80%;
    left: 65%;
}
.cur{
    cursor: default;
}
.curPointer{
    cursor: pointer;
}
.loading {
    font-family: Arial, sans-serif;
    font-size: 20px;
}

.dot {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.dot1 { animation-delay: 0.2s; }
.dot2 { animation-delay: 0.4s; }
.dot3 { animation-delay: 0.6s; }

@keyframes blink {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
}

@media only screen and (max-width: 600px) {
    li{
        padding: 5px;
    }
}

.table{
    color: white;
}

.table > tbody {
    vertical-align: middle;
}
.form-check-input:not(:checked) {
    background-color: white !important;
}
</style>
