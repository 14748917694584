<template>
    <div id="main-wrapper">
        <Spinner v-if="loading" />
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" >
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" style="background-color: rgba(255, 255, 255, 0);">
                <!-- Sidebar navigation-->
                <Sidebar />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!--- Start Content --->
                <div class="row justify-content-between mt-5 pb-5">
                    <h1 class="text-start mb-5 text-white fw-bold">{{ $t('Checkout') }}</h1>
                    <div class="col-12 col-xl-6 order-2 order-xl-0 rounded mt-5" >
                        <h2 class="text-center mb-5 text-white fw-bold">{{ $t('checkout-billingDettails') }}</h2>
                        <div class="mt-5">
                            <form class="form-horizontal w-100 form-material flex-column flexdisplay"  id="loginform" >
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white" for="email">Email</label>
                                        <input class="form-control" type="email" disabled  id="email" :value="client.email">
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white " for="firstName">{{ $t('checkout-name') }}</label>
                                        <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="firstName" v-model="firstName">
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white" for="lastName">{{ $t('checkout-lastName') }}</label>
                                        <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="lastName" v-model="lastName">
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white" for="address">{{ $t('checkout-address') }}</label>
                                        <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="address" v-model="address">
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white" for="city">{{ $t('checkout-city') }}</label>
                                        <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="city" v-model="city">
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white" for="zip">{{ $t('checkout-zip') }}</label>
                                        <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="zip" v-model="zip">
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                    <div class="col-12 " id="billing">
                                        <label class="mb-1 text-white" for="country">{{ $t('checkout-country') }}</label>
                                        <select  name="country" class="form-control text border border-1 border-white px-2 rounded text-dark" id="country" v-model="country">
                                            <option value=""></option>
                                            <option v-for="state in options" :key="state.label"  :selected="country==state.value"  :value="state.value">{{state.label}}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="order-1 order-xl-0 col-12 col-xl-6">
                        <div  class="col-12 d-flex flex-column mb-5 flexdisplay" style="display: flex !important;">
                            <div class="col-12 border border-1 border-white-50 p-3 p-xl-5 rounded">
                                <h2 class="text-white fw-semibold">{{ $t('checkout-order') }}</h2>
                                <h5 class="text-danger fw-bold">{{ textErrCat }}</h5>
                                <div v-for="element in orders" :key="element" class="d-flex justify-content-between align-self-center gap-2 mt-5 flexdisplay" style="display: flex !important;">
                                    <div class="d-flex align-items-center">
                                        <h6 class="text-primary fw-bold me-3">1X</h6>
                                        <div class="d-flex flex-column">
                                            <h5 class="text-white">
                                                {{element.name }}
                                            </h5>
                                            <div>{{ element.size }}</div>
                                            <div>{{ orderPlat }}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row align-items-center gap-2 flexdisplay" >
                                        <h6 v-if="!isNaN(element.price) && orders.length==1 && !off50 && !off30" class="justify-self-end text-white m-0 p-0 pe-3">{{formatCurrency("USD" , element.price)}}</h6>
                                        <div v-else-if="!isNaN(element.price) && orders.length==1 && ( off50 || off30)" class="justify-self-end d-flex flex-column gap-1 aling-items-end justify-content-center text-center text-white m-0 p-0 pe-3">
                                            <h6 class="m-0 text-decoration-line-through">{{formatCurrency("USD" , element.price)}}</h6>
                                            <h5 class="m-0 text-success-ofp">-30% OFF</h5>
                                            <h4 class="m-0 text-white">{{formatCurrency("USD" , element.priceOFF)}}</h4>
                                        </div>
                                        <h6 v-else-if="!isNaN(element.price) && orders.length>1 && element.size>=50000" class="justify-self-end text-white m-0 p-0 pe-3">{{formatCurrency("USD" , element.price)}}</h6>
                                        <h6 v-else-if='!isNaN(element.price) && orders.length>1 && element.size<50000' class="justify-self-end text-success m-0 p-0 pe-3">FREE</h6>
                                        <h6 v-else class="justify-self-end text-white m-0 p-0 pe-3">-</h6>
                                        <div @click="removeElement(element.id)" :class="paymeth=='open'|| paymeth=='close'?'btn disabled':'btn btn-danger rounded'" class="btn btn-danger rounded">{{ $t('Remove') }}</div>
                                    </div>
                                </div>
                                <hr>
                                <div class="d-flex flex-column col-12 flexdisplay" v-if="off30 || off50">
                                    <div class="my-4" >
                                        <div class=" flexdisplay" >
                                            <input  type="checkbox" id="terms" name="terms" v-model="isChecked2" value="true" required :disabled="paymeth=='open' || paymeth=='close'?true:false">
                                            <label class="mx-2 fs-5" for="terms">{{ $t('Agree') }} <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">{{ $t('T&C') }}</a></label><br/>
                                        </div>
                                        <p class="text-danger" v-if="checkText!=''">{{ checkText }}</p>

                                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-1" id="exampleModalLabel">{{ $t('T&C') }}</h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-header">
                                                        <h3>{{ $t('TCTitle') }}</h3>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>
                                                            <p>{{ $t('TCText1') }}</p>
                                                            <p>{{ $t('TCText2') }}</p>
                                                            <p>{{ $t('TCText3') }}</p>
                                                            <p>{{ $t('TCText4') }} </p>
                                                        </div>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC1') }}</h6>
                                                        <p>{{ $t('TC1/1') }}</p>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC2') }}</h6>
                                                        <p>{{ $t('TC2/1') }}
                                                        </p>
                                                        <p>{{ $t('TC2/2') }} </p>
                                                        <p>{{ $t('TC2/3') }}</p>
                                                        <p>{{ $t('TC2/4') }}</p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC3') }} </h6>
                                                        <p>{{ $t('TC3/1') }}</p><br/>
                                                        <div class="ms-3">
                                                            <p> {{ $t('TC3/1/1') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/2') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/3') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/4') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/5') }}</p>
                                                            <p>{{ $t('TC3/1/6') }}
                                                            </p>
                                                        </div><br/>
                                                        <p>3{{ $t('TC3/2') }} </p>
                                                        <p>{{ $t('TC3/3') }} </p>
                                                        <p>{{ $t('TC3/4') }} </p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC4') }}</h6>
                                                        <p>{{ $t('TC4/1') }} </p>
                                                        <p>{{ $t('TC4/2') }} </p>
                                                        <p>{{ $t('TC4/3') }}</p>
                                                        <p>{{ $t('TC4/4') }} </p>
                                                        <p>{{ $t('TC4/5') }}</p>
                                                        <p>{{ $t('TC4/6') }} </p>
                                                        <p>{{ $t('TC4/7') }}</p>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC5') }} </h6>
                                                        <p>{{ $t('TC5/1') }}
                                                        </p>
                                                        <p>{{ $t('TC5/2') }}</p>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC5/2/1') }} </p>
                                                            <table class="table">
                                                                <thead>
                                                                <th>Account {{ $t('InitBalance') }}</th>
                                                                <th>5,000</th>
                                                                <th>10,000</th>
                                                                <th>25,000</th>
                                                                <th>50,000</th>
                                                                <th>100,000</th>
                                                                <th>200,000</th>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>{{ $t('Margin') }}</td>
                                                                    <td>250</td>
                                                                    <td>500</td>
                                                                    <td>1,250</td>
                                                                    <td>2,500</td>
                                                                    <td>5,000</td>
                                                                    <td>10,000</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <p>{{ $t('TC5/2/1b') }}</p>
                                                            <p> {{ $t('TC5/2/2') }}</p>
                                                            <p>{{ $t('TC5/2/3') }}</p>
                                                        </div>
                                                        <p>{{ $t('TC5/3') }}</p>
                                                        <div class="ms-3">
                                                            <p> {{ $t('TC5/3/1') }}
                                                                <br>{{ $t('TC5/3/1a') }}
                                                                <br>{{ $t('TC5/3/1b') }}
                                                                <br>{{ $t('TC5/3/1c') }}
                                                                <br>{{ $t('TC5/3/1d') }}
                                                                <br>{{ $t('TC5/3/1e') }}
                                                                <br>{{ $t('TC5/3/1f') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('TC5/3/2') }}
                                                                <br>{{ $t('TC5/3/2a') }}
                                                                <br>{{ $t('TC5/3/2b') }}
                                                                <br>{{ $t('TC5/3/2c') }}

                                                            </p>
                                                            <p>{{ $t('TC5/3/3') }}</p>
                                                        </div>
                                                        <p>{{ $t('TC5/4') }}</p>
                                                        <p>{{ $t('TC5/5') }}</p>
                                                        <p>{{ $t('TC5/6') }}</p>
                                                        <p>{{ $t('TC5/6/1') }}</p>
                                                    <p>{{ $t('TC5/6/2') }} </p>
                                                    <p>{{ $t('TC5/7') }}</p>
                                                    <p>{{ $t('TC5/8') }} </p>
                                                    <p>{{ $t('TC5/9') }}</p>
                                                    <p>{{ $t('TC5/10') }}</p>
                                                    <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC6') }}</h6>
                                                        <p>{{ $t('TC6/1') }}</p><p>{{ $t('TC6/2') }} </p><p> {{ $t('TC6/3') }}</p><p>{{ $t('TC6/4') }}</p><p>{{ $t('TC6/5') }} </p>
                                                        <div class="ms-3">
                                                            <p>
                                                                {{ $t('TC6/5/1') }}
                                                                {{ $t('TC6/5/1a') }}{{ $t('TC6/5/1b') }}{{ $t('TC6/5/1c') }}{{ $t('TC6/5/1d') }}
                                                            </p>
                                                        <p>{{ $t('TC6/5/2') }}</p>
                                                    </div>
                                                        <p>{{ $t('TC6/6') }}</p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC7') }}</h6>
                                                        <br/>
                                                        <p>{{ $t('TC7/1') }}</p>
                                                        <p>{{ $t('TC7/2') }}</p><p>{{ $t('TC7/3') }} </p><p>{{ $t('TC7/4') }}</p><p>{{ $t('TC7/5') }}</p>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC7/5/1') }}</p><p>{{ $t('TC7/5/2') }}</p>
                                                            <p>{{ $t('TC7/5/3') }}</p>
                                                            <p>{{ $t('TC7/5/4') }}</p>
                                                            <p>{{ $t('TC7/5/5') }}</p>
                                                            <p>{{ $t('TC7/5/6') }} </p>
                                                            <p>{{ $t('TC7/5/7') }}</p>
                                                        </div><br/>
                                                        <p>{{ $t('TC7/6') }}</p>
                                                        <p>{{ $t('TC7/7') }}</p>
                                                        <br>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC8') }}</h6>
                                                        <p>{{ $t('TC8/1') }}</p>
                                                        <p>{{ $t('TC8/2') }}</p>
                                                        <p>{{ $t('TC8/3') }} </p>
                                                        <p>{{ $t('TC8/4') }} </p>
                                                        <p>{{ $t('TC8/5') }}</p>
                                                        <p>{{ $t('TC8/6') }}</p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC9') }} </h6>
                                                        <br/>{{ $t('TC9/1') }}
                                                        <br/>{{ $t('TC9/2') }}
                                                        <br/>{{ $t('TC9/3') }}
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC10') }} </h6>
                                                        <p>{{ $t('TC10/1') }}</p>
                                                        <p>{{ $t('TC10/2') }}</p>
                                                        <p>{{ $t('TC10/3') }} </p>
                                                        <br/>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC11') }}</h6>
                                                        <p>{{ $t('TC11/1') }} </p>
                                                        <p>{{ $t('TC11/2') }}</p>
                                                        <p>{{ $t('TC11/3') }} </p>
                                                        <p>{{ $t('TC11/4') }}</p>
                                                        <p>{{ $t('TC11/5') }}</p>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC11/5/1') }}</p>
                                                            <p>{{ $t('TC11/5/2') }}</p>
                                                        </div>
                                                        <br>
                                                        <p>{{ $t('TC11/6') }}</p>
                                                        <p>{{ $t('TC11/7') }}</p>
                                                        <p>{{ $t('TC11/8') }}</p>
                                                        <br>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC12') }} </h6><br>
                                                        <p>{{ $t('TC12/1') }}</p><br/>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC13') }}</h6><br>
                                                        <p>{{ $t('TC13/1') }}</p>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC14') }}</h6><br>
                                                        <p>{{ $t('TC14/1') }}</p>
                                                        <p>{{ $t('TC14/2') }}</p>
                                                        <p>{{ $t('TC14/3') }}</p>
                                                        <p>{{ $t('TC14/4') }}</p>
                                                        <p>{{ $t('TC14/5') }}</p>
                                                        <p></p>
                                                        <p>{{ $t('TC14/6') }}</p>
                                                        <p>{{ $t('TC14/7') }}</p>
                                                        <br/>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC15') }}</h6><br>
                                                        {{ $t('TC15/1') }} <br>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC15/1/1') }}</p>
                                                            <p>{{ $t('TC15/1/2') }}</p>
                                                            <p>{{ $t('TC15/1/3') }}</p>
                                                            <p>{{ $t('TC15/1/4') }}</p>
                                                            <p>{{ $t('TC15/1/5') }}</p>
                                                            <p>{{ $t('TC15/1/6') }}</p>
                                                            <p>{{ $t('TC15/1/7') }}</p>
                                                            <p>{{ $t('TC15/1/8') }}</p>
                                                            <p>{{ $t('TC15/1/10') }} </p>
                                                            <p>{{ $t('TC15/1/11') }}</p>
                                                            <p>{{ $t('TC15/1/12') }}</p>
                                                            <p>{{ $t('TC15/1/13') }} </p>
                                                            <p>{{ $t('TC15/1/14') }}</p>
                                                            <p>{{ $t('TC15/1/15') }}</p>
                                                            <p>{{ $t('TC15/1/16') }}</p>
                                                            <p>{{ $t('TC15/1/17') }}</p>
                                                            <p>{{ $t('TC15/1/18') }}</p>
                                                            <p> {{ $t('TC15/1/19') }}</p>
                                                            <p>{{ $t('TC15/1/21') }}</p>
                                                            <p>{{ $t('TC15/1/22') }}</p>
                                                            <p>{{ $t('TC15/1/23') }}</p>
                                                            <p>{{ $t('TC15/1/24') }}
                                                                <a target="_blank" href="https://ofpfunding.com">www.ofpfunding.com </a>
                                                                {{ $t('TC15/1/24a') }}
                                                            </p>
                                                        </div>
                                                        <p>{{ $t('TC15/2') }}</p>
                                                        <div>
                                                            {{ $t('TC15/2/1') }}
                                                            <p>{{ $t('TC15/2/2') }}</p>
                                                            <p>{{ $t('TC15/2/3') }}</p>
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            <h5>{{ $t('Schedule') }}</h5>
                                                            <p>{{ $t('Models') }}:</p>
                                                            <ul>
                                                                <li>{{ $t('Monthly') }} 26%</li>
                                                                <li>{{ $t('Monthly') }} 40%</li>
                                                                <li>{{ $t('Monthly') }} 60%</li>
                                                                <li>{{ $t('Monthly') }} 80%</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{{ $t('ondemand') }} 26%</li>
                                                                <li>{{ $t('ondemand') }} 40%</li>
                                                                <li>{{ $t('ondemand') }} 60%</li>
                                                                <li>{{ $t('ondemand') }} 80%</li>
                                                            </ul>
                                                            <p>{{ $t('Size') }}:</p>
                                                            <ul>
                                                                <li>5,000</li>
                                                                <li>10,000</li>
                                                                <li>25,000</li>
                                                                <li>50,000</li>
                                                                <li>100,000</li>
                                                                <li>200,000</li>
                                                            </ul>
                                                            <p>{{ $t('Currency') }}:</p>
                                                            <ul>
                                                                <li>EUR</li>
                                                                <li>USD</li>
                                                                <li>GBP</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a @click="sendOrder()" v-show='order.name' type="submit"  :class="paymeth=='open'|| paymeth=='close' ?'btn disabled':'btn btn-primary'" :disabled="creatingOrder">Confirm Order</a>
                                    <div v-if="orderErrors.length" class="mt-2">
                                        <h4 class="text-danger fw-bold">{{ $t('checkout-error') }}</h4>
                                        <ul>
                                            <li v-for="err of orderErrors" :key="err">
                                                <span class="text-danger">{{ err }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <form v-if="!off30 && !off50" class="d-flex flex-column col-12 flexdisplay" >
                                    <div class="d-flex justify-content-between align-items-center mb-1" >
                                        <label class="m-0 p-0 mb-2  text-gray" for="Cupon">{{ $t('Coupon') }}</label>
                                        <div class="d-flex gap-2 text-end">
                                            <input class="inputCoupon mb-2 mb-md-0" :readonly="validCupon? true:false " :class="validCupon==true?'':validCupon==false?'border border-3 border-danger':validCupon==null?'':''" type="text" id="Coupon"  name="Coupon" v-model="coupon" required>
                                            <div id="addCoupon" class="ms-1 ms-lg-0" :class="paymeth=='open'|| paymeth=='close'?'btn disabled':'btn btn-primary rounded'"  @click="addCupon()">{{ $t('Coupon-add') }}</div>
                                        </div>
                                    </div>
                                    <div v-if="textRespCupon!=''" :class="!validCupon?'text-end fw-bold text-danger ':'text-end fw-bold text-success '">{{ textRespCupon }}</div>
                                    <div class="d-flex justify-content-between align-items-center flexdisplay mt-3" >
                                        <p class="m-0 p-0 mb-2 text-uppercase text-white"> {{ $t('Total') }} : </p>
                                        <h5 v-if="!isNaN(order.price) && percCoupon==''" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" , order.price)}}</h5>
                                        <div v-else-if="!isNaN(order.price) && percCoupon!=''">
                                            <h5  class="m-0 p-0 fw-bold text-white text-decoration-line-through">{{formatCurrency("USD" , order.price)}} </h5>
                                            <h4 v-if="typeCoupon=='percentage' && (order.price-(order.price*percCoupon)/100)>0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,(order.price-(order.price*percCoupon)/100))}}</h4>
                                            <h4 v-if="typeCoupon=='percentage' && (order.price-(order.price*percCoupon)/100)<=0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,0)}}</h4>
                                            <h4 v-if="typeCoupon=='amount' && order.price-percCoupon>0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,(order.price-percCoupon))}}</h4>
                                            <h4 v-if="typeCoupon=='amount' && order.price-percCoupon<=0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,0)}}</h4>
                                        </div>
                                        <h5 v-else class="justify-self-end text-white m-0 p-0 pe-3">-</h5>
                                    </div>
                                    <div v-if="percCoupon!='' && typeCoupon=='percentage'" class="text-end text-white mb-3">{{ percCoupon }} {{ $t('Coupon-text') }}</div>
                                    <div v-if="percCoupon!='' && typeCoupon=='amount'" class="text-end text-white mb-3">- {{ formatCurrency('USD', percCoupon) }} {{ $t('Coupon%-text') }}</div>
                                    <div class="my-4" >
                                        <div class=" flexdisplay" >
                                            <input  type="checkbox" id="terms" name="terms" v-model="isChecked2" value="true" required :disabled="paymeth=='open' || paymeth=='close'?true:false">
                                            <label class="mx-2 fs-5" for="terms">{{ $t('Agree') }} <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">{{ $t('T&C') }}</a></label><br/>
                                        </div>
                                        <p class="text-danger" v-if="checkText!=''">{{ checkText }}</p>

                                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-1" id="exampleModalLabel">{{ $t('T&C') }}</h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-header">
                                                        <h3>{{ $t('TCTitle') }}</h3>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>
                                                            <p>{{ $t('TCText1') }}</p>
                                                            <p>{{ $t('TCText2') }}</p>
                                                            <p>{{ $t('TCText3') }}</p>
                                                            <p>{{ $t('TCText4') }} </p>
                                                        </div>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC1') }}</h6>
                                                        <p>{{ $t('TC1/1') }}</p>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC2') }}</h6>
                                                        <p>{{ $t('TC2/1') }}
                                                        </p>
                                                        <p>{{ $t('TC2/2') }} </p>
                                                        <p>{{ $t('TC2/3') }}</p>
                                                        <p>{{ $t('TC2/4') }}</p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC3') }} </h6>
                                                        <p>{{ $t('TC3/1') }}</p><br/>
                                                        <div class="ms-3">
                                                            <p> {{ $t('TC3/1/1') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/2') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/3') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/4') }}
                                                            </p>
                                                            <p>{{ $t('TC3/1/5') }}</p>
                                                            <p>{{ $t('TC3/1/6') }}
                                                            </p>
                                                        </div><br/>
                                                        <p>3{{ $t('TC3/2') }} </p>
                                                        <p>{{ $t('TC3/3') }} </p>
                                                        <p>{{ $t('TC3/4') }} </p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC4') }}</h6>
                                                        <p>{{ $t('TC4/1') }} </p>
                                                        <p>{{ $t('TC4/2') }} </p>
                                                        <p>{{ $t('TC4/3') }}</p>
                                                        <p>{{ $t('TC4/4') }} </p>
                                                        <p>{{ $t('TC4/5') }}</p>
                                                        <p>{{ $t('TC4/6') }} </p>
                                                        <p>{{ $t('TC4/7') }}</p>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC5') }} </h6>
                                                        <p>{{ $t('TC5/1') }}
                                                        </p>
                                                        <p>{{ $t('TC5/2') }}</p>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC5/2/1') }} </p>
                                                            <table class="table">
                                                                <thead>
                                                                <th>Account {{ $t('InitBalance') }}</th>
                                                                <th>5,000</th>
                                                                <th>10,000</th>
                                                                <th>25,000</th>
                                                                <th>50,000</th>
                                                                <th>100,000</th>
                                                                <th>200,000</th>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td>{{ $t('Margin') }}</td>
                                                                    <td>250</td>
                                                                    <td>500</td>
                                                                    <td>1,250</td>
                                                                    <td>2,500</td>
                                                                    <td>5,000</td>
                                                                    <td>10,000</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <p>{{ $t('TC5/2/1b') }}</p>
                                                            <p> {{ $t('TC5/2/2') }}</p>
                                                            <p>{{ $t('TC5/2/3') }}</p>
                                                        </div>
                                                        <p>{{ $t('TC5/3') }}</p>
                                                        <div class="ms-3">
                                                            <p> {{ $t('TC5/3/1') }}
                                                                <br>{{ $t('TC5/3/1a') }}
                                                                <br>{{ $t('TC5/3/1b') }}
                                                                <br>{{ $t('TC5/3/1c') }}
                                                                <br>{{ $t('TC5/3/1d') }}
                                                                <br>{{ $t('TC5/3/1e') }}
                                                                <br>{{ $t('TC5/3/1f') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('TC5/3/2') }}
                                                                <br>{{ $t('TC5/3/2a') }}
                                                                <br>{{ $t('TC5/3/2b') }}
                                                                <br>{{ $t('TC5/3/2c') }}

                                                            </p>
                                                            <p>{{ $t('TC5/3/3') }}</p>
                                                        </div>
                                                        <p>{{ $t('TC5/4') }}</p>
                                                        <p>{{ $t('TC5/5') }}</p>
                                                        <p>{{ $t('TC5/6') }}</p>
                                                        <p>{{ $t('TC5/6/1') }}</p>
                                                    <p>{{ $t('TC5/6/2') }} </p>
                                                    <p>{{ $t('TC5/7') }}</p>
                                                    <p>{{ $t('TC5/8') }} </p>
                                                    <p>{{ $t('TC5/9') }}</p>
                                                    <p>{{ $t('TC5/10') }}</p>
                                                    <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC6') }}</h6>
                                                        <p>{{ $t('TC6/1') }}</p><p>{{ $t('TC6/2') }} </p><p> {{ $t('TC6/3') }}</p><p>{{ $t('TC6/4') }}</p><p>{{ $t('TC6/5') }} </p>
                                                        <div class="ms-3">
                                                            <p>
                                                                {{ $t('TC6/5/1') }}
                                                                {{ $t('TC6/5/1a') }}{{ $t('TC6/5/1b') }}{{ $t('TC6/5/1c') }}{{ $t('TC6/5/1d') }}
                                                            </p>
                                                        <p>{{ $t('TC6/5/2') }}</p>
                                                    </div>
                                                        <p>{{ $t('TC6/6') }}</p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC7') }}</h6>
                                                        <br/>
                                                        <p>{{ $t('TC7/1') }}</p>
                                                        <p>{{ $t('TC7/2') }}</p><p>{{ $t('TC7/3') }} </p><p>{{ $t('TC7/4') }}</p><p>{{ $t('TC7/5') }}</p>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC7/5/1') }}</p><p>{{ $t('TC7/5/2') }}</p>
                                                            <p>{{ $t('TC7/5/3') }}</p>
                                                            <p>{{ $t('TC7/5/4') }}</p>
                                                            <p>{{ $t('TC7/5/5') }}</p>
                                                            <p>{{ $t('TC7/5/6') }} </p>
                                                            <p>{{ $t('TC7/5/7') }}</p>
                                                        </div><br/>
                                                        <p>{{ $t('TC7/6') }}</p>
                                                        <p>{{ $t('TC7/7') }}</p>
                                                        <br>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC8') }}</h6>
                                                        <p>{{ $t('TC8/1') }}</p>
                                                        <p>{{ $t('TC8/2') }}</p>
                                                        <p>{{ $t('TC8/3') }} </p>
                                                        <p>{{ $t('TC8/4') }} </p>
                                                        <p>{{ $t('TC8/5') }}</p>
                                                        <p>{{ $t('TC8/6') }}</p>
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC9') }} </h6>
                                                        <br/>{{ $t('TC9/1') }}
                                                        <br/>{{ $t('TC9/2') }}
                                                        <br/>{{ $t('TC9/3') }}
                                                        <br/>
                                                        <h6 class="fw-bold mt-4">{{ $t('TC10') }} </h6>
                                                        <p>{{ $t('TC10/1') }}</p>
                                                        <p>{{ $t('TC10/2') }}</p>
                                                        <p>{{ $t('TC10/3') }} </p>
                                                        <br/>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC11') }}</h6>
                                                        <p>{{ $t('TC11/1') }} </p>
                                                        <p>{{ $t('TC11/2') }}</p>
                                                        <p>{{ $t('TC11/3') }} </p>
                                                        <p>{{ $t('TC11/4') }}</p>
                                                        <p>{{ $t('TC11/5') }}</p>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC11/5/1') }}</p>
                                                            <p>{{ $t('TC11/5/2') }}</p>
                                                        </div>
                                                        <br>
                                                        <p>{{ $t('TC11/6') }}</p>
                                                        <p>{{ $t('TC11/7') }}</p>
                                                        <p>{{ $t('TC11/8') }}</p>
                                                        <br>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC12') }} </h6><br>
                                                        <p>{{ $t('TC12/1') }}</p><br/>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC13') }}</h6><br>
                                                        <p>{{ $t('TC13/1') }}</p>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC14') }}</h6><br>
                                                        <p>{{ $t('TC14/1') }}</p>
                                                        <p>{{ $t('TC14/2') }}</p>
                                                        <p>{{ $t('TC14/3') }}</p>
                                                        <p>{{ $t('TC14/4') }}</p>
                                                        <p>{{ $t('TC14/5') }}</p>
                                                        <p></p>
                                                        <p>{{ $t('TC14/6') }}</p>
                                                        <p>{{ $t('TC14/7') }}</p>
                                                        <br/>

                                                        <h6 class="fw-bold mt-4">{{ $t('TC15') }}</h6><br>
                                                        {{ $t('TC15/1') }} <br>
                                                        <div class="ms-3">
                                                            <p>{{ $t('TC15/1/1') }}</p>
                                                            <p>{{ $t('TC15/1/2') }}</p>
                                                            <p>{{ $t('TC15/1/3') }}</p>
                                                            <p>{{ $t('TC15/1/4') }}</p>
                                                            <p>{{ $t('TC15/1/5') }}</p>
                                                            <p>{{ $t('TC15/1/6') }}</p>
                                                            <p>{{ $t('TC15/1/7') }}</p>
                                                            <p>{{ $t('TC15/1/8') }}</p>
                                                            <p>{{ $t('TC15/1/10') }} </p>
                                                            <p>{{ $t('TC15/1/11') }}</p>
                                                            <p>{{ $t('TC15/1/12') }}</p>
                                                            <p>{{ $t('TC15/1/13') }} </p>
                                                            <p>{{ $t('TC15/1/14') }}</p>
                                                            <p>{{ $t('TC15/1/15') }}</p>
                                                            <p>{{ $t('TC15/1/16') }}</p>
                                                            <p>{{ $t('TC15/1/17') }}</p>
                                                            <p>{{ $t('TC15/1/18') }}</p>
                                                            <p> {{ $t('TC15/1/19') }}</p>
                                                            <p>{{ $t('TC15/1/21') }}</p>
                                                            <p>{{ $t('TC15/1/22') }}</p>
                                                            <p>{{ $t('TC15/1/23') }}</p>
                                                            <p>{{ $t('TC15/1/24') }}
                                                                <a target="_blank" href="https://ofpfunding.com">www.ofpfunding.com </a>
                                                                {{ $t('TC15/1/24a') }}
                                                            </p>
                                                        </div>
                                                        <p>{{ $t('TC15/2') }}</p>
                                                        <div>
                                                            {{ $t('TC15/2/1') }}
                                                            <p>{{ $t('TC15/2/2') }}</p>
                                                            <p>{{ $t('TC15/2/3') }}</p>
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            <h5>{{ $t('Schedule') }}</h5>
                                                            <p>{{ $t('Models') }}:</p>
                                                            <ul>
                                                                <li>{{ $t('Monthly') }} 26%</li>
                                                                <li>{{ $t('Monthly') }} 40%</li>
                                                                <li>{{ $t('Monthly') }} 60%</li>
                                                                <li>{{ $t('Monthly') }} 80%</li>
                                                            </ul>
                                                            <ul>
                                                                <li>{{ $t('ondemand') }} 26%</li>
                                                                <li>{{ $t('ondemand') }} 40%</li>
                                                                <li>{{ $t('ondemand') }} 60%</li>
                                                                <li>{{ $t('ondemand') }} 80%</li>
                                                            </ul>
                                                            <p>{{ $t('Size') }}:</p>
                                                            <ul>
                                                                <li>5,000</li>
                                                                <li>10,000</li>
                                                                <li>25,000</li>
                                                                <li>50,000</li>
                                                                <li>100,000</li>
                                                                <li>200,000</li>
                                                            </ul>
                                                            <p>{{ $t('Currency') }}:</p>
                                                            <ul>
                                                                <li>EUR</li>
                                                                <li>USD</li>
                                                                <li>GBP</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a @click="sendOrder()" v-show='order.name' type="submit"  :class="paymeth=='open'|| paymeth=='close' ?'btn disabled':'btn btn-primary'" :disabled="creatingOrder">Confirm Order</a>
                                    <div v-if="orderErrors.length" class="mt-2">
                                        <h4 class="text-danger fw-bold">{{ $t('checkout-error') }}</h4>
                                        <ul>
                                            <li v-for="err of orderErrors" :key="err">
                                                <span class="text-danger">{{ err }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div v-if="paymeth=='open'" class="col-12 d-flex flex-column mb-5 border border-1 border-white-50 p-3 p-xl-5 rounded flexdisplay">
                            <h2 class="text-white fw-semibold mb-3">{{ $t('PayMeth') }} :</h2>
                            <div class="p-2 mb-5" v-if="reward?.total > 0">
                                <div class="d-flex flex-column gap-1 mb-3">
                                    <h3 class="m-0 text-primary">{{ $t('checkout-reward') }}</h3>
                                    <h6 class="m-0 p-0">{{ $t('checkout-rewardText') }}</h6>
                                    <h4 class="text-danger fw-bold">{{ pointstext }}</h4>
                                </div>
                                <div >
                                    <label for="rangeFidelity" class="form-label fs-6 fw-bold">{{ rewardPoints }}/ {{ reward.total.toFixed(2) }} = {{ (rewardPoints/40).toFixed(2)  }} &pound; </label>
                                    <input step="1" type="range" class="form-range" min="0" :max="reward.total" id="rangeFidelity" v-model="rewardPoints">
                                </div>
                            </div>

                            <!-- <div v-if="false" class="btn d-flex align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" style="height: 50px;" @click="payWith('stripe')" >
                                <div class="d-flex align-items-center flexdisplay" >
                                <img class="me-4 " style="height: 50px" src="../../../public/assets/images/stripe.png" alt="">

                               </div>
                                <div class="d-flex gap-2 align-items-center">
                                    <h4 class="text-uppercase text-white m-0 p-0"> Stripe</h4>
                                    <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'credit-card']" />
                                </div>
                            </div> -->
                            <div class="col-12 d-block d-xl-flex justify-content-between">
                                <div :class="!payDisabled?'disabled':''" class="col-12 col-xl-5 btn btn-outline-primary border-2 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay"  @click="payWith('trustPayment')">
                                    <div class="d-flex align-items-center flexdisplay"  >
                                        <form id="form" method="POST" action="https://payments.securetrading.net/process/payments/choice">
                                            <input type="hidden" name="sitereference" :value="this.$trustpayment_domain">
                                            <input type="hidden" name="stprofile" value="default">
                                            <input type="hidden" name="currencyiso3a" value="USD">
                                            <input type="hidden" name="mainamount" v-model="formTot">
                                            <input type="hidden" name="version" value="2">
                                            <input type="hidden" name="billingcontactdetailsoverride" value="1">
                                            <input type="hidden" name="customercontactdetailsoverride" value="1">
                                            <input type="hidden" name="ruleidentifier" value="STR-6">
                                            <input type=hidden name="successfulurlredirect" :value="'https://client.ofpfunding.com/successPayment?customer_id=' + client._id">
                                            <input type="hidden" name="allurlnotification" value="https://ofp-api.ait-tech.it/api/webhook/payment-gateway?gateway=trustpayment">
                                            <input type="hidden" name="orderreference" v-model="formId">
                                            <input type="submit" class="d-none btn" >
                                        </form>
                                        <div class="d-flex gap-3 align-items-center">
                                            <img class="" style="height: 2.5rem" src="/assets/images/trustwhite.png" alt="">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center flexdisplay gap-3 col-12">
                                        <img class="col-2" src="/assets/images/card.png" alt="">
                                        <img class="col-2" src="/assets/images/visa.png" alt="">
                                        <img class="col-2" src="/assets/images/google_pay_light.png" alt="">
                                        <img class="col-2" src="/assets/images/apple-pay.png" alt="">
                                    </div>
                                    <div class="d-flex gap-2 align-items-center">
                                        <h4 class="text-uppercase text-white m-0 p-0"> Trustpayment</h4>
                                        <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'credit-card']" />
                                    </div>
                                </div>
                                <div :class="!payDisabled?'disabled':''" class="col-12 col-xl-5 btn btn-outline-primary border-2 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" @click="payWith('coinbase')">
                                    <div class="d-flex align-items-center flexdisplay">
                                        <img class="" style="height: 2.5rem;" src="/assets/images/coinbase_white.png" alt="">
                                    </div>

                                    <div class="d-flex justify-content-center align-items-center flexdisplay gap-2 col-12">
                                        <img  class="col-3" src="/assets/images/bitcoin.png" alt="">
                                        <img  class="col-3" src="/assets/images/tether.png" alt="">
                                        <img  class="col-3" src="/assets/images/cryptocurrency.png" alt="">
                                    </div>

                                    <div class="d-flex gap-2 align-items-center">
                                        <h4 class="text-uppercase text-white m-0 p-0"> Cryptocurrency</h4>
                                        <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'coins']" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- End Content --->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <div v-if="paymeth=='close'" class="w-100 bg-success d-flex flex-column mb-5 border border-1 border-white-50 p-xl-5 rounded flexdisplay position-absolute top-50 text-center">
                <h2 class="text-white fw-bold mb-3">Payment Success :</h2>
                <p class="text-white fw-semibold">Go to see your <a href="/orders">Orders</a> </p>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- =============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import bannerKyc from "../components/statics/bannerKyc.vue";
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/statics/navbar.vue";
import Footer from "../components/statics/footer.vue";
import Spinner from "../components/Spinner.vue"
export default {
    components: {Footer, Navbar, Sidebar, Spinner,bannerKyc},
    data(){
        return{
            ref_origin:'',
            creatingOrder: false,
            rewardPoints:0,
            isChecked2:false,
            coupon:'',
            options: [
                { value: 'AF', label: 'Afghanistan'},
                { value: 'AX', label: 'Aland Islands'},
                { value: 'AL', label: 'Albania'},
                { value: 'DZ', label: 'Algeria'},
                { value: 'AS', label: 'American Samoa'},
                { value: 'AD', label: 'Andorra'},
                { value: 'AO', label: 'Angola'},
                { value: 'AI', label: 'Anguilla'},
                { value: 'AQ', label: 'Antarctica'},
                { value: 'AG', label: 'Antigua And Barbuda'},
                { value: 'AR', label: 'Argentina'},
                { value: 'AM', label: 'Armenia'},
                { value: 'AW', label: 'Aruba'},
                { value: 'AU', label: 'Australia'},
                { value: 'AT', label: 'Austria'},
                { value: 'AZ', label: 'Azerbaijan'},
                { value: 'BS', label: 'Bahamas'},
                { value: 'BH', label: 'Bahrain'},
                { value: 'BD', label: 'Bangladesh'},
                { value: 'BB', label: 'Barbados'},
                { value: 'BY', label: 'Belarus'},
                { value: 'BE', label: 'Belgium'},
                { value: 'BZ', label: 'Belize'},
                { value: 'BJ', label: 'Benin'},
                { value: 'BM', label: 'Bermuda'},
                { value: 'BT', label: 'Bhutan'},
                { value: 'BO', label: 'Bolivia'},
                { value: 'BA', label: 'Bosnia And Herzegovina'},
                { value: 'BW', label: 'Botswana'},
                { value: 'BV', label: 'Bouvet Island'},
                { value: 'BR', label: 'Brazil'},
                { value: 'IO', label: 'British Indian Ocean Territory'},
                { value: 'BN', label: 'Brunei Darussalam'},
                { value: 'BG', label: 'Bulgaria'},
                { value: 'BF', label: 'Burkina Faso'},
                { value: 'BI', label: 'Burundi'},
                { value: 'KH', label: 'Cambodia'},
                { value: 'CM', label: 'Cameroon'},
                { value: 'CA', label: 'Canada'},
                { value: 'CV', label: 'Cape Verde'},
                { value: 'KY', label: 'Cayman Islands'},
                { value: 'CF', label: 'Central African Republic'},
                { value: 'TD', label: 'Chad'},
                { value: 'CL', label: 'Chile'},
                { value: 'CN', label: 'China'},
                { value: 'CX', label: 'Christmas Island'},
                { value: 'CC', label: 'Cocos (Keeling) Islands'},
                { value: 'CO', label: 'Colombia'},
                { value: 'KM', label: 'Comoros'},
                { value: 'CG', label: 'Congo'},
                { value: 'CD', label: 'Congo}, Democratic Republic'},
                { value: 'CK', label: 'Cook Islands'},
                { value: 'CR', label: 'Costa Rica'},
                { value: 'CI', label: 'Cote D\'Ivoire'},
                { value: 'HR', label: 'Croatia'},
                { value: 'CU', label: 'Cuba'},
                { value: 'CY', label: 'Cyprus'},
                { value: 'CZ', label: 'Czech Republic'},
                { value: 'DK', label: 'Denmark'},
                { value: 'DJ', label: 'Djibouti'},
                { value: 'DM', label: 'Dominica'},
                { value: 'DO', label: 'Dominican Republic'},
                { value: 'EC', label: 'Ecuador'},
                { value: 'EG', label: 'Egypt'},
                { value: 'SV', label: 'El Salvador'},
                { value: 'GQ', label: 'Equatorial Guinea'},
                { value: 'ER', label: 'Eritrea'},
                { value: 'EE', label: 'Estonia'},
                { value: 'ET', label: 'Ethiopia'},
                { value: 'FK', label: 'Falkland Islands (Malvinas)'},
                { value: 'FO', label: 'Faroe Islands'},
                { value: 'FJ', label: 'Fiji'},
                { value: 'FI', label: 'Finland'},
                { value: 'FR', label: 'France'},
                { value: 'GF', label: 'French Guiana'},
                { value: 'PF', label: 'French Polynesia'},
                { value: 'TF', label: 'French Southern Territories'},
                { value: 'GA', label: 'Gabon'},
                { value: 'GM', label: 'Gambia'},
                { value: 'GE', label: 'Georgia'},
                { value: 'DE', label: 'Germany'},
                { value: 'GH', label: 'Ghana'},
                { value: 'GI', label: 'Gibraltar'},
                { value: 'GR', label: 'Greece'},
                { value: 'GL', label: 'Greenland'},
                { value: 'GD', label: 'Grenada'},
                { value: 'GP', label: 'Guadeloupe'},
                { value: 'GU', label: 'Guam'},
                { value: 'GT', label: 'Guatemala'},
                { value: 'GG', label: 'Guernsey'},
                { value: 'GN', label: 'Guinea'},
                { value: 'GW', label: 'Guinea-Bissau'},
                { value: 'GY', label: 'Guyana'},
                { value: 'HT', label: 'Haiti'},
                { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
                { value: 'VA', label: 'Holy See (Vatican City State)'},
                { value: 'HN', label: 'Honduras'},
                { value: 'HK', label: 'Hong Kong'},
                { value: 'HU', label: 'Hungary'},
                { value: 'IS', label: 'Iceland'},
                { value: 'IN', label: 'India'},
                { value: 'ID', label: 'Indonesia'},
                { value: 'IR', label: 'Iran}, Islamic Republic Of'},
                { value: 'IQ', label: 'Iraq'},
                { value: 'IE', label: 'Ireland'},
                { value: 'IM', label: 'Isle Of Man'},
                { value: 'IL', label: 'Israel'},
                { value: 'IT', label: 'Italia'},
                { value: 'JM', label: 'Jamaica'},
                { value: 'JP', label: 'Japan'},
                { value: 'JE', label: 'Jersey'},
                { value: 'JO', label: 'Jordan'},
                { value: 'KZ', label: 'Kazakhstan'},
                { value: 'KE', label: 'Kenya'},
                { value: 'KI', label: 'Kiribati'},
                { value: 'KR', label: 'Korea'},
                { value: 'KW', label: 'Kuwait'},
                { value: 'KG', label: 'Kyrgyzstan'},
                { value: 'LA', label: 'Lao People\'s Democratic Republic'},
                { value: 'LV', label: 'Latvia'},
                { value: 'LB', label: 'Lebanon'},
                { value: 'LS', label: 'Lesotho'},
                { value: 'LR', label: 'Liberia'},
                { value: 'LY', label: 'Libyan Arab Jamahiriya'},
                { value: 'LI', label: 'Liechtenstein'},
                { value: 'LT', label: 'Lithuania'},
                { value: 'LU', label: 'Luxembourg'},
                { value: 'MO', label: 'Macao'},
                { value: 'MK', label: 'Macedonia'},
                { value: 'MG', label: 'Madagascar'},
                { value: 'MW', label: 'Malawi'},
                { value: 'MY', label: 'Malaysia'},
                { value: 'MV', label: 'Maldives'},
                { value: 'ML', label: 'Mali'},
                { value: 'MT', label: 'Malta'},
                { value: 'MH', label: 'Marshall Islands'},
                { value: 'MQ', label: 'Martinique'},
                { value: 'MR', label: 'Mauritania'},
                { value: 'MU', label: 'Mauritius'},
                { value: 'YT', label: 'Mayotte'},
                { value: 'MX', label: 'Mexico'},
                { value: 'FM', label: 'Micronesia}, Federated States Of'},
                { value: 'MD', label: 'Moldova'},
                { value: 'MC', label: 'Monaco'},
                { value: 'MN', label: 'Mongolia'},
                { value: 'ME', label: 'Montenegro'},
                { value: 'MS', label: 'Montserrat'},
                { value: 'MA', label: 'Morocco'},
                { value: 'MZ', label: 'Mozambique'},
                { value: 'MM', label: 'Myanmar'},
                { value: 'NA', label: 'Namibia'},
                { value: 'NR', label: 'Nauru'},
                { value: 'NP', label: 'Nepal'},
                { value: 'NL', label: 'Netherlands'},
                { value: 'AN', label: 'Netherlands Antilles'},
                { value: 'NC', label: 'New Caledonia'},
                { value: 'NZ', label: 'New Zealand'},
                { value: 'NI', label: 'Nicaragua'},
                { value: 'NE', label: 'Niger'},
                { value: 'NG', label: 'Nigeria'},
                { value: 'NU', label: 'Niue'},
                { value: 'NF', label: 'Norfolk Island'},
                { value: 'MP', label: 'Northern Mariana Islands'},
                { value: 'NO', label: 'Norway'},
                { value: 'OM', label: 'Oman'},
                { value: 'PK', label: 'Pakistan'},
                { value: 'PW', label: 'Palau'},
                { value: 'PS', label: 'Palestinian Territory}, Occupied'},
                { value: 'PA', label: 'Panama'},
                { value: 'PG', label: 'Papua New Guinea'},
                { value: 'PY', label: 'Paraguay'},
                { value: 'PE', label: 'Peru'},
                { value: 'PH', label: 'Philippines'},
                { value: 'PN', label: 'Pitcairn'},
                { value: 'PL', label: 'Poland'},
                { value: 'PT', label: 'Portugal'},
                { value: 'PR', label: 'Puerto Rico'},
                { value: 'QA', label: 'Qatar'},
                { value: 'RE', label: 'Reunion'},
                { value: 'RO', label: 'Romania'},
                { value: 'RU', label: 'Russian Federation'},
                { value: 'RW', label: 'Rwanda'},
                { value: 'BL', label: 'Saint Barthelemy'},
                { value: 'SH', label: 'Saint Helena'},
                { value: 'KN', label: 'Saint Kitts And Nevis'},
                { value: 'LC', label: 'Saint Lucia'},
                { value: 'MF', label: 'Saint Martin'},
                { value: 'PM', label: 'Saint Pierre And Miquelon'},
                { value: 'VC', label: 'Saint Vincent And Grenadines'},
                { value: 'WS', label: 'Samoa'},
                { value: 'SM', label: 'San Marino'},
                { value: 'ST', label: 'Sao Tome And Principe'},
                { value: 'SA', label: 'Saudi Arabia'},
                { value: 'SN', label: 'Senegal'},
                { value: 'RS', label: 'Serbia'},
                { value: 'SC', label: 'Seychelles'},
                { value: 'SL', label: 'Sierra Leone'},
                { value: 'SG', label: 'Singapore'},
                { value: 'SK', label: 'Slovakia'},
                { value: 'SI', label: 'Slovenia'},
                { value: 'SB', label: 'Solomon Islands'},
                { value: 'SO', label: 'Somalia'},
                { value: 'ZA', label: 'South Africa'},
                { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
                { value: 'ES', label: 'Spain'},
                { value: 'LK', label: 'Sri Lanka'},
                { value: 'SD', label: 'Sudan'},
                { value: 'SR', label: 'Suriname'},
                { value: 'SJ', label: 'Svalbard And Jan Mayen'},
                { value: 'SZ', label: 'Swaziland'},
                { value: 'SE', label: 'Sweden'},
                { value: 'CH', label: 'Switzerland'},
                { value: 'SY', label: 'Syrian Arab Republic'},
                { value: 'TW', label: 'Taiwan'},
                { value: 'TJ', label: 'Tajikistan'},
                { value: 'TZ', label: 'Tanzania'},
                { value: 'TH', label: 'Thailand'},
                { value: 'TL', label: 'Timor-Leste'},
                { value: 'TG', label: 'Togo'},
                { value: 'TK', label: 'Tokelau'},
                { value: 'TO', label: 'Tonga'},
                { value: 'TT', label: 'Trinidad And Tobago'},
                { value: 'TN', label: 'Tunisia'},
                { value: 'TR', label: 'Turkey'},
                { value: 'TM', label: 'Turkmenistan'},
                { value: 'TC', label: 'Turks And Caicos Islands'},
                { value: 'TV', label: 'Tuvalu'},
                { value: 'UG', label: 'Uganda'},
                { value: 'UA', label: 'Ukraine'},
                { value: 'AE', label: 'United Arab Emirates'},
                { value: 'GB', label: 'United Kingdom'},
                { value: 'US', label: 'United States'},
                { value: 'UM', label: 'United States Outlying Islands'},
                { value: 'UY', label: 'Uruguay'},
                { value: 'UZ', label: 'Uzbekistan'},
                { value: 'VU', label: 'Vanuatu'},
                { value: 'VE', label: 'Venezuela'},
                { value: 'VN', label: 'Viet Nam'},
                { value: 'VG', label: 'Virgin Islands}, British'},
                { value: 'VI', label: 'Virgin Islands}, U.S.'},
                { value: 'WF', label: 'Wallis And Futuna'},
                { value: 'EH', label: 'Western Sahara'},
                { value: 'YE', label: 'Yemen'},
                { value: 'ZM', label: 'Zambia'},
                { value: 'ZW', label: 'Zimbabwe'}
            ],
            platform:['MT4','MT5'],
            currencies:['USD','EUR', 'GBP','CAD','AUD'],
            firstName:'',
            lastName:'',
            address:'',
            city:'',
            phone:'',
            birth:'',
            zip:'',
            email:'',
            id:'',
            country:'',
            client:[],
            type:[],
            baseSize:[
                5000,10000,25000,50000,100000],
            sizes:[],
            selectedCurrency:'USD',
            selectedPlatform:'',
            selectedSize:'',
            selectedType:'',
            acc26:true,
            acc40:false,
            acc80:false,
            orders:[],
            order:'',
            textErrCat:'',
            paymeth:'start',
            accsizes:[],
            checkText:'',
            items:[],
            orderID:'',
            apiUrl:'',
            reward:[],
            pointstext:'',
            formTot:0,
            formId:'',
            orderPlat:'',
            textRespCupon:'',
            validCupon:null,
            percCoupon:'',
            typeCoupon:'',
            orderErrors: [],
            loading:true,
            payDisabled:true,
            off30:false,
            off50:false
        }
    },
    methods:{
        formatCurrency(curr,value){
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: curr,
                minimumFractionDigits: 2,
            });
            return formatter.format(value);
        },
        getItems(){
            this.coupon=''
            let tag= this.$cookies.get('typeBuy')||""
            axios.get(`${this.$baseUrl}/ecommerce/getItems?tags=${tag}`).then((response)=>{
                this.items=response.data
                this.items.forEach(element => {
                    this.type.push({
                        title:element.name,
                        text:element.description.toLowerCase(),

                    })
                    element.versions.forEach(version=>{
                        this.sizes.push({
                            id:version.id,
                            size:version.size,
                            price:version.price,
                            id:version.id,
                            name:version.name
                        })
                    })
                });
                this.clientInfo()

            })
        },
        removeElement(item ){
            axios.post(`${this.$baseUrl}/ecommerce/cleanCart`, {
                "customer_id": this.id
            }).then((response)=>{
                try {
                    const curr = this.order.name.split(' ')[1];
                    const data = {
                        order_id: this.orderID,
                        ORDERID: this.orderID,
                        ITEM: this.order.id,
                        PRICE: this.order.price || 0,
                        CURR: curr,
                        SIZE: new Intl.NumberFormat('en-US').format(this.order.size),
                        NAME: this.order.name
                    };
                    window.sendinblue.track(
                        'cart_deleted',
                        {
                            email: this.client.email,
                            FIRSTNAME: this.client.firstName,
                            LASTNAME: this.client.lastName
                        },
                        {
                            data
                        }
                    );
                    window.sendinblue.track(
                        'article_removed',
                        {
                            email: this.client.email,
                            FIRSTNAME: this.client.firstName,
                            LASTNAME: this.client.lastName
                        },
                        {
                            data
                        }
                    );
                } catch (e) {
                    console.error(e);
                }
                this.changeStatus='remove'
                this.order=''
                this.textErrCat=''
                this.paymeth='start'
                this.isChecked2=false
                axios.get(`${this.$baseUrl}/ecommerce/cart/getCart?customer_id=${this.id}`).then((response)=>{
                    if (response.data.items.length === 0) {
                        // quando non ha items nel carrello torna indietro di una pagina
                        this.$router.push({ path: '/buy-account',})
                        return;
                    }
                })
            })
        },
        async clientInfo(){
            axios.get(`${this.$baseUrl}/customers/getCustomerInfo/${this.$cookies.get('emailAccount')}`).then((response)=>{
                this.client= response.data
                this.firstName=this.client.firstName
                this.lastName=this.client.lastName
                this.address=this.client.address
                this.zip=this.client.zip
                this.city=this.client.city
                this.country=this.client.country
                this.phone=this.client.phone
                this.birth= this.client.birthday
                this.id=this.client._id
                this.email=this.client.email

                axios.get(`${this.$baseUrl}/ecommerce/cart/getCart?customer_id=${this.id}`).then((response)=>{
                    if (response.data.items.length === 0) {
                        // quando non ha items nel carrello torna indietro di una pagina
                        window.history.back();
                        return;
                    }
                    response.data.items.forEach(elemento => {
                        this.sizes.forEach(element => {
                        if(element.id == elemento.itemId){
                            this.orders.push(element)
                        }
                    });
                    });
                    this.sizes.forEach(element => {
                        if(element.id == response.data.items[0].itemId){
                            this.order=element
                        }
                    });
                    this.orderPlat= response.data.items[0].platform
                    this.couponPromo()
                })
                this.getReward()
            })
        },
        async sendOrder() {
            this.checkText = '';

            if (!this.isChecked2) {
                this.checkText = "The acknowledgment of the terms and conditions of purchase is required";
                return;
            }

            this.creatingOrder = true;

            try {
                let origin= this.$route.query.utm_source|| this.$route.query.ref_origin;
                const orderConfirm = {
                    customer_id: this.id,
                    ref_origin:origin,
                    billing_details: {
                        address: this.address,
                        city: this.city,
                        country: this.country,
                        name: this.firstName,
                        surname: this.lastName,
                        zip: this.zip
                    }
                }

                const config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${this.$baseUrl}/ecommerce/cart/confirmOFPOrder`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: orderConfirm
                };

                const response = await axios.request(config);
                if(response.data._id){
                    this.orderID = response.data.id
                    this.paymeth = response.data.total <= 0 ? 'close' : 'open'
                    this.checkText = ''
                }else if(response.data.name='TotalAllocationError'){
                    this.checkText = response.data.message;
                }else{
                    this.checkText = 'Attention: An error occurred while saving the order';
                }


                try {
                    const curr = this.order.name.split(' ')[1];
                    const data = {
                        order_id: this.orderID,
                        ORDERID: this.orderID,
                        ITEM: this.order.id,
                        PRICE: this.order.price || 0,
                        CURR: curr,
                        SIZE: new Intl.NumberFormat('en-US').format(this.order.size),
                        NAME: this.order.name
                    };

                    window.sendinblue.track(
                        'order_created',
                        {
                            email: this.client.email,
                            FIRSTNAME: this.client.firstName,
                            LASTNAME: this.client.lastName
                        },
                        {
                            data,
                            ...data
                        }
                    );
                } catch (e) {
                    console.error('brevo', e);
                }
            }
            catch (ex) {
                const { errors } = err.response.data;
                if (!errors) {
                    console.error(err);
                    return;
                }

                this.orderErrors = errors;
                this.creatingOrder = false;
            }
        },
        payWith(value){
            this.loading=true
            this.payDisabled=false
            this.pointstext=''
            let payMethods={
                "order_id":this.orderID,
                "payment_type":value,
                "use_points":this.rewardPoints
            }
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${this.$baseUrl}/ecommerce/order/pay`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data : payMethods
            };
            axios.request(config).then((response) => {
                if (response.data === 'Not enough points') {
                    this.pointstext = response.data;
                    this.getReward()
                } else {
                    try {
                        const curr = this.order.name.split(' ')[1];
                        this.$gtm.trackEvent({
                            event: 'begin_checkout',
                            value: {
                                ORDERID: this.orderID,
                                ITEM: this.order.id,
                                PRICE: this.order.price,
                                CURR: curr,
                                SIZE: new Intl.NumberFormat('en-US').format(this.order.size),
                                NAME: this.order.name
                            }
                        })
                    } catch (e) {
                        console.error('gtm', e);
                    }
                    if (value === 'stripe') {
                        window.open(response.data.url, "_blank");
                    } else if (value === 'coinbase') {
                        window.open(response.data.url, "_blank");
                        this.loading=false
                        this.payDisabled=true
                    } else if (value === 'trustPayment') {
                        this.formTot = response.data.total;
                        this.formId = response.data.OFPPaymentId;
                        if (this.formId !== '' && this.formTot !== 0) {
                            this.$forceUpdate();
                            this.$nextTick(() => {
                                this.sendForm();
                            });
                            this.loading=false
                            this.payDisabled=true
                        }

                    }
                }
            }).catch((error) => {
                console.error("Errore durante la chiamata axios:", error);
            });
        },
        sendForm(){
            let form = document.getElementById('form')
            form.submit()
        },
        getReward(){
            axios.get(`${this.$baseUrl}/ecommerce/getCustomerReward?id=${this.id}`).then((response)=>{
                this.reward=response.data
                this.loading=false
            })
        },
        addCupon(){
            this.percCoupon=''
            axios.get(`${this.$baseUrl}/ecommerce/check-coupon?code=${this.coupon}&customer_id=${this.id}`).then((response)=>{
                this.textRespCupon= response.data.data.reason
                this.validCupon = response.data.data.isValid
                if(this.validCupon){
                    this.percCoupon= response.data.data.coupon.value
                    this.typeCoupon=response.data.data.coupon.type
                    axios.post(`${this.$baseUrl}/ecommerce/cart/addCouponToCart`, {
                        "customer_id": this.id,
                        "coupon": this.coupon
                    }).then((resp)=>{
                    })
                }
            })
        },
        couponPromo(){
            const now=new Date()
            const year = new Date().getUTCFullYear();
            const startDate30 =new Date(Date.UTC(year, 1, 21, 14, 30));
            const endDate30 = new Date(Date.UTC(year, 2, 7, 23, 59));
            const startDate50=new Date(year, 2,11)
            const endDate50 = new Date(Date.UTC(year, 2, 25, 23, 59));
            if(now>=startDate30 && now<= endDate30){
                let coupon='SPECIAL30'
                axios.get(`${this.$baseUrl}/ecommerce/check-coupon?code=${coupon}&customer_id=${this.id}`).then((response)=>{
                this.validCupon = response.data.data.isValid
                if(this.validCupon){
                    axios.post(`${this.$baseUrl}/ecommerce/cart/addCouponToCart`, {
                        "customer_id": this.id,
                        "coupon": coupon
                    }).then((resp)=>{
                        this.off30=true
                        this.order.priceOFF= Math.ceil(this.order.price * 0.7);
                    })
                }
            })
            }else if(now>=startDate50 && now<= endDate50){
                let coupon='OFP50'
                axios.get(`${this.$baseUrl}/ecommerce/check-coupon?code=${coupon}&customer_id=${this.id}`).then((response)=>{
                this.validCupon = response.data.data.isValid
                if(this.validCupon){
                    axios.post(`${this.$baseUrl}/ecommerce/cart/addCouponToCart`, {
                        "customer_id": this.id,
                        "coupon": this.coupon
                    }).then((resp)=>{
                        this.off50=true
                        this.order.priceOFF= Math.ceil(this.order.price * 0.5);
                    })
                }
            })
            }
        }
    },
    mounted(){
        this.getItems()
    },
    beforeMount(){
        if(!this.$cookies.get('emailAccount')){
            this.$router.push({ path: '/',})
        }
    }

}
</script>

<style scoped>
.form-control{
    background-color: transparent!important;
    border: 1px solid #868789;
    padding: 5px;
}
option{
    background-color: #0d0b27;
    color: white;
}
br{
    margin: 4px, 0px;
}
.inputCoupon{
    background-color: transparent!important;
    border: 1px solid #868789;
    color: white;
}
.text-success-ofp {
    color: #6CF542 !important;
}
.text-white {
    color: white !important;
}
</style>
