<template>
    <div id="main-wrapper "  >
        <Spinner v-if="loading"/>
        <!-- Topbar -->
        <header class="topbar"  :class="{ 'opacity-25': loading }">
            <Navbar :stopnav="stopnavbar" ref="navbarRef" />
        </header>
        <!-- /Toolbar -->

        <!-- Sidebar -->
        <aside class="left-sidebar"  :class="{ 'opacity-25': loading }">
            <div class="scroll-sidebar">
                <Sidebar @rank="watchRank" />
            </div>
        </aside>
        <!-- /Sidebar -->

        <!-- Page -->
        {{ formatData('2024-01-15T00:00:00')}}
        <div class="page-wrapper" v-if="!loading && accountInfo" :class="{ 'opacity-25': showRank }">
            <bannerViolation :status="accountInfo.status" :reason="accountInfo.reason"/>
            <div>
                <!-- Header -->
                <div class="row m-0 w-100 p-2 mt-3 mt-md-0" v-if="accountInfo.status === 'active'">
                    <div v-if="accountInfo.type_account.includes('Monthly')" class="d-flex no-block align-items-center text-white fw-semibold d-flex">
                        <span v-if="accountInfo.datePayout">
                            <h2 class="text-capitalize">{{formatDay(accountInfo.datePayout.slice(accountInfo.datePayout.indexOf(' ') + 1)) }}</h2>
                            <h4>{{ formatData(accountInfo.datePayout.slice(accountInfo.datePayout.indexOf(' ') + 1) )}}</h4>
                        </span>
                        <div class="ms-auto text-end">
                            <h2>{{ accountInfo.nextPayout }} {{ $t('Days') }}</h2>
                            <medium>{{ $t('toPayout') }}</medium>
                        </div>
                    </div>
                    <div v-if="accountInfo.type_account.includes('Demand')" class="rounded py-3 px-4 bgSfumato no-block  text-white fw-semibold ">
                        <div v-if=" requestPayout && requestPayout.status=='pending'" class="d-flex align-items-center justify-content-between w-100">
                            <div class="d-flex flex-column">
                                <h4 class="fw-bold">{{ $t('requestOndemandTitle') }}:</h4>
                                <small> {{ $t('requestOndemandText1') }} : {{ requestPayout.created_at }}</small>
                            </div>
                            <div class="ms-auto text-end">
                                <h5 class="text-uppercase text-warning">{{ requestPayout.status }}</h5>
                            </div>
                        </div>
                        <div v-else class="d-flex flex-column align-items-start justify-content-start w-100">
                            <div class="d-flex align-items-center justify-content-between w-100">
                                <h6>{{ $t('requestOndemandSubTitle') }}</h6>
                                <div class="ms-auto text-end">
                                    <button :disabled="consistency > 20 || consistency < 0" class="btn btn-primary" @click="askPayout()">{{ $t('requestOndemandButton') }}</button>
                                </div>
                            </div>
                            <div v-if=" requestPayout && requestPayout.status=='cancelled'">
                               <i class="text-danger"> {{ $t('Warning') }} </i> :   {{ $t('requestOndemandText1') }} : {{ formatData(requestPayout.created_at) }}  {{ $t('requestOndemandText2') }} <i class="text-danger text-uppercase">{{ $t('requestCancelled') }}</i>
                            </div>
                        </div>
                        <h5 v-if="requestError"><span class="text-danger fw-bold">{{ $t('Warning') }}:</span> {{ $t('requestOndemandFailed') }}</h5>
                    </div>
                </div>
                <!-- /Header -->

                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-12 mt-2 mt-lg-0 d-flex align-items-center justify-content-between d-flex">
                            <!-- Breadcrumb -->
                            <div class="d-flex justify-content-start gap-1" style="display: flex!important;">
                                <p class="text-white-50 m-0 aling-items-center" style="display: flex!important;">
                                    <router-link class="text-white-50" to="/home">
                                        <font-awesome-icon icon="fa-solid fa-house" class="m10"/>
                                        Dashboard
                                    </router-link>
                                </p>
                                <p class="text-white-50 m-0">&raquo;</p>
                                <p class="text-primary m-0"> {{ $t('Account_Details') }}</p>
                            </div>
                            <!-- /Breadcrumb -->

                            <!-- Info Account -->
                            <div class="mt-2 mt-lg-0">
                                <div class="displayFlex gap-2 justify-content-end">
                                    <button type="button" class="btn border border-white text-white d-flex gap-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <i class="mdi mdi-information-outline"></i>
                                        <span class="d-none d-lg-block">{{ $t('Info') }} Account</span>
                                    </button>
                                    <button type="button" class="btn border border-primary text-primary d-flex gap-2" @click="refreshData()">
                                        <i class="mdi mdi-refresh"></i>
                                        <span class="d-none d-lg-block">{{ $t('UpdateData') }}</span>
                                    </button>
                                </div>
                                <p class="mt-2 text-end">
                                    {{ $t('DelayData') }}
                                </p>
                            </div>
                            <!-- Info account -->
                        </div>
                    </div>

                    <!-- Modal: Info Account -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-3 text-white" id="exampleModalLabel">
                                        Account: {{ accountInfo.username }}
                                    </h1>
                                    <button type="button" class="btn btn-close text-white" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <div class="modal-body">
                                    <div class="m-2">
                                        <ul class="nav nav-tabs" id="infoAccountTab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <a id="cred" class="nav-link active fw-bold" data-bs-toggle="tab" data-bs-target="#credentials-tab" type="button" role="tab" aria-controls="credentials" aria-selected="true">
                                                    {{ $t('Credentials') }}
                                                </a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a id="info" class="nav-link fw-bold" data-bs-toggle="tab" data-bs-target="#info-tab" type="button" role="tab" aria-controls="info" aria-selected="true">
                                                    Account {{ $t('Info') }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-content px-2" id="infoAccountTabContent">
                                        <div class="tab-pane fade show active" id="credentials-tab" role="tabpanel" aria-labelledby="credentials-tab">
                                            <div class="d-flex flex-column data-account w-100 py-2 px-2">
                                                <p class="d-flex justify-content-between mb-0">
                                                    {{ $t('Login') }}: <span id="login" :class="{ 'text-white': true, 'fw-bold': true, 'ms-4': true, 'me-2': true, 'blurred': !credentials }">
                                                        <i data-toggle="tooltip" data-bs-placement="bottom" title="Copy" role="button" @click="copyToClipboard('login')" :class="{ 'ti-layers': true, 'btn': true, 'blurred': !credentials }"></i>
                                                        {{ accountInfo.username }}
                                                    </span>
                                                </p>

                                                <hr class="m-0">

                                                <p class="d-flex justify-content-between mb-0">
                                                    {{ $t('Password') }}: <span id="password" :class="{ 'text-white': true, 'fw-bold': true, 'ms-4': true, 'me-2': true, 'blurred': !credentials }">
                                                        <i :class="{ 'blurred': !credentials }" role="button" @click="copyToClipboard('password')" class="ti-layers btn"></i>
                                                        {{ accountInfo.password }}
                                                    </span>
                                                </p>

                                                <hr class="m-0">

                                                <p class="d-flex justify-content-between mb-0">
                                                    {{  $t('Server') }}: <span id="server" :class="{ 'text-white': true, 'fw-bold': true, 'ms-4': true, 'me-2': true, 'blurred': !credentials }">
                                                        <i :class="{ 'blurred': !credentials }" role="button" @click="copyToClipboard('server')" class="ti-layers btn"></i>
                                                        {{ accountInfo.server }}
                                                    </span>
                                                </p>

                                                <hr class="m-0">

                                                <p class="d-flex justify-content-between mb-0">
                                                    {{  $t('Platform') }}: <span :class="{ 'text-white': true, 'text-uppercase': true, 'fw-bold': true, 'ms-4': true, 'me-2': true, 'blurred': !credentials }">
                                                        {{ accountInfo.platform }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="info-tab" role="tabpanel" aria-labelledby="info-tab">
                                            <div class="my-3 d-flex flex-column data-account p-2" style="display: flex !important;">
                                                <div class="d-flex align-items-center justify-content-between" style="display: flex !important;">
                                                    <div class="d-flex align-items-center" style="display: flex !important;">
                                                        <div class="row-icon">
                                                            <i class="icons-Calendar-4"></i>
                                                        </div>
                                                        <div class="d-flex flex-column" style="display: flex !important;">
                                                            <h6 class="m-0 fw-bold">{{  $t('Start') }}</h6>
                                                            <p class="m-0" >
                                                                {{  $t('StartTradPeriod') }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p v-if="accountInfo.created_at" class="m-0">
                                                            {{ accountInfo.created_at.toLocaleString() }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between" style="display: flex !important;">
                                                    <div class="d-flex align-items-center" style="display: flex !important;">
                                                        <div class="row-icon">
                                                            <i class="icons-Dollar-Sign2"></i>
                                                        </div>
                                                        <div class="d-flex flex-column" style="display: flex !important;">
                                                            <h6 class="m-0 fw-bold">{{  $t('InitBalance') }}</h6>
                                                            <p class="m-0" >{{  $t('Size') }}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0">
                                                            {{ currency }}
                                                            {{ formatAmount(accountInfo.initial_balance) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between" style="display: flex !important;">
                                                    <div class="d-flex align-items-center" style="display: flex !important;">
                                                        <div class="row-icon">
                                                            <i class="icons-Coins"></i>
                                                        </div>
                                                        <div class="d-flex flex-column" style="display: flex !important;">
                                                            <h6 class="m-0 fw-bold"> {{  $t('Currency') }}</h6>
                                                            <p class="m-0" ></p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0" v-if="accountInfo.currency">
                                                            {{ accountInfo.currency }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between" style="display: flex !important;">
                                                    <div class="d-flex align-items-center" style="display: flex !important;">
                                                        <div class="row-icon">
                                                            <i class="icons-Money-2"></i>
                                                        </div>
                                                        <div class="d-flex flex-column" style="display: flex !important;">
                                                            <h6 class="m-0 fw-bold">{{  $t('WithdrawalTot') }}</h6>
                                                            <p class="m-0" ></p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0">
                                                            {{ currency }} {{ accountInfo.totalPayouts }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between" style="display: flex !important;">
                                                    <div class="d-flex align-items-center" style="display: flex !important;">
                                                        <div class="row-icon">
                                                            <i class="icons-Satelite"></i>
                                                        </div>
                                                        <div class="d-flex flex-column" style="display: flex !important;">
                                                            <h6 class="m-0 fw-bold">{{  $t('Platform') }}</h6>
                                                            <p class="m-0" >{{  $t('PlatformText') }}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0" v-if="accountInfo.platform">
                                                            {{ accountInfo.platform.toUpperCase() }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between" style="display: flex !important;">
                                                    <div class="d-flex align-items-center" style="display: flex !important;">
                                                        <div class="row-icon">
                                                            <i class="icons-Server"></i>
                                                        </div>
                                                        <div class="d-flex flex-column" style="display: flex !important;">
                                                            <h6 class="m-0 fw-bold">{{  $t('Server') }}</h6>
                                                            <p class="m-0" >{{  $t('ServerText') }}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="m-0">{{ accountInfo.server }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Modal: Info Account -->

                    <!--- Content --->
                    <div class="d-flex justify-content-between align-items-center mt-4" >
                        <h2 class="text-white fw-bold mt-2 d-none d-lg-block">Account: {{ accountInfo.username }}</h2>
                        <p class="d-block d-lg-none text-center">{{  $t('ResetDaily') }}</p>
                        <div class="d-flex justify-content-center align-items-center gap-2" style="display: flex!important;">
                            <p class="m-0 d-none d-lg-block ">{{  $t('ResetDaily') }}:</p>
                            <div class="progress-bar progress-padding" :style="`background: radial-gradient(closest-side, #010916 80%, transparent 80% 100%), conic-gradient(#fb9678 ${100 - ((countdown.hours*100)/24)}%, rgb(251 150 120 / 50%) 0)`">
                                {{ countdown.hours }}hr {{ countdown.minutes }}m
                                <progress :value="countdown.hours" min="0" max="24" class="d-none"></progress>
                            </div>
                        </div>
                    </div>
                    <div class="row marginpage text-white justify-content-evenly my-5 pb-5">
                        <!-- Widgets: Desktop -->
                        <div class=" row d-none d-xl-flex justify-content-between p-0">
                            <div class="col-sm-3 "> <!-- Growth % -->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountInfo.growth" class="my-auto fw-bold text-primary">
                                                    {{ accountInfo.growth.toFixed(2) }}%
                                                </h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">{{  $t('trial-growth') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3 "> <!-- Total profits -->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountMetrics.profit" class="my-auto fw-bold text-primary">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.profit.toFixed(2)) }}
                                                </h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">{{  $t('trial-totProf') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3 "> <!--- Account balance --->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountMetrics.balance" class="my-auto fw-bold text-primary">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.balance) }}
                                                </h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">

                                                <h6 class="text-white font-medium">Acc. Balance</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3 "> <!--- Account equity --->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountMetrics.equity" class="my-auto fw-bold text-primary">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.equity) }}
                                                </h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">Acc. Equity</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" row d-none d-xl-flex justify-content-between p-0">
                            <div class="col-sm-3 "> <!--- Profit factor --->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountMetrics.profitFactor" class="my-auto fw-bold text-primary">
                                                    {{ accountMetrics.profitFactor.toFixed(2) }}
                                                </h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">{{ $t('ProfitFactor') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3 "> <!--- Expectacy --->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountMetrics.expectancy" class="my-auto fw-bold text-primary">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.expectancy) }}
                                                </h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">{{ $t('Expectancy') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3"> <!--- R:R --->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="isNaN(rr)">-</h2>
                                                <h2 v-else class="my-auto fw-bold text-primary">1 : {{ rr }}</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">{{ $t('profile-ARR') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3 "> <!--- commissions --->
                                <div class="card ">
                                    <div class="card-body flex-column active">
                                        <div class=" mt-3">
                                            <div class="px-0">
                                                <h2 v-if="accountMetrics.commissions" class="my-auto fw-bold text-primary">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.commissions) }}</h2>
                                                <h2 v-else>-</h2>
                                            </div>
                                        </div>
                                        <div class="d-flex no-block al m-r-15ign-items-center">
                                            <div class="m-t-10 d-flex gap-2">
                                                <h6 class="text-white font-medium">{{  $t('trial-totComm') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <!-- /Widgets: Desktop -->

                        <!-- Widgets: Mobile -->
                        <div id="carouselExampleInterval" class="carousel slide d-sm-block d-xl-none "
                             data-bs-ride="carousel">
                            <div class="carousel-inner" style="width: 95% !important ; margin: 0 auto !important">
                                <div class="carousel-item active" data-bs-interval="10000">
                                    <div class="card lossBox" id="card-primary">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountMetrics.profitFactor" class="my-auto fw-bold text-primary">
                                                        {{ formatAmount(accountMetrics.profitFactor) }}
                                                    </h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">{{ $t('ProfitFactor') }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-bs-interval="2000">
                                    <div class="card expBox" id="card-aqua">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountMetrics.expectancy"
                                                        class="my-auto fw-bold text-primary">
                                                        {{ currency }}
                                                        {{ formatAmount(accountMetrics.expectancy)}}
                                                    </h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">{{ $t('Expectancy') }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card winBox" id="card-pink">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountMetrics.balance" class="my-auto fw-bold text-primary">
                                                        {{ currency }}
                                                        {{ formatAmount(accountMetrics.balance) }} %
                                                    </h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">Acc. Balance</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card totalBox" id="card-azure">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountMetrics.equity" class="my-auto fw-bold text-primary">
                                                        {{ currency }}
                                                        {{ accountMetrics.equity.toFixed(2) }}</h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">Acc. Equity</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button"
                                    data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button"
                                    data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                        <div id="carouselExampleInterval2" class="carousel slide d-sm-block d-xl-none "
                             data-bs-ride="carousel">
                            <div class="carousel-inner" style="width: 95% !important ; margin: 0 auto !important">
                                <div class="carousel-item active" data-bs-interval="10000">
                                    <div class="card lossBox" id="card-primary">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountInfo.growth"
                                                        class="my-auto fw-bold text-primary">
                                                        {{ accountInfo.growth.toFixed(2) }}%</h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">{{  $t('trial-growth') }} %</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-bs-interval="2000">
                                    <div class="card expBox" id="card-aqua">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="isNaN(rr)">-</h2>
                                                    <h2 v-else class="my-auto fw-bold text-primary">1 : {{ rr }}</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">{{  $t('profile-ARR') }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card winBox" id="card-pink">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountMetrics.profit" class="my-auto fw-bold text-primary">
                                                        {{ currency }}
                                                        {{ formatAmount(accountMetrics.profit.toFixed(2)) }}
                                                    </h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">{{  $t('trial-totProf') }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="card totalBox" id="card-azure">
                                        <div class="card-body flex-column active">
                                            <div class=" mt-3">
                                                <div class="px-0">
                                                    <h2 v-if="accountMetrics.commissions" class="my-auto fw-bold text-primary">
                                                        {{ currency }}
                                                        {{ formatAmount(accountMetrics.commissions) }}
                                                    </h2>
                                                    <h2 v-else>-</h2>
                                                </div>
                                            </div>
                                            <div class="d-flex no-block al m-r-15ign-items-center">
                                                <div class="m-t-10 d-flex gap-2">

                                                    <h6 class="text-white font-medium">{{  $t('trial-totComm') }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button"
                                    data-bs-target="#carouselExampleInterval2" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button"
                                    data-bs-target="#carouselExampleInterval2" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                        <!-- /Widgets: Mobile -->

                        <!-- Charts: Stats -->
                        <div class="row p-0 mt-5" v-if="accountMetrics.currencySummary && accountMetrics.currencySummary.length > 0">
                            <div class="d-flex justify-content-between">
                                <h3 class="text-start text-white">{{  $t('Stats') }}</h3>
                                <div class="col-5 col-lg-1 ms-auto ">
                                    <select class="form-select my-3 " v-model="selectedCurrency" aria-label="Default select example">
                                        <option value="all">{{  $t('All') }}</option>
                                        <option v-for="element in accountMetrics.currencySummary" :value="element.currency" :key="element">
                                            {{ element.currency }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row stats-charts ">
                                <div class="col-12 col-lg-4 mb-1">
                                    <div class="card  mb-0 d-flex flex-column  ">
                                        <div class="d-flex flex-column text-white">
                                            <h5 class="text-white">{{  $t('trial-NtotTrades') }}</h5>
                                            <h1 v-if="accountMetrics.trades">{{ accountMetrics.trades }}</h1>
                                            <h1 v-else>0</h1>
                                        </div>
                                        <TotalTradesBarChart :selectedoption="selectedCurrency" :metrics="accountMetrics" :currency="currency"/>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 mb-1">
                                    <div class="card  mb-0 ">
                                        <div class="d-flex flex-column text-white">
                                            <h5>{{  $t('trial-totProf') }}</h5>
                                            <h1 v-if="accountMetrics.profit">{{ currency }} {{ formatAmount(accountMetrics.profit.toFixed(2)) }}</h1>
                                            <h1 v-else>0</h1>
                                        </div>
                                        <TotalProfitBarChart :selectedoption="selectedCurrency" :metrics="accountMetrics" :currency="currency"/>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 mb-1">
                                    <div class="card  mb-0 ">
                                        <div class="d-flex flex-column text-white" >
                                            <h5>{{  $t('trial-WtotTrades') }}</h5>
                                            <h1 v-if="accountMetrics.wonTradesPercent">{{ accountMetrics.wonTradesPercent.toFixed(2) }}%</h1>
                                            <h1 v-else>0%</h1>
                                        </div>
                                        <ProfitsPieChart :selectedoption="selectedCurrency" :metrics="accountMetrics" :currency="currency"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Charts: Stats -->

                        <!-- parte sx-->
                        <div class="row d-xl-flex d-md-block justify-content-between p-0 mb-xl-5 mb-5">
                            <div class="box-trail col-12 ">
                                <!--Grafico-->
                                <div class=" py-3 d-block my-5" style="height: 700px;">
                                    <div class="" style="height: 90%;" v-if="accountChartTracking && accountMetrics && currency && accountId">
                                        <ChartLine :currency="currency" :metrics="accountMetrics" :chartTracking="accountChartTracking" :accountId="accountId"/>
                                    </div>
                                </div>

                            </div>
                            <!-- parte dx-->
                        </div>

                        <!-- Rules-->
                        <div class="box-trail col-12 my-4">
                            <!--Rules-->
                            <div class="d-flex align-items-center mb-3">
                                <h3 class="card-title text-white m-0 me-3">{{ $t('trial-lossAnal') }}</h3>
                                <a  @click="postTvsTot()" v-if="accountInfo.status === 'active'"
                                        class="btn btn-primary position-relative"
                                        :class="{ 'disabled': canReset === false || openTrades.length > 0 }"
                                        data-bs-toggle="modal" data-bs-target="#exampleModalReset">
                                        {{ $t('btn-reset') }}
                                </a>
                                <!-- Button trigger modal -->
                                    <font-awesome-icon class="text-white btn" icon="fa-solid fa-circle-info" role="button" data-bs-toggle="modal" data-bs-target="#exampleModalinfotvs"/>
                                    <!-- Modal -->
                                    <div class="modal fade" id="exampleModalinfotvs" tabindex="-1" aria-labelledby="exampleModalinfotvsLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title text-primary fs-5" id="exampleModalinfotvsLabel"> {{ $t('trial-resetRule') }}</h1>
                                        </div>
                                        <div class="modal-body">
                                            <ul>
                                                <li>
                                                    {{ $t('trial-resetRule1') }}
                                                </li>
                                                <li>
                                                    {{ $t('trial-resetRule2') }}
                                                </li>
                                                <li>
                                                    {{ $t('trial-resetRule3') }}
                                                </li>
                                                <li>
                                                    {{ $t('trial-resetRule4') }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Close') }}</button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModalReset" tabindex="-1" aria-labelledby="exampleModalResetLabel" aria-hidden="true">
                                    <Spinner v-if="loadingPay" />
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h2 class="modal-title" id="exampleModalResetLabel">{{ $t('btn-reset') }}</h2>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="col-12 mb-3 d-flex align-items-center justify-content-between rounded p-2 border border-primary">
                                                    <h5 class="m-0 p-0 mb-2  text-white"> {{ $t('trial-resetCost') }} : </h5>
                                                    <h4>{{ formatCurrency(tvsReset.total) }}</h4>
                                                </div>
                                                <div v-if="tvsReset.total>0" class="col-12 d-block d-xl-flex justify-content-between">
                                                    <div class="col-12 col-xl-5 btn btn-outline-primary border-1 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" @click="payWith('trustPayment')">
                                                        <div class="d-flex align-items-center flexdisplay"  >
                                                                <form id="form" method="POST" action="https://payments.securetrading.net/process/payments/choice">
                                                                    <input type="hidden" name="sitereference" :value="this.$trustpayment_domain">
                                                                    <input type="hidden" name="stprofile" value="default">
                                                                    <input type="hidden" name="currencyiso3a" value="USD">
                                                                    <input type="hidden" name="mainamount" v-model="tvsReset.total">
                                                                    <input type="hidden" name="version" value="2">
                                                                    <input type="hidden" name="billingcontactdetailsoverride" value="1">
                                                                    <input type="hidden" name="customercontactdetailsoverride" value="1">
                                                                    <input type="hidden" name="ruleidentifier" value="STR-6">
                                                                    <input type=hidden name="successfulurlredirect" :value="'https://client.ofpfunding.com/successPayment?customer_id=' + accountInfo.customer_id">
                                                                    <input type="hidden" name="allurlnotification" value="https://ofp-api.ait-tech.it/api/webhook/payment-gateway?gateway=trustpayment">
                                                                    <input type="hidden" name="orderreference" v-model="tvsReset.id">
                                                                    <input type="submit" class="d-none btn" >
                                                                </form>
                                                            <div class="d-flex gap-3 align-items-center">
                                                                <img class="" style="height: 2.5rem" src="/assets/images/trustwhite.png" alt="">
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-center align-items-center flexdisplay gap-3 col-12">
                                                            <img class="col-2" src="/assets/images/card.png" alt="">
                                                            <img class="col-2" src="/assets/images/visa.png" alt="">
                                                            <img class="col-2" src="/assets/images/google_pay_light.png" alt="">
                                                            <img class="col-2" src="/assets/images/apple-pay.png" alt="">
                                                        </div>
                                                        <div class="d-flex gap-2 align-items-center">
                                                            <h4 class="text-uppercase text-white m-0 p-0"> Trustpayment</h4>
                                                            <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'credit-card']" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-xl-5 btn btn-outline-primary border-1 d-flex flex-column gap-3 align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" @click="payWith('coinbase')">
                                                        <div class="d-flex align-items-center flexdisplay">
                                                            <img class="" style="height: 2.5rem;" src="/assets/images/coinbase_white.png" alt="">
                                                        </div>

                                                        <div class="d-flex justify-content-center align-items-center flexdisplay gap-2 col-12">
                                                            <img  class="col-3" src="/assets/images/bitcoin.png" alt="">
                                                            <img  class="col-3" src="/assets/images/tether.png" alt="">
                                                            <img  class="col-3" src="/assets/images/cryptocurrency.png" alt="">
                                                        </div>

                                                        <div class="d-flex gap-2 align-items-center">
                                                            <h4 class="text-uppercase text-white m-0 p-0"> Cryptocurrency</h4>
                                                            <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'coins']" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <h5><span class="text-danger">Attention</span> : Please contact the Customer Support</h5>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Close') }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /Modal -->
                            </div>
                            <div class="card p-3">
                                <div class="d-flex align-items-center justify-content-end">
                                    <div class=" d-flex justify-content-between gap-3 border border-2 p-3 align-items-center z-" style='display: flex!important;z-index: 5;'>
                                        <h6 class="m-0 p-0 text-white">{{ $t('Rules') }}: </h6>
                                        <div class="d-flex gap-3">
                                            <div class=" align-items-center justify-content-between" style="display: flex !important;">
                                                <div class="align-items-center gap-3 text-white" style="display: flex !important;">
                                                    <div :class="{ 'text-danger': !accountInfo.rule_1 }">
                                                        {{ $t('trial-5%') }}
                                                    </div>
                                                </div>
                                                <div class="ms-2">
                                                    <font-awesome-icon class="text-white" icon="fa-solid fa-circle-info" role="button" @click="togglePopup(1)"/>
                                                    <div :class="{'d-block': popupShowed === 1, 'd-none': popupShowed !== 1, 'card': true, 'text-white': true, 'rulesBox': true}" style="background-color:#1b222f">
                                                        <font-awesome-icon icon="fa-solid fa-circle-xmark"  style="cursor: pointer; padding: 5px; color: gray;" @click="togglePopup(1)"/>
                                                        <p>{{ $t('trial-5%text') }}</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" align-items-center justify-content-between" style="display: flex !important;">
                                                <div class=" align-items-center gap-3 text-white" style="display: flex !important;">
                                                    <div :class="{ 'text-danger': !accountInfo.rule_2 }">
                                                        {{ $t('trial-10%') }}
                                                    </div>
                                                </div>
                                                <div class="ms-2">
                                                    <font-awesome-icon class="text-white" icon="fa-solid fa-circle-info" role="button" @click="togglePopup(2)"/>
                                                    <div style="background-color:#1b222f" :class="{'d-block': popupShowed === 2, 'd-none': popupShowed !== 2, 'card': true, 'text-white': true, 'rulesBox': true }">
                                                        <font-awesome-icon icon="fa-solid fa-circle-xmark" style="cursor: pointer; padding: 5px; color: gray;" @click="togglePopup(2)"/>
                                                        <p>{{ $t('trial-10%text') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" align-items-center justify-content-between" style="display: flex !important;">
                                                <div class=" align-items-center gap-3 text-white" style="display: flex !important;">
                                                    <div :class="{ 'text-danger': consistency > 25 }">
                                                        {{ $t('trial-TVS') }}
                                                    </div>
                                                </div>
                                                <div class="ms-2">
                                                    <font-awesome-icon class="text-white" icon="fa-solid fa-circle-info" role="button" @click="togglePopup(3)"/>
                                                    <div style="background-color:#1b222f" :class="{'d-block': popupShowed === 3, 'd-none': popupShowed !== 3, 'card': true, 'text-white': true, 'rulesBox': true }">
                                                        <font-awesome-icon icon="fa-solid fa-circle-xmark" style="cursor: pointer; padding: 5px; color: gray;" @click="togglePopup(3)"/>
                                                        <p>{{ $t('trial-TVStext') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!loading" class="rules-mobile my-3 d-flex d-md-flex flex-column rounded" style="row-gap: 40px; padding: 20px 15px;display: flex!important;">
                                    <div class="text-white">
                                        <h4 class="m-t-30">{{ $t('trial-AvaibDLoss') }}</h4>
                                        <div class="d-flex justify-content-between my-2 fw-bold text-white" style="display: flex!important;">
                                            <span>
                                                {{
                                                    lossAnalytics.daily_loss > percent5 ?
                                                        formatAmount(((Number(lossAnalytics.daily_loss) - Number(percent5)) * -1).toFixed(2)) :
                                                        formatAmount(( Number(percent5) + Number(lossAnalytics.daily_loss)).toFixed(2))
                                                }}
                                                <span class="mx-1">/</span> {{ formatAmount((percent5).toFixed(2)) }}</span>
                                            <span
                                                class="pull-right text-white ms-auto">{{ (lossAnalytics.daily_progress_bar || 0) > 100 ? 100+((lossAnalytics.daily_progress_bar - 100) * -1).toFixed(0) : (lossAnalytics.daily_progress_bar*100 || 0)+100 }}%<span
                                                class="mx-1">/</span> 100%</span>
                                        </div>
                                        <div class="progress ">
                                            <div class="progress-bar bg-danger wow animated progress-animated"
                                                 :style="`width:${ (lossAnalytics.daily_progress_bar || 0) > 100 ? 100+((lossAnalytics.daily_progress_bar - 100) * -1).toFixed(2) : (lossAnalytics.daily_progress_bar*100 || 0)+100 }%`"
                                                 style="; height:6px; border-radius:0px" role="progressbar"></div>
                                        </div>
                                        <h4 class="m-t-30">{{ $t('trial-AvaibLoss') }} </h4>
                                        <div class="d-flex justify-content-between my-2 fw-bold text-white"
                                             style="display: flex!important;">
                                            <span>{{ formatAmount((lossAnalytics.account_loss).toFixed(2)) }}</span> <span class="mx-1">/</span>
                                            <span>{{ formatAmount((percent10).toFixed(2)) }}</span>
                                            <span
                                                class="pull-right text-white ms-auto">{{ (lossAnalytics.account_progress_bar*100).toFixed(0) || 0 }}%<span
                                                class="mx-1">/</span> 100%</span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-danger wow animated progress-animated"
                                                 :style="{ 'width': `${lossAnalytics.account_progress_bar*100}%`, 'height': '6px', 'border-radius': '0px' }" role="progressbar"></div>
                                        </div>
                                        <h4 class="m-t-30">{{ $t('trial-TVS') }}</h4>
                                        <div class="d-flex justify-content-between my-2 fw-bold text-white"
                                             style="display: flex!important;">
                                            <span v-if="(accountInfo.consistencyScore) "
                                                  class="pull-right text-white">{{ (accountInfo && accountInfo.consistencyScore && accountInfo.consistencyScore.scoreValue ? parseFloat(accountInfo.consistencyScore.scoreValue).toFixed(2) : '0') }}%<span
                                                class="mx-1">/</span> 100%
                                            </span>
                                            <span v-else>
                                            No Data
                                        <span class="mx-1">/ 100</span>
                                        </span>
                                            <span v-if="(accountInfo.consistencyScore)"
                                                  class="pull-right text-white ms-auto">{{ (accountInfo && accountInfo.consistencyScore && accountInfo.consistencyScore.scoreValue ? parseFloat(accountInfo.consistencyScore.scoreValue).toFixed(2) : '0') }}%<span
                                                class="mx-1">/</span> 100%
                                            </span>
                                            <span v-else
                                                  class="pull-right text-white ms-auto">100%<span
                                                class="mx-1">/</span> 100%
                                            </span>
                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar bg-info wow animated progress-animated"
                                                 :style="(accountInfo.consistencyScore)?`width:${parseFloat(accountInfo.consistencyScore.scoreValue).toFixed(0)}%`: '0'"
                                                 style=";height:6px; border-radius:0px" role="progressbar"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-trail col-12  my-4" v-if="accountInfo.bad_trades" style="height: 550px;">
                            <h3 class="card-title text-white mb-0">Bad Trades</h3>
                            <p class="">2 Minutes Rule</p>
                            <div class="card p-3" style="max-height: 500px; overflow-y: auto">
                                <table class="table mb-0">
                                    <thead class="bg-primary">
                                    <tr>
                                        <th>Position Id</th>
                                        <th>Symbol</th>
                                        <th>Ticket Id</th>
                                        <th>Type</th>
                                        <th>State</th>
                                        <th>Time</th>
                                        <th>Done Time</th>
                                        <th>Volume</th>
                                        <th>StopLoss</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(record) in accountInfo.bad_trades" :key="record">
                                        <tr  v-for="rec in JSON.parse(record)" :key="rec" >
                                            <td>{{ rec.positionId }}</td>
                                            <td>{{  rec.symbol }}</td>
                                            <td>{{  rec.id }}</td>
                                            <td v-if="rec.type">
                                                <p class="m-0" :class="rec.type.includes('BUY')? 'bg-success text-center rounded-pill':rec.type.includes('SELL')? 'bg-danger text-center rounded-pill': 'bg-warning rounded-pill text-center'"> {{ rec.type.split('_')[2] }}</p>
                                            </td>
                                            <td>{{  rec.state.split('_')[2] }}</td>
                                            <td>{{  rec.time.split('T')[0] }}  {{  rec.time.split('T')[1].substring(0,8) }}</td>
                                            <td>{{  rec.doneTime.split('T')[0] }}  {{  rec.doneTime.split('T')[1].substring(0,8) }}</td>
                                            <td>{{ rec.volume }}</td>
                                            <td v-if="rec.stopLoss">{{  rec.stopLoss.toLocaleString('en-GB') }}</td>
                                            <td v-else>-</td>
                                        </tr>
                                        <hr class="my-4 bg-transparent">
                                    </template>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <!--Objectives-->
                        <div v-if="!loading" class="box-trail col my-4" style="height: 350px;">
                            <h3 class="card-title text-white">{{ $t('trial-rulesCheck') }}</h3>
                            <div class="card " style="height:320px;overflow-y:auto; max-width:100%; overflow-x:auto">
                                <table class="table mb-0" style="">
                                    <thead class="bg-primary"  style="position: sticky;top: 0;height:60px" >
                                    <tr >
                                        <th>{{ $t('trial-rulesTab1') }}</th>
                                        <th>{{ $t('trial-rulesTab2') }}</th>
                                        <th>{{ $t('trial-rulesTab3') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody style="height:250px">
                                    <tr class="verticalText">
                                        <td class="text-white"> <h5>{{ $t('trial-rulesTab4') }} <br>-{{ formatAmount(percent5) }}</h5></td>
                                        <td class="text-white"> <h4>
                                            <span>{{ formatAmount(lossAnalytics.daily_loss) }}</span>
                                        </h4></td>
                                        <td v-if="!accountInfo.rule_1"><h5><i class="mdi  mdi-close-circle-outline text-danger"></i>{{ $t('NPass') }}</h5></td>
                                        <td v-else>  <h5><i class="mdi mdi-check-circle-outline text-success"></i> {{ $t('Pass') }}</h5> </td>
                                    </tr>
                                    <tr class="verticalText">
                                        <td class="text-white"> <h5>{{ $t('trial-rulesTab5') }}  <br>-{{ formatAmount(percent10) }}</h5></td>
                                        <td class="text-white">
                                            <h4> {{ formatAmount(accountMetrics.profit) }}</h4>
                                        </td>
                                        <td v-if="!accountInfo.rule_2">
                                            <h5 class="text-white"><i class="mdi mdi-close-circle-outline text-danger"></i> {{ $t('NPass') }}</h5>
                                        </td>
                                        <td v-else>
                                            <h5 class="text-white"><i class="mdi mdi-check-circle-outline text-success"></i> {{ $t('Pass') }}</h5>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <!--Balance/Equity stats-->
                        <div class="box-trail col my-4" v-if="Object.values(accountInfo.worstBestData).every(obj => Object.keys(obj).length > 0)" style="height: 350px;">
                            <h3 class="card-title text-white">Balance/Equity {{ $t('Stats') }}</h3>
                            <div class="card " style="height:320px;overflow-y:auto; max-width:100%; overflow-x:auto">
                                <table class="table mb-0" style="">
                                    <thead class="bg-primary"  style="position: sticky;top: 0; height:60px" >
                                    <tr>
                                        <th>{{ $t('Target') }}</th>
                                        <th>{{ $t('Value') }}</th>
                                        <th>{{ $t('Date') }}</th>
                                        <th>{{ $t('Time') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody style="height:250px">
                                    <tr v-for="(record,key) in accountInfo.worstBestData" :key="record" id="middle-table" class="verticalText">
                                        <td  class="text-white">{{
                                                key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                                            }}</td>
                                        <td  class="text-white"> {{ currency }}{{ formatAmount(record.value) }}</td>
                                        <td  class="text-white">{{ record.time.split('T')[0] }}</td>
                                        <td  class="text-white">{{ record.time.split('T')[1].substring(0, 8) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <!--Profits/Losses Calendar-->
                        <div class="box-trail col my-4" v-if="accountTrades" style="height: 350px;">
                            <h3 class="card-title text-white">Profits/Losses Calendar</h3>
                            <div class="card " style="height:320px;overflow-y:auto; max-width:100%; overflow-x:auto">
                                <table class="table mb-0">
                                    <thead class="bg-primary"  style="position: sticky;top: 0; height:60px" >
                                    <tr>
                                        <th>{{ $t('Day') }}</th>
                                        <th>N. {{ $t('Loss') }}</th>
                                        <th>N. {{ $t('Trades') }}</th>
                                        <th>{{ $t('Profits') }}/{{ $t('Losses') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody style="height:250px">
                                    <tr v-for="(element) in groupedTrades" :key="element" class="verticalText">
                                        <td style="width: 35%">{{ element.close }}</td>
                                        <td >{{ element.totLots.toFixed(2) }}</td>
                                        <td >{{element.trades.length }}</td>
                                        <td :class="element.totProfitsSum.toFixed(2)>0?'text-success':'text-danger'" >{{ element.totProfitsSum.toFixed(2) }}</td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--Lots-->
                        <div class="box-trail row d-flex flex-wrap px-2 mb-4" v-if="lots && Object.keys(lots).some(key => Object.keys(lots[key]).length > 0)">
                            <h3 class="card-title text-white mt-5 pt-3">{{ $t('WeeklyOv') }}</h3>
                            <hr>
                            <template v-for="(categoryData, categoryName) in lots" :key="categoryName" >
                                <div
                                    :class="{'col-12 text-center': countKeysWithValues(lots) === 1, 'col-12 col-lg-6 text-center': countKeysWithValues(lots) > 1}"
                                    class="d-flex flex-column"
                                    v-if="Object.keys(categoryData).length > 0"
                                >
                                    <h3  class="text-capitalize">{{ categoryName }}</h3>
                                    <div  class="card" style="height: 320px; overflow-y: auto;margin-bottom: 10px;">
                                        <table class="table" >
                                            <thead
                                                style="position: sticky;top: 0;height:60px" class="bg-primary" >
                                            <tr>
                                                <th>{{ $t('Week') }}</th>
                                                <th>{{ $t('NTrades') }}</th>
                                                <th>{{ $t('AvLots') }}</th>
                                                <th>{{ $t('AvG') }} %</th>
                                                <th>{{ $t('AvP') }}</th>
                                            </tr>
                                            </thead>
                                            <tbody style="height:230px" >
                                            <tr v-for="(range, dateRange) in categoryData" :key="dateRange" class="verticalText">
                                                <td>{{ dateRange }}</td>
                                                <td>{{ range.nTrades }}</td>
                                                <td>{{ range.averageVolume }}</td>
                                                <td>{{ range.averageGain }}%</td>
                                                <td>{{ range.averageProfit }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </template>

                        </div>
                        <!--Trade History-->
                        <div class="mt-5" v-if="(accountTrades && accountTrades.length > 0) || (openTrades && openTrades.length > 0)">
                            <div class="row p-0">
                                <div class="col-12">
                                    <h3 class="text-start text-white">{{ $t('TradesHist') }}</h3>
                                    <div class="gap-4 mb-2 d-none d-lg-flex justify-content-between">
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('BTrade') }}</p>
                                                <p class="text-dark" v-if="accountMetrics.bestTrade">
                                                    <span class="text-success">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.bestTrade) }}</span>
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('WTrade') }}</p>
                                                <p class="text-dark" v-if="accountMetrics.worstTrade">
                                                    <span class="text-danger">
                                                    {{ currency }}
                                                    {{ formatAmount(accountMetrics.worstTrade) }}</span>
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div class="border border-1"></div>
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('trial-NtotTrades') }}</p>
                                                <p v-if="accountMetrics.trades">{{ accountMetrics.trades }}</p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('trial-TotalNLots') }}</p>
                                                <p v-if="accountMetrics.lots">{{
                                                        formatAmount(accountMetrics.lots)
                                                    }}</p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div class="border border-1"></div>
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('trial-TotalNPips') }}</p>
                                                <p v-if="pipSum">{{
                                                        formatAmount(pipSum)
                                                    }}</p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div class="border border-1"></div>
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('trial-ADG') }}</p>
                                                <p v-if="accountMetrics.dailyGain">
                                                    {{formatAmount(accountMetrics.dailyGain) }} %
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('trial-MG') }}</p>
                                                <p v-if="accountMetrics.monthlyGain">
                                                    {{formatAmount(accountMetrics.monthlyGain) }} %
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                        <div class="border border-1"></div>
                                        <div>
                                            <div class="d-none d-lg-block">
                                                <p class="text-dark">{{ $t('trial-totComm') }}</p>
                                                <p v-if="accountMetrics.commissions">
                                                    {{currency}}
                                                    {{formatAmount(accountMetrics.commissions) }}
                                                </p>
                                                <p v-else>-</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="gap-4 mb-2 d-flex d-lg-none row">
                                        <div class="col-12 displayFlex">
                                            <div class="col-6 ">
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('BTrade') }}</p>
                                                    <p class="text-success" v-if="accountMetrics.bestTrade">
                                                        {{currency }} {{ formatAmount(accountMetrics.bestTrade) }}</p>
                                                    <p v-else>-</p>
                                                </div>
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('WTrade') }}</p>
                                                    <p class="text-danger" v-if="accountMetrics.worstTrade">
                                                        {{ currency }} {{ formatAmount(accountMetrics.worstTrade) }}</p>
                                                    <p v-else>-</p>
                                                </div>
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('trial-NtotTrades') }}</p>
                                                    <p v-if="accountMetrics.trades">{{ accountMetrics.trades }}</p>
                                                    <p v-else>-</p>
                                                </div>
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('trial-TotalNLots') }}</p>
                                                    <p v-if="accountMetrics.lots">
                                                        {{ formatAmount(accountMetrics.lots) }}
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                            <div class="border border-1 me-2"></div>
                                            <div class="col-5">
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('trial-TotalNPips') }}</p>
                                                    <p v-if="pipSum">
                                                        {{ formatAmount(pipSum) }}
                                                    </p>
                                                    <p v-else>-</p>
                                                </div>
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('trial-ADG') }}</p>
                                                    <p v-if="accountMetrics.dailyGain">
                                                        {{ formatAmount(accountMetrics.dailyGain) }} %</p>
                                                    <p v-else>-</p>
                                                </div>
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('trial-MG') }}</p>
                                                    <p v-if="accountMetrics.monthlyGain"> {{ formatAmount(accountMetrics.monthlyGain) }}%</p>
                                                    <p v-else>-</p>
                                                </div>
                                                <div class="">
                                                    <p class="text-dark p-0 m-0">{{ $t('trial-totComm') }}</p>
                                                    <p v-if="accountMetrics.commissions">
                                                        {{currency}}
                                                        {{ formatAmount(accountMetrics.commissions) }}</p>
                                                    <p v-else>-</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="card  p-3">
                                        <div class="card-body p-0 p-lg-2">

                                            <ul class="nav nav-tabs">
                                                <li class="nav-item">
                                                    <a @click="showclose()" id="showclose"
                                                       class="nav-link active fw-bold" style="background-color: transparent!important;" aria-current="page">{{ $t('Closed') }}</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a @click="showopen()" style="background-color: transparent!important;" id="showopen" class="nav-link fw-bold">{{ $t('Opened') }}</a>
                                                </li>
                                            </ul>
                                            <div class="table card-body p-0 "
                                                 style="max-height: 700px; overflow-y: auto;overflow-x: auto;">
                                                <table v-show="closetrade" class="table">
                                                    <thead class="bg-primary"
                                                           style="position: sticky;top: 0">
                                                    <tr>
                                                        <th>{{ $t('table-ticket') }}</th>
                                                        <th>{{ $t('table-openAt') }}</th>
                                                        <th>{{ $t('Type') }}</th>
                                                        <th>{{ $t('Volume') }}</th>
                                                        <th>{{ $t('table-openPrice') }}</th>
                                                        <th>{{ $t('table-closePrice') }}</th>
                                                        <th>{{ $t('table-closeAt') }}</th>
                                                        <th>{{ $t('Profit') }}</th>
                                                        <th>{{ $t('Pips') }}</th>
                                                        <th>{{ $t('table-duration') }}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody v-if="accountTrades">
                                                    <tr v-for="trade in accountTrades" :key="trade._id">
                                                        <td v-if="trade.symbol">{{ trade.symbol }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.openTime">{{ formatData(trade.openTime) }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.type"><p class="m-0 text-black"
                                                                                 :class="trade.type.includes('BUY')? 'bg-success text-center rounded-pill':trade.type.includes('SELL')? 'bg-danger text-center rounded-pill': 'text-warning text-center'">
                                                            {{ trade.type }}</p></td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.volume">{{ trade.volume.toFixed(2) }} lots
                                                        </td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.openPrice">{{ trade.openPrice }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.closePrice">{{ trade.closePrice }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.closeTime">{{ formatData(trade.closeTime) }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.profit" :class="trade.profit>=0 && !trade.type.includes('BALANCE')?'text-success': trade.profit<0?'text-danger':''">{{ trade.profit.toFixed(2) }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.pips">{{ trade.pips.toFixed(2) }}</td>
                                                        <td v-else>-</td>
                                                        <td>
                                                            {{ trade.durationInMinutes }} minutes
                                                        </td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <table v-show="opentrade" class="table">
                                                    <thead class="bg-primary"
                                                           style="position: sticky;top: 0">
                                                    <tr>
                                                        <th>{{ $t('table-ticket') }}</th>
                                                        <th>{{ $t('table-openAt') }}</th>
                                                        <th>{{ $t('Type') }}</th>
                                                        <th>{{ $t('Volume') }}</th>
                                                        <th>{{ $t('table-openPrice') }}</th>
                                                        <th>{{ $t('Status') }}</th>
                                                        <th>{{ $t('Profit') }}</th>
                                                        <th>{{ $t('table-duration') }}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody v-if="openTrades">
                                                    <tr v-for="trade in openTrades" :key="trade._id">
                                                        <td v-if="trade.symbol">{{ trade.symbol }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.openTime">{{ formatData(trade.openTime) }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.type"><p class="m-0 text-black"
                                                                                 :class="trade.type.includes('BUY')? 'bg-success text-center rounded-pill':trade.type.includes('SELL')? 'bg-danger text-center rounded-pill': 'text-warning text-center'">
                                                            {{ trade.type }}</p></td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.volume">{{ trade.volume.toFixed(2) }} lots
                                                        </td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.openPrice">{{ trade.openPrice }}</td>
                                                        <td v-else>-</td>
                                                        <td v-if="trade.status">{{ trade.status }}</td>
                                                        <td v-else>-</td>

                                                        <td v-if="trade.profit" :class="trade.profit>=0 && !trade.type.includes('BALANCE')?'text-success': trade.profit<0?'text-danger':''">{{ trade.profit.toFixed(2) }}</td>
                                                        <td v-else>-</td>
                                                        <td :class="trade.durationInMinutes<2?'text-danger':''">
                                                            {{ trade.durationInMinutes }} minutes
                                                        </td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Profits Chart-->
                        <div v-if="accountTrades && accountTrades.length > 0" class="row p-0 mt-5 d-flex">
                            <div class="col-12">
                                <TradesChartDay :trades="accountTrades" :metriche="accountMetrics"
                                                :currency="currency"/>
                            </div>
                        </div>
                        <!-- /dopo -->
                    </div>
                    <!--- End Content --->

                </div>
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

        <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script setup>
import {getCurrentInstance, ref, onBeforeMount, onMounted, inject} from 'vue';
import axios from 'axios';
import { useCookies } from 'vue3-cookies'

// Components
import Spinner from "./Spinner";
import Navbar from "./statics/navbar";
import Sidebar from "./Sidebar";
import TotalTradesBarChart from "./TotalTradesBarChart.vue";
import TotalProfitBarChart from "./TotalProfitBarChart.vue";
import ProfitsPieChart from "./ProfitsPieChart.vue";
import ChartLine from "./ChartLine.vue";
import TradesChartDay from "./TradesChartDay.vue";
import RankModal from "./RankModal";
import bannerViolation from "./statics/bannerViolation.vue"
// Route
import { useRoute, useRouter } from "vue-router";
import moment from "moment/moment";
const router = useRouter();
const route = useRoute();
const { cookies } = useCookies();

//copy info account
const copyToClipboard= async(value)=>{
    var info = document.getElementById(value);
    var range = document.createRange();
    range.selectNode(info);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

}


// Reset TVS
let loadingPay= ref(false)
let canReset= ref(false)
let tvsReset=ref([]);
const getTvsTot= async() =>{
    if(openTrades.value.length<1){
        axios.get(`${baseUrl}/ecommerce/tvr?account_id=${accountInfo.value.username}`).then((response)=>{
        if(!response.data ){
            canReset.value=true
        }else if(response.data.status!='completed'){
            canReset.value=true
        }
    })
    }else{
        return false
    }
}
const postTvsTot= async() =>{
    console.log(canReset.value)
    let idAccReset=''
    let curr= accountInfo.value.currency
    let type= (accountInfo.value.type_account).split('%')[0]
    let size=''
    if(accountInfo.value.order && accountInfo.value.order.items){
        idAccReset=accountInfo.value.order.items[0].itemId
    }
    else{
        switch(accountInfo.value.value_account){
            case '200000':
                size='200K'
            break;
            case '100000':
                size='100K'
            break;
            case '50000':
                size='50K'
            break;
            case '25000':
                size='25K'
            break;
            case '10000':
                size='10K'
            break;
            case '5000':
                size='5K'
            break;
        }
        idAccReset= `${size}${curr}${type}`
    }

    let params={
        "customer_id": accountInfo.value.customer_id,
        "item_id": idAccReset
    }
    if(canReset.value==true){
        axios.post(`${baseUrl}/ecommerce/tvr?account_id=${accountInfo.value.username}`,params).then((response)=>{
        console.log('post',response.data)
        tvsReset.value=response.data
    })
    }else{
        return false
    }

}
const payWith=(value)=>{
    loadingPay.value=true
    if(canReset.value==true){
            let payMethods={
            "customer_id": accountInfo.value.customer_id,
            "account_id": accountInfo.value.username
        }
        if(value=='coinbase'){
            axios.patch(`${baseUrl}/ecommerce/tvr/pay/coinbase`,payMethods).then((response)=>{
                window.open(response.data.payment_data.url, "_blank");
                loadingPay.value=false
            })
        }else if(value=='trustPayment'){
            axios.patch(`${baseUrl}/ecommerce/tvr/pay/trustpayment`,payMethods).then((response)=>{
                let form = document.getElementById('form')
                form.submit()
                loadingPay.value=false
            })

        }
    }else{
        return false
    }
}
// Misc
const baseUrl = getCurrentInstance().appContext.config.globalProperties.$baseUrl;
const loading = ref(false);
const stopnavbar = ref(false);
const showRank = ref(false);
const credentials = ref(true);

// Account
const accountId = ref(null)
const currency = ref(null);
const accountInfo = ref(null);
const accountMetrics = ref(null);
const accountChartTracking = ref(null);
const accountTrades = ref(null);

// Trades
const openTrades = ref(null);
const groupedTrades = ref(null);
const dayTrades = ref([]);
const pipSum = ref(0);

// Lots
const lots = ref(null);

// Tabs
const closetrade = ref(true);
const opentrade = ref(false);
// Countdown
const countdown = ref({
    hours: 0,
    minutes: 0
});


// Stats & Chart
const rr = ref(null);
const consistency = ref(0);
const selectedCurrency = ref('all');
const lossAnalytics = ref({
    daily_loss: 0,
    total_loss: 0,
    account_loss: 0,
    account_progress_bar: 0,
    daily_progress_bar: 0
});

// Rules
const percent5 = ref(0);
const percent10 = ref(0);

// Refresh

const  refreshData = () =>{
    localStorage.clear(accountInfo.value.userID)
    location.reload(true);
}

// Popups
const popupShowed = ref(0);
const togglePopup = popupId => {
    popupShowed.value = popupShowed.value === popupId
        ? 0
        : popupId;

    stopnavbar.value = popupShowed.value !== 0;
}

// Refs
const navbarRef = ref(null);

const watchRank = value => {
    showRank.value = value;
};
const closeRank = () => {
    showRank.value = false;
};
const app = getCurrentInstance().appContext.app;
const formatData=(value)=>{
    let date = new Date(value);
    let local = app.config.globalProperties.$languages[cookies.get('client').language]||app.config.globalProperties.$languages['en'];
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var formatter = new Intl.DateTimeFormat(local, options);
    return formatter.format(date);
};
const formatDay= (value)=>{
    let date = new Date(value);
    let local = app.config.globalProperties.$languages[cookies.get('client').language]||app.config.globalProperties.$languages['en'];
    return date.toLocaleDateString(local, { weekday: 'long' });
};
const getAccountInfo = async () => {
    try {
        loading.value = true;

        const { data } = await axios.get(`${baseUrl}/accounts/getAccountInfo/${accountId.value}`);
        accountInfo.value = data;
        accountInfo.value.created_at = new Date(accountInfo.value.created_at);
        accountInfo.value.nextPayout = data.nextReview;
        navbarRef.value.setClient();

        const date = new Date();
        date.setDate(date.getDate() + accountInfo.value.nextPayout);
        accountInfo.value.day = date.toLocaleDateString('en-US', { weekday: 'long' });
        accountInfo.value.datePayout = date.toDateString();

        percent5.value = data.initial_balance * 0.05;
        percent10.value = data.initial_balance * 0.1;

        lots.value = (data.lotsWeekly && data.lotsWeekly.length > 0) ? data.lotsWeekly[0] : null;

        await calculateMetrics();
        accountInfo.value.growth = ((accountMetrics.value.balance - accountInfo.value.initial_balance) / accountInfo.value.initial_balance) * 100;
        await getPayouts();
        await getUserTrackingForChart();
        await getTrades();

        lossAnalytics.value.daily_progress_bar = +(lossAnalytics.value.daily_loss / percent5.value).toFixed(2);
        lossAnalytics.value.account_progress_bar = +(lossAnalytics.value.account_loss / percent10.value).toFixed(2);
        loading.value = false;
    } catch (e) {
        console.error(e);
    }
};
const countKeysWithValues= (obj)=> {
    console.log(Object.values(obj).filter(value =>
        typeof value === 'object' && Object.keys(value).length > 0
    ).length)
    return Object.values(obj).filter(value =>
        typeof value === 'object' && Object.keys(value).length > 0
    ).length;
};
const getUserTrackingForChart = async () => {
    try {
        loading.value = true;

        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const start = startDate.toISOString().split('T')[0];
        const end = endDate.toISOString().split('T')[0];
        console.log(end)
        const { data } = await axios.get(`${baseUrl}/getUserTrackingForChart?user_id=${accountInfo.value.userID}&first_date=${start}&last_date=${end}`);

        let indexes = [];
        if (data.label.length) {
            let startTime = new Date();
            startTime.setHours(0, 0, 0);

            let endTime = new Date();
            endTime.setDate(endTime.getDate() + 1);
            endTime.setHours(23, 59, 59);

            indexes = data.label
                .filter(label => {
                    return new Date(label).getTime() >= startTime.getTime()
                        && new Date(label).getTime() <= endTime.getTime();
                })
                .map(label => data.label.indexOf(label));
            if (indexes.length === 0) { // Fallback to last available day
                const lastAvailableDay = data.label[data.label.length - 1];

                startTime = new Date(lastAvailableDay);
                startTime.setHours(0, 0, 0);

                endTime = new Date(lastAvailableDay);
                endTime.setHours(23, 59, 59);

                console.debug('getUserTrackingForChart @ Fallback to last available day', startTime, endTime);
                indexes = data.label
                    .filter(label => {
                        return new Date(label).getTime() >= startTime.getTime()
                            && new Date(label).getTime() <= endTime.getTime();
                    })
                    .map(label => data.label.indexOf(label));
            }
        }

        const balances = data.balance.filter((balance, index) => indexes.includes(index));
        const dailyMaxBalance = balances.length > 0 ? Math.max(...balances) : 0;
        const lastEquity = data.equity[data.equity.length - 1] || 0;

        lossAnalytics.value.daily_loss = ((dailyMaxBalance - lastEquity) * -1).toFixed(2);

        const totalLoss = (accountInfo.value.ref_balance - lastEquity) * 1;
        lossAnalytics.value.total_loss = +(isFinite(totalLoss) ? totalLoss : 0).toFixed(0);

        const accountLoss = (percent10.value + (lastEquity - accountInfo.value.ref_balance));
        lossAnalytics.value.account_loss = +(isFinite(accountLoss) ? accountLoss : 0).toFixed(0);

        loading.value = false;
        accountChartTracking.value = data;
        console.debug('ChartTracking', JSON.parse(JSON.stringify(accountChartTracking.value)));
    } catch (e) {
        console.error(e);
    }
};

const getUserMetrics = async () => {
    const { data } = await axios.get(`${baseUrl}/getUserMetrics?user_id=${accountInfo.value.userID}`);
    data.expire = Date.now() + 5 * 60 * 1000;

    const cachedMetrics = JSON.stringify(data);

    localStorage.setItem(accountInfo.value.userID, cachedMetrics);
    return cachedMetrics;
}

const calculateMetrics = async () => {
    let cachedMetrics = localStorage.getItem(accountInfo.value.userID);
    if (!cachedMetrics) {
        cachedMetrics = await getUserMetrics();
    }

    let metrics = JSON.parse(cachedMetrics);
    const timestamp = Date.now();

    if (timestamp < metrics.expire) {
        cachedMetrics = await getUserMetrics();
        metrics = JSON.parse(cachedMetrics);
    }

    accountMetrics.value = metrics;

    rr.value = Math.abs(metrics.averageWin / metrics.averageLoss).toFixed(2);

    if (metrics.skills) {
        accountMetrics.value.dailyAvailable = 100 - (metrics.skills.daily_available_loss);
        accountMetrics.value.totalAvailable = 100 - (metrics.skills.total_available_loss);

        accountMetrics.value.dailyAvailable = accountMetrics.value.dailyAvailable.value >= 100 ? 100 : accountMetrics.value.dailyAvailable;
        accountMetrics.value.dailyAvailable = accountMetrics.value.dailyAvailable.value <= 0 ? 0 : accountMetrics.value.dailyAvailable;

        accountMetrics.value.totalAvailable = accountMetrics.value.totalAvailable.value >= 100 ? 100 : accountMetrics.value.totalAvailable;
        accountMetrics.value.totalAvailable = accountMetrics.value.totalAvailable.value <= 0 ? 0 : accountMetrics.value.totalAvailable;
    }

    const profits = (metrics.dailyGrowth ?? []).map(growth => growth.profit);
    const absoluteValues = profits.map(profit => Math.abs(profit));
    const sum = profits.reduce((a, b) => a + b, 0);
    const maxScore = Math.max(...absoluteValues);
    consistency.value = ((maxScore / sum) * 100).toFixed(2);

    console.debug('Metrics', JSON.parse(JSON.stringify(accountMetrics.value)));
};

const getPayouts = async () => {
    try {
        loading.value = true;

        const { data } = await axios.post(`${baseUrl}/accounts/getPayouts`, JSON.stringify({ usernameId: accountId }));
        accountInfo.value.totalPayouts = data.total;

        loading.value = false;
    } catch (e) {
        console.error(e);
    }
};
const requestPay = ref(true)
const requestError = ref(false)
const askPayout = async () => {
    try {
        requestPay.value= false
        const { data } = await axios.post(`${baseUrl}/request-payouts`,{
            "account_id": accountId.value
        });
        console.log(data)
        await checkPayoutRequest()
    } catch (error) {
        console.log(error)
        requestError.value=true
    }
};
const requestPayout = ref(null);
const checkPayoutRequest = async () => {
    try {
        const {data} = await axios.get(`${baseUrl}/request-payouts/${accountId.value}`);
        requestPayout.value=data[0]
        requestPayout.value.created_at = moment(requestPayout.value.created_at).format('YYYY-MM-DD HH:mm:ss');
        console.log('request', requestPayout.value)
        requestPay.value=true
    } catch (error) {

    }
}
const getTrades = async () => {
    try {
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = nextMonth.toLocaleDateString('en-US', options).split('/').join('-');
        console.log(date)

        const { data } = await axios.post(`${baseUrl}/user/getTrades2?user_id=${accountInfo.value.userID}&start_date=01-01-2020&end_date=${date}`);
        accountTrades.value = data;
        if(data.length > 0) {
            let groupTrades = {};
            data.forEach((element) => {
                element.openTime = moment(element.openTime).format('YYYY-MM-DD HH:mm:ss');
                element.closeTime = moment(element.closeTime).format('YYYY-MM-DD HH:mm:ss');
                element.close=moment(element.closeTime).format('YYYY-MM-DD');
                element.type = element.type.split('_')[2]
                dayTrades.value.push(element.closeTime)
                dayTrades.value = [ ...new Set(dayTrades.value) ];

                if (element.pips != null) {
                    let pipsAsNumber = parseFloat(element.pips);
                    if (!isNaN(pipsAsNumber)) {
                        pipSum.value += pipsAsNumber;
                    }
                }

                if(!element.type.includes('BALANCE')) {
                    if (groupTrades[element.close]) {
                        groupTrades[element.close].push(element);
                    } else {
                        groupTrades[element.close] = [element];
                    }
                }
            });

            groupedTrades.value = Object.keys(groupTrades).map((close) => {
                return {
                    close: close,
                    trades: groupTrades[close]
                }
            });

            groupedTrades.value.forEach(giorno => {
                giorno.totProfits = []
                giorno.totLots = 0
                giorno.trades.forEach((element) => {
                    giorno.totLots=giorno.totLots+element.volume
                    giorno.totProfits.push(element.profit)
                })
                giorno.totProfitsSum = giorno.totProfits.reduce((acc, valore) => acc + valore, 0);
            });
        }
    } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 500) {
            document.getElementById('modalTrades').click();
            localStorage.removeItem(accountId)
            startCountdown()
        }}

    try {
        const response = await axios.post(`${baseUrl}/user/getOpenTrades?user_id=${accountInfo.value.userID}`);
        openTrades.value = response.data.map(trade => {
            trade.openTime = moment(trade.openTime).format('YYYY-MM-DD HH:mm:ss');
            trade.type = trade.type.split('_')[2]

            switch (trade.success) {
                case 'lost':
                    trade.status = 'loosing';
                    break;
                case 'won':
                    trade.status = 'winning';
                    break;
                default:
                    break;
            }

            return trade;
        });

        console.debug('Trades', JSON.parse(JSON.stringify(accountTrades.value)));
    } catch(e) {
        console.error(e)
    }
};

const momentTZ = require('moment-timezone');
const createCountdown = () => {
    const now = momentTZ(); //moment timezone
    const eet = now.tz('Europe/Bucharest'); // EET timezone
    const isDaylightSavingTime = eet.isDST(); // Verifica se è in orario legale
    const gmtOffset = isDaylightSavingTime ? 3 : 2; //scelgo UTC +2 o +3
    const nowLocal = now.clone().utcOffset(gmtOffset * 60);
    const midnight = eet.clone().endOf('day');
    // Aggiungi 1 giorno a mezzanotte se l'ora locale >= 24
    if (nowLocal.isAfter(midnight)) {
        midnight.add(1, 'day');
    }
    const diff = midnight.diff(nowLocal);
    const duration = momentTZ.duration(diff);
    countdown.value.hours = duration.hours();
    countdown.value.minutes = duration.minutes();
    console.log('Count', countdown);
    return countdown;
};
const formatCurrency= (value)=>{
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    });
    return formatter.format(value);
}
const formatAmount = amount => {
    return (+amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const showclose = () => {
    closetrade.value = true
    opentrade.value = false
    let element = document.getElementById('showclose')
    element.classList.add('active')
    let element1 = document.getElementById('showopen')
    element1.classList.remove('active')
};
const showopen = () => {
    closetrade.value = false
    opentrade.value = true
    let element = document.getElementById('showopen')
    element.classList.add('active')
    let element1 = document.getElementById('showclose')
    element1.classList.remove('active')
}


// Hooks
onBeforeMount(async () => {
    const { params, query } = route;
    if (!params.accountId || !query.currency) {
        await router.replace('/home');
        return;
    }

    accountId.value = params.accountId;
    currency.value = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: query.currency,
    }).formatToParts(1)[0].value;
});
onMounted(async () => {
    await getAccountInfo();
    await checkPayoutRequest()
    await createCountdown();
    await getTvsTot()
    console.debug('Account', JSON.parse(JSON.stringify(accountInfo.value)));
});
</script>

<style scoped>
.modal_dialog{
    min-width:800px!important;
    margin:auto
}
.modal-dialog {
    max-width: 800px!important;
}
.modal-content{
    background-color: #0d0a1f!important;
}

.allineamento{
    vertical-align: middle;
}
.displayFlex{
    display: flex !important;
}
.progress-bar {
    height: 100px;
    border-radius: 50%;
}
.progress-padding{
    padding: 20px;
}
.countdown {
    width: 200px;
    height: 200px;
    border: 10px solid gray;
    border-radius: 50%;
    position: relative;
}


.eyered {
    text-decoration: line-through;
    text-decoration-color: red
}

.tooltip {
    background-color: black;
    color: red;
}

.box-trail > * {
    border-radius: 10px;
    display: flex;
}

.stats-charts >div> * {
    padding: 20px;
}

.info {
    cursor: pointer;
}

.card {
    border-radius: 10px;
    overflow: hidden;
}

.rulesBox {
    position: absolute;
    width: 80%;
    right: 10px;
    padding: 15px;
    z-index: 999999;
    text-align: end;
    box-shadow: 4px 4px 25px black;
}

.carousel-control-prev {
    width: 2%;
}

.carousel-control-next {
    width: 2%;
}

h5.font-medium {
    font-size: 1.3rem;
}
.nav-link {
    color: white !important;
}

.nav-link.active {
    color: #fb9678 !important;
    background-color: #454d5d !important;
}

.bgSfumato{
    background-color: #c24f3561;
}

.scroll-sidebar {
    background-color: rgba(255, 255, 255, 0.1);
}
.blurred {
    filter: blur(10px);
}
.data-account {
    row-gap: 10px;
}
#info-tab .row-icon {
    width: 40px;
    margin-right: 10px;
}
#info-tab .row-icon i {
    margin-right: 15px;
    font-size: 30px;
}
thead{
    text-wrap: nowrap;
}

td{
    padding:0.75rem !important;
}
.verticalText{
    vertical-align: middle;
}
</style>
