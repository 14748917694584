<template>
    <div class="trustpilot-widget px-3 py-0" data-locale="en-GB" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="639bc6aca1af872eeeaab686" data-style-height="370px" data-style-width="100%" data-theme="dark" data-stars="5" data-review-languages="en">
        <a href="https://uk.trustpilot.com/review/ofpfunding.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
</template>

<script>
export default {
    name:'Trustpilot',
    mounted() {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.onload = () => {
      // Callback function that is called when the widget script is loaded
      window.Trustpilot && window.Trustpilot.loadFromElement(document.getElementsByClassName('trustpilot-widget'), true);
    };
    document.head.appendChild(script);
  }
}
</script>

<style scoped>
.trustpilot-widget{
    margin: 3px;
    padding: 10px 0;
}
</style>
