<template>
    <Profile />
</template>

<script>

import Profile from '../components/Profile';
export default {
    name: "Home",
    data() {
        return {
            //
        }
    },
    components: {
    Profile
},

}
</script>
