<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" >
            <Navbar :stop="stopnav" />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" >
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->

        <div class="page-wrapper mb-3" :class="showRank?'opacity-25':''" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!--- Start Content --->
                <div class="row mt-5 pb-5">
                    <!-- Column -->
                    <div class="col-lg-12 col-md-12">
                        <div class="">
                            <div class="">
                                <h2 class="card-title text-white fw-bold ">{{ $t('orders-title') }}</h2>
                                <h5 v-if="orders.length==0 && orders_ofp.length==0">{{ $t('Noorders-title') }}</h5>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-borderless" v-if="orders.length>0 || orders_ofp.length>0">
                                    <thead class=" border-bottom ">
                                        <tr class="text-white">
                                            <th>{{ $t('ID')}}</th>
                                            <th class="d-none d-xl-table-cell"  @click="toggleSortOrderAll()">
                                                <span class="me-3">{{ $t('Date') }}</span>
                                                <span style="cursor: pointer;" v-if="sortOrderall === 'asc'">↓</span>
                                                <span style="cursor: pointer;" v-else>↑</span>
                                            </th>
                                            <th class="d-none d-lg-table-cell">{{ $t('Product') }}</th>

                                            <th>{{ $t('Status') }}</th>
                                            <th class="d-none d-lg-table-cell">{{ $t('Total') }}</th>
                                            <th>{{ $t('Info') }}</th>

                                        </tr>
                                    </thead>
                                    <tbody v-for="order in filtredOrdOfp" :key="order.id" class="border-bottom" >
                                            <tr class="text-capitalize bodyOrders">
                                                <td class="d-flex align-items-center" style="display: flex!important;">
                                                <div class="me-1" v-if="order.payments && order.payments.length>0" @click="toggleExpandedRow(order)" style="cursor: pointer;">
                                                    <span v-if="order!=expandedRow">&#9658;</span>
                                                    <span v-else>&#9660;</span>
                                                </div>
                                                #{{ order.id }}
                                            </td>
                                            <td class="d-none d-xl-table-cell">{{formatData(order.date)}}</td>
                                            <td v-if="order.items && order.items[0]" class="d-none d-lg-table-cell">{{ order.items[0].itemId }}</td>
                                            <td v-else-if="order.line_items" class="d-none d-lg-table-cell">{{ order.line_items[0].name }}</td>

                                            <td><p class=" badge" style="font-size: 0.90rem;" :class="order.status=='completed'|| order.status=='paid'?'bg-success':order.status=='cancelled'|| order.status=='unpaid'?'bg-danger':order.status=='on-hold'?'bg-warning':order.status=='pending'?'bg-info':'' ">{{ order.status }}</p></td>
                                            <td class="d-none d-lg-table-cell">{{formatCurrency("USD" , order.total)}}</td>
                                            <td @click="popUp(order.id)" role="button"><i class="ti-info-alt fs-4"></i></td>
                                            </tr>
                                            <div v-if="clicked && popOrder.id==order.id" class="d-flex flex-column text-black " style="display: flex!important;" >
                                                    <div class="d-flex flex-column text-white">
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Product') }}</p>
                                                            <p v-if="popOrder.line_items && popOrder.line_items[0].name">{{ popOrder.line_items[0].name }}</p>
                                                             <p v-else-if="popOrder.items && popOrder.items[0].itemId">{{ popOrder.items[0].itemId }}</p>
                                                            <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p v-if="popOrder.date" class="fw-bold">{{ $t('Date') }}</p>
                                                        <p v-if="popOrder.date">{{ formatData(popOrder.date) }}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Status') }}</p>
                                                        <p v-if="popOrder.status">{{ popOrder.status }}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Currency') }}</p>
                                                        <p v-if="popOrder.currency">{{ popOrder.currency }}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Quantity') }}</p>
                                                        <p v-if="popOrder.line_items && popOrder.line_items[0].quantity" >{{ popOrder.line_items[0].quantity }}</p>
                                                        <p v-else-if="popOrder.items && popOrder.items[0]" >{{ popOrder.items[0].quantity }}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Price') }}</p>
                                                        <p v-if="popOrder.line_items && popOrder.line_items[0].subtotal" >{{ popOrder.currency_symbol +popOrder.line_items[0].subtotal  }}</p>
                                                        <p v-else-if="popOrder.total && !popOrder.line_items" >{{popOrder.currency_symbol}} {{  popOrder.total }}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Discount') }}</p>
                                                        <p v-if="popOrder.discount_total" >{{ popOrder.currency_symbol +popOrder.discount_total  }}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('PayMeth') }}</p>
                                                        <p v-if="popOrder.payment_method" >{{ popOrder.payment_method }} </p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p class="fw-bold">{{ $t('Total') }}</p>
                                                        <p v-if="popOrder.total" > {{formatCurrency("USD" , popOrder.total)}}</p>
                                                        <p v-else >---</p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p v-if="popOrder.platform" class="fw-bold">{{ $t('Platform') }}</p>
                                                        <p v-if="popOrder.platform" >{{ popOrder.platform }} </p>
                                                        </div>
                                                        <div class="d-flex gap-2">
                                                            <p v-if="popOrder.birthday" class="fw-bold">{{ $t('Bday') }}</p>
                                                        <p v-if="popOrder.birthday" >{{ popOrder.birthday }} </p>
                                                        </div>
                                                       <div class="d-flex gap-2">
                                                        <p class="fw-bold">{{ $t('BillAddress') }} </p>
                                                        <div class="d-flex gap-2" v-if="popOrder.billing && popOrder.billing.address_1" ><p>{{ popOrder.billing.address_1 }}</p>
                                                            <p>{{ popOrder.billing.address_2  }}</p>
                                                            <p>{{ popOrder.billing.city +" "+ popOrder.billing.country  }}</p>
                                                            <p>{{ popOrder.billing.postcode }}</p></div>
                                                            <div class="d-flex gap-2" v-else-if="popOrder.billing_details && popOrder.billing_details.address" ><p>{{ popOrder.billing_details.address }}</p>
                                                            <p>{{ popOrder.billing_details.city +" "+ popOrder.billing_details.country  }}</p>
                                                            <p>{{ popOrder.billing_details.zip }}</p></div>
                                                        <div v-else >---</div>
                                                       </div>
                                                        <div class="d-flex gap-2">
                                                            <p v-if="popOrder.billing && popOrder.billing.email" >{{ $t('Email') }} </p>
                                                        <p v-if="popOrder.billing && popOrder.billing.email" >{{ popOrder.billing.email }} </p>
                                                        </div>
                                                       </div>
                                            </div>
                                            <tr class="text-capitalize bodyOrders">
                                                <td class="w-100 " colspan="6">
                                                    <table v-if="expandedRow==order" class="table table-borderless" style="overflow: auto;">
                                                        <thead>
                                                            <tr class="text-white">
                                                                <th>{{ $t('PayId') }}</th>
                                                                <th>{{ $t('Type') }}</th>
                                                                <th >{{ $t('Url') }}</th>
                                                                <th>{{ $t('Status') }}</th>
                                                                <th class="d-none d-xl-table-cell">{{ $t('Reason') }}</th>
                                                                <th class="d-flex gap-1"  @click="toggleSortOrder()">
                                                                    <span>{{ $t('Data') }}</span>
                                                                    <span style="cursor: pointer;" v-if="sortOrder === 'asc'">↓</span>
                                                                    <span style="cursor: pointer;" v-else>↑</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="order.payments.length>0">
                                                            <tr v-for="pay in order.payments" :key="pay.id">
                                                                <td>{{ pay.id }}</td>
                                                                <td>{{ pay.payment_type }}</td>
                                                                <td >
                                                                    <a v-if="pay.status!='paid' && pay.payment_type!='trustpayment'" target="blank" class="text-success" :href='pay.payment_url'>{{ $t('PayNow') }}</a>
                                                                    <a v-else-if="pay.payment_type=='trustpayment' || pay.status=='paid'"  class="text-info">-</a>
                                                                </td>
                                                                <td :class="pay.status=='unpaid'?'text-danger': pay.status=='Pending'?'text-primary':pay.status=='paid'?'text-success':'' ">{{ pay.status }}</td>
                                                                <td v-if="pay.status!='paid'" class="d-none d-xl-table-cell">{{ pay.reason }}</td>
                                                                <td v-else  class="d-none d-xl-table-cell"> - </td>
                                                                <td>{{ pay.createdAt }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <!-- Column -->
                </div>
                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import bannerKyc from "./statics/bannerKyc";
import RankModal from "./RankModal";
export default {
    name: "Base",
    components: {Footer, Navbar, Sidebar,bannerKyc,RankModal},
    data(){
        return{
            orders:[],
            orders_ofp:[],
            popOrder:[],
            clicked:false,
            stopnav:false,
            expandedRow:null,
            sortOrder:'asc',
            sortOrderall:'asc',
            showRank:false,
            o:[]
        }
    },
    methods:{
        formatData(value){
            let date = new Date(value)
            let local = this.$languages[this.$cookies.get('client').language]||['en']
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var formatter = new Intl.DateTimeFormat(local, options);
            return formatter.format(date);
        },
        closeRank(){
            this.showRank=false
        },
        watchRank(value){
            console.log(value)
            this.showRank=value
        },
        toggleExpandedRow(data) {
      if (this.expandedRow === data) {
        this.expandedRow = null;
      } else {
        this.expandedRow = data;
      }
        },
        toggleSortOrder() {
            this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        },
        toggleSortOrderAll(){
            this.sortOrderall = this.sortOrderall === 'asc' ? 'desc' : 'asc';
        },
        popUp(event){
        this.clicked=!this.clicked
        this.o.forEach((order=>{
                if(order.id==event &&  order.meta_data){
                    order.meta_data.forEach((data)=>{
                            if(data.key=="additional_platform"){
                                order.platform=data.value
                            }
                            if(data.key=="billing_birthday"){
                                let birth= new Date(data.value).toDateString()
                                order.birthday=birth
                            }
                        })
                    this.popOrder=order
                }else if(order.id==event){
                    this.popOrder=order
                }
            }))

        if(this.clicked){
            this.stopnav=true
        }else{
            this.stopnav=false
        }
        },
        getData(){
            axios.get(`${this.$baseUrl}/orders/getAllCustomerOrders?email=${this.$cookies.get('emailAccount')}`).then((response)=>{
                console.log(response.data.orders)
                response.data.orders.forEach(element => {
                    let date=new Date(element.date_created).toDateString()
                    element.date=date
                    this.o.push(element)
                });
                response.data.orders_ofp.forEach(element => {
                    let date=new Date(element.createdAt).toDateString()
                    element.date=date
                    if(element.payments.length>0){
                        element.payments.forEach(el => {
                            let dataOraMinuti = new Date(el.createdAt).toISOString().slice(0, 16).replace("T", " ");
                            el.createdAt=dataOraMinuti
                        });
                    }
                    this.o.push(element)
                });
                this.orders=response.data.orders
                this.orders_ofp= response.data.orders_ofp
                console.log(this.o)
            })
        },
        formatCurrency(curr,value){
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: curr,
            minimumFractionDigits: 2,
            });
            return formatter.format(value);
        },
    },
    computed:{
        filtredOrdOfp(){
            this.o.forEach((el)=>{
                if(el.createdAt){
                    el.dataOrdine= el.createdAt
                }else if (el.date_created){
                    el.dataOrdine= el.date_created
                }
            })
            this.o.sort((c,d)=>{
                    const dateC = new Date(c.dataOrdine)
                    const dateD = new Date(d.dataOrdine)
                    if(this.sortOrderall === 'desc'){
                        return dateC - dateD;
                    }else{
                        return dateD - dateC;
                    }
                })
            this.o.forEach(element => {
                  if(element.payments && element.payments.length>0){
                    element.payments.sort((a, b) => {
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);
                            if (this.sortOrder === 'desc') {
                                return dateA - dateB;
                            } else {
                                return dateB - dateA;
                            }
                        });
                  }
                });
                return this.o
        },
    },
    mounted(){
        this.getData()
    },
    beforeMount(){
        if(!this.$cookies.get('emailAccount') ){
            this.$router.push({ path: '/',})
        }
    }
}
</script>

<style scoped>
.op5{
    opacity: 5%;
}
tbody>tr{
    vertical-align: middle;
}
tbody > tr > td > p { margin-top:auto;margin-bottom:auto; }
.card{
    border-radius: 10px;
    overflow: hidden;
}
.bodyOrders>*{
    color: white!important;
}
.bodyOrders{
    width: 100%!important;
}
.popUp{
    background-color: #3c4452;
    z-index: 999;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid white;
    overflow-y: auto;
}
</style>
