<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar">
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <div class="page-wrapper" :class="showRank?'opacity-25':''" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->

                <!--- Start Content --->
                <div class="mt-5 ">
                    <h2 class="text-white text-start fw-bold">{{$t('sidebar-News')}}</h2>
                     <div class="row mt-5 d-flex justify-content-evenly">
                            <div v-for="(notizia, index) in paginatedNotizie" :key="index " class="col-12 col-lg-5 p-2 border border-1 border-white-50 rounded-2 mb-4" >
                                <div class="col-12 d-flex">
                                    <img v-if="notizia.image_url" class="d-none d-lg-block imgnews" :src="notizia.image_url" alt="Img-new">
                                    <img v-else class="d-none d-lg-block imgnews" src="assets/fin.png"  alt="Img-new">
                                    <div class="align-self-start ps-3">
                                        <h3 class="card-title text-white">{{ notizia.title }}{{ index }}</h3>
                                        <h5 class="card-text">{{ notizia.source }}</h5>
                                        <h6>{{ notizia.pubblicazione }}</h6>
                                        <a target="_blank" :href="notizia.link" class="btn btn-primary">{{$t('News-read')}}</a>
                                    </div>
                                </div>
                            </div>
                         </div>
                    <nav aria-label="Page navigation example" class="d-flex justify-content-center mt-3" style="padding-bottom: 150px;"  >
                    <ul class="pagination">
                        <li class="page-item ms-auto" :class="{'disabled': currentPage === 1}">
                        <a class="page-link" href="#" aria-label="Previous" @click.prevent="prevPage">
                            <span aria-hidden="true">{{ $t('Prev') }}</span>
                        </a>
                        </li>
                        <li v-for="page in pages" :key="page" class="page-item d-none d-lg-block" :class="{'active': currentPage === page}">
                        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item me-auto" :class="{'disabled': currentPage === pages.length}">
                        <a class="page-link" href="#" aria-label="Next" @click.prevent="nextPage">
                            <span aria-hidden="true">{{ $t('Next') }}</span>
                        </a>
                        </li>
                    </ul>
                    </nav>
                </div>

                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->

    </div>
     <!-- ============================================================== -->
     <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import BannerKyc from './statics/bannerKyc';
import RankModal from "./RankModal";
export default {
    components: { Footer, Navbar, Sidebar,BannerKyc,RankModal},
  data() {
    return {
        showRank:false,
      notizie: [], // array di notizie
      currentPage: 1, // pagina corrente
      itemsPerPage: 20, // numero di elementi per pagina
    };
  },
  computed: {
    // calcola il numero totale di pagine
    totalPages() {
      return Math.ceil(this.notizie.length / this.itemsPerPage);
    },
    // calcola l'array di notizie per la pagina corrente
    paginatedNotizie() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.notizie.slice(startIndex, endIndex);
    },
    // calcola l'array di numeri di pagina
    pages() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    closeRank(){
            this.showRank=false
        },
        watchRank(value){
            console.log(value)
            this.showRank=value
        },
    // cambia la pagina corrente
    changePage(page) {
      this.currentPage = page;
    },
    // pagina precedente
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // pagina successiva
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getnews(){
            let today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0'); // Gennaio è 0!
                let yyyy = today.getFullYear();
                today = yyyy + '-' + mm + '-' + dd;

                // Tre giorni fa
                let threeDaysAgo = new Date();
                threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
                dd = String(threeDaysAgo.getDate()).padStart(2, '0');
                mm = String(threeDaysAgo.getMonth() + 1).padStart(2, '0'); // Gennaio è 0!
                yyyy = threeDaysAgo.getFullYear();
                threeDaysAgo = yyyy + '-' + mm + '-' + dd;
            axios.get(`${this.$baseUrl}/extra/getNews?start_date=${threeDaysAgo}&end_date=${today}`).then((response)=>{
                let array=response.data
                this.notizie = array.sort((a, b) => {
                    const dateA = new Date(a.pubDate);
                    const dateB = new Date(b.pubDate);
                    const now = new Date();

                    const diffA = Math.abs(now - dateA);
                    const diffB = Math.abs(now - dateB);

                    return diffA - diffB;
                    });
                this.notizie.forEach((element)=>{
                    let data= new Date(element.pubDate)
                    element.pubblicazione=(data.toDateString())
                })
            })
        }
  },
  mounted(){
        this.getnews()
    },
    beforeMount(){
        if(!this.$cookies.get('emailAccount') ){
            this.$router.push({ path: '/',})
        }
    }
};
</script>



<style scoped>
.imgnews{
    width:50%;
    height: auto;
    align-self: center;
    border-radius: 10px;
}
.pagination{
    --bs-pagination-focus-box-shadow:none

}
</style>
