<template>
    <div>
        <div class="d-flex gap-5">
            <p v-for="acc in accounts" :key="acc">
                Account: <span class="text-white"> {{ acc.username }}</span> -> {{$t('CurrentBalance')}}: <span class="text-success">{{ acc.balance }}</span>
            </p>
        </div>
        <div >
            <canvas id="accountsChart" width="200"></canvas>
        </div>
    </div>
   </template>

   <script>
   import Chart from "chart.js/auto"

   export default {
       data() {
         return {
             colorCodes : [
             "rgb(237, 147, 141,",
             "rgb(216, 141, 237,",
                "rgb(242, 201, 76,",
                "rgb(179, 185, 255,",
                "rgb(255, 223, 179,",
                "rgb(179, 255, 186,",
                "rgb(255, 242, 179,",
                "rgb(255, 255, 255,",
                "rgb(141, 226, 237,",
                "rgb(152, 141, 237,",





            ],
            eldate:'',
            elbalance:0,
            accounts:[],
            data:[],
            balance:[],
            max:[],
            i:0,
            chartData: {
                labels: [],
                datasets: [

                ],
            },
           areaChart: null,
         }
       },
       methods: {
    accountMetrics() {
        let list = (sessionStorage.getItem('accounts'))
        let allaccounts=JSON.parse(list).list_account
        allaccounts.forEach(elemento => {
            let acc= (sessionStorage.getItem(elemento))
            let element= JSON.parse(acc)
            if(element.status!='0'){
               this.accounts.push(element)
            }
        });
      this.accounts.forEach((account,ind) => {
        let metricheAcc=JSON.parse(localStorage.getItem((account.userID)))
          if (!metricheAcc){
              return false
          }
          if(metricheAcc.dailyGrowth){
             //metricheAcc = metricheAcc.data
          this.basecolor=`rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)},`
          // Aggiungi un set di dati separato per ogni account all'oggetto di configurazione
          const data = {
            label: account.username,
            data: [],
            backgroundColor: `${this.colorCodes[ind]}0.2)`,
            borderColor: `${this.colorCodes[ind]}1)`,
            borderWidth: 2,
            fill: 'origin',
            lineTension:0.4
          };
          this.basecolor=''
          this.chartData.datasets.push(data);
            this.balance=[]
            this.elbalance=0


            let startDate = new Date(account.created_at.split("T")[0]);
            let today = new Date();

            let endDate = new Date(today.getFullYear(), today.getMonth(), (today.getDate()+1));

            while (startDate <= endDate) {
            let date = startDate.toISOString().split("T")[0];
            if (!this.chartData.labels.includes( date)) {
              this.chartData.labels.push( date);
            }

            startDate.setDate(startDate.getDate() + 1);
            }
            if(metricheAcc.dailyGrowth){
                metricheAcc.dailyGrowth.forEach((element) => {
                this.chartData.labels.forEach((data)=>{
                this.eldate = element.date;
                this.elbalance = element.balance;
            // Aggiungi il valore di balance alla serie di dati corrispondente
            if(data==this.eldate){
                this.balance.push(this.elbalance);
            }else if(this.balance[this.balance.length-1]!=undefined){
                this.balance.push(this.balance[this.balance.length-1])
            }else{
                this.balance.push(account.initial_balance)
            }
            })


          });
            }

          this.chartData.datasets[this.i].data=this.balance
          const maxValue = Math.max(...this.chartData.datasets[this.i].data);
          this.max.push(maxValue)
          this.i++
          if(this.i==this.accounts.length){
          this.updateChart()
          }
          }

        });
    },
    createChart(){
               const ctx = document.getElementById('accountsChart');
               this.areaChart = new Chart(ctx, {
               type: 'line',
               data: this.chartData,
               maintainAspectRatio: false,
               options: {
                plugins:{
                    tooltip:{
                        padding:10,
                        boxPadding:3,
                        xAlign:'center',
                        yAlign:'center',
                        titleSpacing:15,
                        bodySpacing:10,
                        font:{
                            size:30
                        }
                    },
                },


                color: '#ffffff',
               responsive: true,
               scales: {
                y: {
                    suggestedMax:Math.max(...this.max),
                    beginAtZero: true,
                    ticks: {
                color:'white',}
                },
                x: {
                    ticks: {
                color:'white',
                display:false
                }
                }
                },
                },
            });
           },
    updateChart() {

      if (this.areaChart) {
        this.areaChart.destroy();
      }
      this.createChart()
    },
    destroyChart() {
            if (this.lineChart !== null) {
                this.lineChart.destroy();
                this.lineChart = null;
            }
        },
        formatCurrency(value){
            const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        });
                        return formatter.format(value);
        },
        beforeUnmount() {
    this.destroyChart();
  }
  },
  mounted() {
    this.accountMetrics();
  },

   }

   </script>

   <style>

   </style>
