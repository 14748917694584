<template>
    <body  class="skin-default card-no-border" >
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
        <div v-if="loading" class="position-absolute top-50 start-50 translate-middle fw-bold text-uppercase text-primary">Loading</div>
        <div v-if="!loading" class="row h-100 justify-content-start">
            <div class="w-100 d-flex">
              <div class="d-flex flex-column col-12 col-lg-7">
                <div class="mt-2 ms-2 mb-5 mb-lg-0 mt-lg-5 ms-lg-5"> <img src="/assets/images/logoScritte.png" ></div>
                    <div class="w-100 d-flex flex-column align-items-center my-auto " >
                    <div class="w-100" >
                        <div v-if="error" class="text-center pb-5">
                            <h3 class="text-danger fw-bold">{{ texterror }}</h3>
                        </div>
                        <form  @submit.prevent="createClient()" class="form-horizontal form-material d-flex flex-column gap-1 col-11 col-lg-8 mx-2 mx-lg-auto">
                            <div class="m-b-20">
                                <h1 class="text-start fw-bold text-white">Register</h1>
                                <p>Welcome back! Please enter your details</p>
                            </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 ">
                                <label for="firstName">Name</label>
                                <input class="form-control border border-1 border-white rounded" placeholder="Name" type="text" v-model="firstName" required id="firstName" >
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">

                            <div class="col-12 ">
                                <label for="lastName"> Last Name </label>
                                <input class="form-control border border-1 border-white rounded" placeholder="last Name" type="text" v-model="lastName" required id="lastName" >
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 ">
                                <label for="birthday">Birthday</label>
                                <input class="form-control rounded" v-model="birthday" type="date" required id="birthday" >
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 col-lg-5">
                                <label for="address">Address</label>
                                <input class="form-control border border-1 border-white rounded" placeholder="Address" v-model="address" type="text" required id="address" >
                            </div>
                            <div class="col-12 col-lg-5">
                                <label for="country">Country</label>
                                <select required name="country" class="form-control text-white border border-1 border-white rounded" id="country" v-model="country">
                                    <option value="0" selected="selected" > </option>
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Åland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">Antigua and Barbuda</option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia, Plurinational State of</option>
                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BA">Bosnia and Herzegovina</option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">British Indian Ocean Territory</option>
                                    <option value="BN">Brunei Darussalam</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">Central African Republic</option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">Cocos (Keeling) Islands</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">Congo, the Democratic Republic of the</option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Côte d'Ivoire</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CW">Curaçao</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">Dominican Republic</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">Equatorial Guinea</option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">French Southern Territories</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GG">Guernsey</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">Heard Island and McDonald Islands</option>
                                    <option value="VA">Holy See (Vatican City State)</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran, Islamic Republic of</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IM">Isle of Man</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JE">Jersey</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                    <option value="KR">Korea, Republic of</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Lao People's Democratic Republic</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macao</option>
                                    <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia, Federated States of</option>
                                    <option value="MD">Moldova, Republic of</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="MP">Northern Mariana Islands</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestinian Territory, Occupied</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Réunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russian Federation</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="BL">Saint Barthélemy</option>
                                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                    <option value="KN">Saint Kitts and Nevis</option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="MF">Saint Martin (French part)</option>
                                    <option value="PM">Saint Pierre and Miquelon</option>
                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">Sao Tome and Principe</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SX">Sint Maarten (Dutch part)</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                    <option value="SS">South Sudan</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syrian Arab Republic</option>
                                    <option value="TW">Taiwan, Province of China</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania, United Republic of</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">Trinidad and Tobago</option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">Turks and Caicos Islands</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">United Arab Emirates</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="UM">United States Minor Outlying Islands</option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">Virgin Islands, British</option>
                                    <option value="VI">Virgin Islands, U.S.</option>
                                    <option value="WF">Wallis and Futuna</option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                </select>
                            </div>
                            </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 col-lg-5">
                                <label for="city">City</label>
                                <input class="form-control border border-1 border-white rounded" placeholder="City" v-model="city" type="text" required id="city" >
                            </div>
                            <div class="col-12 col-lg-5">
                                <label for="zip">ZIP code</label>
                                <input class="form-control border border-1 border-white rounded" placeholder="Zip Code" v-model="zip" type="text" required id="zip" >
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 ">
                                <label for="phone">Phone</label>
                                <input class="form-control border border-1 border-white rounded" placeholder="Phone" type="text" v-model="phone" required id="phone" >
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 ">
                                <label for="email">Email</label>
                                <input class="form-control border border-1 border-white rounded" placeholder="Email" type="email" v-model="email" required id="email" @input="validateEmail()" >
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 " >
                                <label >Password</label>
                                <div class="d-flex border border-1 border-white rounded pe-2 displayFlex">
                                    <input id="password" placeholder="Password" :type="passType?'password':'text'" class="form-control" v-model="password" required autocomplete="off" >
                                    <i class="icons-Eye-Visible my-auto" style="justify-self:  end; align-self: flex-end;" @click="changeTipePsw()" ></i>
                                </div>
                            </div>
                        </div>
                        <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                            <div class="col-12 " >
                                <label >Confirm Password</label>
                                <div class="d-flex border border-1 border-white rounded pe-2 displayFlex">
                                    <input id="confirmPassword" placeholder="Confirm Password" :type="confType?'password':'text'" class="form-control" v-model="confirmPassword" required autocomplete="off"   @input="validatePsw">
                                    <i class="icons-Eye-Visible my-auto" style="justify-self:  end; align-self: flex-end;" @click="changeTipeConfPsw()" ></i>
                                </div>
                                <span v-if="pswError" class="error text-danger">{{ pswError }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <div class="d-flex align-items-center gap-2 displayFlex" :style="checkText!=''?'border-bottom:1px solid red;padding-bottom:5px':''">
                                    <input type="checkbox" class="form-check-input border border-2 border-light" id="customCheck1" v-model="isChecked">
                                    <label class="form-check-label" for="customCheck1">I agree to all <a target="_blank" href="https://ofpfunding.com/terms-conditions/">Terms</a></label>
                                </div>
                                <p class="text-danger text-uppercase" v-if="checkText!=''">{{ checkText }}</p>
                            </div>
                        </div>
                        <div class="form-group text-center p-b-20">
                            <div class="col-xs-12">
                                <button class="btn btn-primary btn-lg w-100 rounded waves-effect waves-light"  type="submit" :disabled="!registerok">Sign Up</button>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                Already have an account? <a href="/" class="text-primary m-l-5"><b class="text-primary fw-bold">Sign In</b></a>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
              </div>
              <div class="col-5 d-none d-lg-flex loginDx position-relative">
                    <div class="position-absolute posizioneImg w-100 rounded">
                        <img class="img-fluid" src="/assets/images/imgLoginRegister.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>

    </section>

</body>
</template>
<script>

export default {
    data() {
            return {
                registerok:false,
                confType:true,
                passType:true,
                isChecked:false,
                checkText:'',
                firstName: "",
                lastName: "",
                birthday: "",
                bio: "",
                address: "",
                city: "",
                zip: "",
                country: "",
                phone: "",
                email: "",
                emailError:'',
                password: "",
                confirmPassword:'',
                pswError:'',
                error: null,
                locale: 'en'
            }
        },
    methods: {
            goBuy(){
                window.location.replace('https://ofpfunding.com/account-types/')
            },
            changeTipePsw(){
                this.passType=!this.passType
            },
            changeTipeConfPsw(){
                this.confType=!this.confType
            },
            async createClient() {
                if (!this.isChecked) {
                    this.checkText = 'Required';
                    return;
                }

                this.origin = this.$route.query?.ref_origin
                    || this.$cookies.get('ref_origin')
                    || sessionStorage.getItem('ref_origin')
                    || localStorage.getItem('ref_origin')
                    || null;
                this.$cookies.remove('ref_origin');
                localStorage.removeItem('ref_origin');
                sessionStorage.removeItem('ref_origin');
                let numberPhone = this.phone.replace(/[^0-9]/g, '');
                numberPhone=(parseInt(numberPhone)).toFixed(0)
                const payload = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    country: this.country ,
                    address: this.address,
                    city: this.city,
                    zip: this.zip,
                    phone: numberPhone,
                    birthday: this.birthday,
                    bio_description: this.bio,
                    ref_origin:this.origin
                };

                try {
                    const response = await axios.post(`${this.$baseUrl}/customers/createCustomer`, payload)
                    if(response.status !== 200) {
                        console.error(response.data.message)
                        return;
                    }

                    try {
                        window.sendinblue.track(
                            'registered_client',
                            {
                                email: this.email,
                                FIRSTNAME: this.firstName,
                                LASTNAME: this.lastName
                            },
                            {
                                data: {
                                    email: this.email,
                                    FIRSTNAME: this.firstName,
                                    LASTNAME: this.lastName
                                }
                            }
                        );
                    } catch (e) {
                        console.error('brevo', e);
                    }
                    try {
                        this.$gtm.trackEvent({
                            event: 'sign_up',
                            value: response.data
                        });
                    } catch (e) {
                        console.error('gtm', e)
                    }

                    console.log('account creato')
                    window.location.replace('/');
                } catch(e) {
                    console.error(e);
                }
            },
            validateEmail() {
                // Regular expression to match the format of an email address
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                // Check if the input matches the regular expression
                if (!regex.test(this.email)) {
                this.emailError = 'Please enter a valid email address.';
                } else {
                this.emailError = '';
                }
             },
             validatePsw() {
                if (this.password !== this.confirmPassword) {
                    this.pswError = 'Password do not match';
                    this.registerok=false
                } else {
                    this.pswError = '';
                    this.registerok=true
             }},
        },
        watch: {
            locale(newLocale) {
                this.$i18n.locale = newLocale
            },
            password(){
            this.validatePsw()
        },
        confirmPassword(){
            this.validatePsw()
        }
        },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;500;700&display=swap');

.o-income .c3 line, .o-income .c3 path {
  stroke: #e9ecef; }

.news-slide .carousel-item {
  min-height: 515px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 56%, rgba(0, 0, 0, 0.65) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 56%, rgba(0, 0, 0, 0.65) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 56%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  /* IE6-9 */ }

.news-slide .carousel-caption {
  text-align: left;
  left: 25px;
  right: 25px; }

#cc-table span a i {
  font-size: 25px;
 }

label{
    color:#ffffff!important;
}
.form-group{
    margin-bottom:20px!important;
}
.login-box{
    width: 100%;
}
input{
    background-color: transparent!important;
}
select{
    background-color: transparent!important;
}
select>option{
    background-color: #1e1f41;
}
.displayFlex{
    display: flex!important;
}
.form-group{
    margin-bottom:20px ;
}
input{
    background-color: transparent!important;
    padding: 10px!important;
}
.loginDx{
    background-color:rgba(255, 255, 255,0.1);
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 200px;
}
.posizioneImg{
    transform: translate(-15%,-15%);
}


input[type="date"] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
    padding: 5px;
    height: auto;  /* Adattare secondo le proprie esigenze */
    width: 100%;  /* Adattare secondo le proprie esigenze */
}

input[type="date"]::-webkit-datetime-edit {
    display: block;
    padding: 0; /* Adattare secondo le proprie esigenze */
    /* Adattare secondo le proprie esigenze */
    border-color: transparent;
}

</style>

