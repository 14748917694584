<template>
    <!--KYC-->
  <div v-if="client.kyc!='approved'">
    <div  class="mx-auto p-2 d-flex justify-content-between rounded " style="background-color: rgba(194, 79, 53, 0.2)!important;display: flex!important;">
        <div class="col-6 d-flex align-items-center justify-content-start fw-bold " style="display: flex!important;">
            <i class="mdi mdi-alert-outline fs-3 text-white me-2 d-none d-lg-block"></i>
            <h6 class="m-0 p-0 text-white text-center" >{{ $t('KYC-Banner') }}</h6>
        </div>
        <div  class="d-flex col-6 justify-content-end align-items-center" style="display: flex!important;">
            <button v-if="veriff==null" class="btn btn-primary" style="border-radius: 10px;" @click="startVeriff()">{{ $t('KYC-Verify') }}</button>
        </div>
        <div id='veriff-root' class="mt-2"></div>
    </div>
  </div>
    <!-- /KYC -->
</template>

<script>
import {Veriff} from '@veriff/js-sdk';
export default {
    name:'banner',
  methods:{
    setClient(){
       let clientCookies = this.$cookies.get('client') || {};
       this.client= clientCookies|| {kyc: ''}
    },
    startVeriff() {
          let client= this.$cookies.get('client')
          let id= client._id
        this.veriff = new Veriff({
        apiKey: '3f684eb9-2d9e-4ff2-8aed-993ee59d4b85',
        parentId: 'veriff-root',
        onSession: function(err, response) {
          if (err) {
            console.error(err);
            return;
          }
          // Redirect to the verification session url
          window.open(response.verification.url, '_blank');
        }

      });
      this.veriff.setParams({
            vendorData: id
        });
        this.veriff.mount();
    },

  },
  data(){
    return{
        client:'',
        veriff:null
    }
  },
  beforeMount() {
        this.setClient()
    }
}
</script>
<style scoped>
    #veriff-root{
        max-width: 100%!important;
        color: white!important;
    }
    .veriff-description {
        color: white!important;
    }
</style>

