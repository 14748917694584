
<template>
    <div id="main-wrapper">
        <Spinner v-if="loading"/>
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" :class="{ 'opacity-25': loading }" >
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" :class="{ 'opacity-25': loading }">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
                <div class="page-wrapper mt-4 position-relative" :class="{ 'opacity-25': loading }">
        <div class="container-fluid pt-3" :class="modalOn || modalOnBF?'opacity-25':''">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="row  d-flex justify-content-between">
                <div class=" col-12 mb-5 mb-lg-0">
                    <!--Choose Acc-->
                    <div class="col-12 mb-2 row">
                        <h2 class="text-center mb-5 text-white fw-bold">{{ $t('shop-title') }}</h2>
                        <div  class="col-12 py-4 d-flex flex-column gap-3 align-items-center justify-content-center flexdisplay" id="currency">
                            <div class="">
                                <h3 class="text-white">Account {{ $t('Type') }}:</h3>
                            </div>
                            <div class="d-block d-xl-flex justify-content-center align-items-start col-12 gx-2 gy-3" >
                                <div class="form-check form-check-inline col-12 col-xl-2 px-0" @click="selectAccType('')">
                                    <div class="d-flex justify-content-start align-items-center border border-1 rounded  " :class="{ 'bg-primary ': typeAccSelcet==''}">
                                        <label class=" py-3 d-flex align-items-center w-100 justify-content-around text-white text-center"  >
                                            <p class="p-0 m-0">{{ $t('MontPay') }}</p>
                                        </label>
                                        <input class="form-check-input d-none" type="radio" title="size">
                                    </div>
                                </div>
                                <div class="form-check form-check-inline col-12 col-xl-2 px-0" @click="selectAccType('on-demand')">
                                    <div class="d-flex justify-content-start align-items-center border border-1 rounded  " :class="{ 'bg-primary ': typeAccSelcet=='on-demand'}">
                                        <label class=" py-3 d-flex align-items-center w-100 justify-content-around text-white text-center"  >
                                            <p class="p-0 m-0">{{ $t('ondemand') }}</p>
                                        </label>
                                        <input class="form-check-input d-none" type="radio" title="size">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-xl-flex justify-content-between gap-2">
                            <div  class=" col-12 col-xl-5 py-4 d-flex flex-column gap-3 align-items-start justify-content-center flexdisplay" id="currency">
                                <div class="">
                                    <h3 class="text-white">Account {{ $t('Value') }}:</h3>
                                </div>
                                <div class="d-block d-xl-flex justify-content-start align-items-start col-12 gx-2 gy-3" >
                                    <div class="form-check form-check-inline col-12 col-xl-2 px-0" v-for="(element) in baseSize" :key="element" @click="choosingAcc(element/1000)">
                                        <div class="d-flex justify-content-start align-items-center border border-1 rounded  " :class="{ 'bg-primary ': element/1000==selectedSize}">
                                            <label class=" py-3 d-flex align-items-center w-100 justify-content-around text-white text-center"  >
                                                <h6 class="p-0 m-0">{{element/1000}}.000</h6>
                                            </label>
                                            <input class="form-check-input d-none" type="radio" title="size">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  class=" col-12 col-xl-4 py-4 d-flex flex-column gap-3 align-items-end justify-content-center flexdisplay"  id="currency">
                                <div class="">
                                    <h3 class="text-white">Account  {{ $t('Currency') }}:</h3>
                                </div>
                                <div class="d-block d-xl-flex justify-content-end align-items-center col-12 gx-2 gy-3" >
                                    <div class="form-check form-check-inline col-12 col-xl-2 px-0 me-0 ms-3" v-for="(element) in currencies" :key="element" @click="takeCurr(element)">
                                        <div class="d-flex justify-content-start align-items-center border border-1 py-3 rounded" :class="{ 'bg-primary': selectedCurrency === element}">
                                            <label class="d-flex align-items-center w-100 justify-content-around text-white text-center" :for="element" >
                                                <h6 class="p-0 m-0">{{ element}}</h6>
                                            </label>
                                            <input class="form-check-input d-none" type="radio" name="size" :id="element" :value="element" v-model="selectedCurrency">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Timer Promo -->
                    <div v-if='showCountdown' style="color:white">
                        <h6 id="countDownTitle">{{ $t('promo1') }} {{countDownAVG}} {{$t('promo2')}}:</h6>
                        <div id="countdownContainer">
                            <div id="countdown"></div>
                            <div id="progressBarContainer">
                                <div id="progressBar"></div>
                            </div>
                        </div>
                    </div>
                    <!-- /Timer Promo -->


                    <!--Acc -->
                    <div  class="col-12 mt-3">
                        <div class="row">
                            <div class="table-responsive mb-5">
                                <table class="table table-bordered text-white">
                                    <thead>
                                    <tr class="fw-bold text-uppercase fs-4">
                                        <th scope="col">
                                            <h4 class="fw-bold m-0">Account {{ $t('Type') }}</h4>
                                            <div class="d-flex">
                                                <div class="form-check form-check-inline px-0 me-0 ">
                                                    <div class="d-flex justify-content-center align-items-center py-2 flexdisplay">
                                                        <p class="m-0 p-0">MT4</p>
                                                        <div class="form-check form-switch mx-2">
                                                            <input  v-model="selectedPlatform" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                                                        </div>
                                                        <p class="m-0 p-0">MT5</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col" :class="element.title.includes('80')?'opacita':''" class="text-center"  v-for="element in type" :key="element.title" >
                                            {{ element.title }}
                                            <br/>
                                            <p style="font-size: 10px;" class="btn position-relative" @click="openExplain(element.title)">See How it Works</p>
                                            <div :id="element.title" class="d-none bg-primary">
                                                <div class="d-flex justify-content-end text-end">
                                                    <button type="button" class="btn-close " @click="closeDettails(element.title)"></button>
                                                </div>
                                                <div class="d-flex justify-content-center text-white">
                                                    <h1 class="fs-2 fw-bold" id="">{{element.title}}</h1>
                                                </div>
                                                <div v-if="typeAccSelcet==''" class="d-flex flex-column align-items-center justify-content-center fs-6">
                                                    <p> {{ $t('shop-text1') }} {{ element.title.split(' ')[0] }} {{ $t('shop-text2') }}</p>
                                                    <p> {{ $t('shop-text3') }}</p>
                                                </div>
                                                <div v-else-if="typeAccSelcet=='on-demand'" class="d-flex flex-column align-items-center justify-content-center fs-6">
                                                    <p> {{ $t('shop-text0') }} {{ element.title.split(' ')[0] }} {{ $t('shop-text2') }}</p>
                                                    <p> {{ $t('shop-text3') }}</p>
                                                </div>
                                            </div>

                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody >
                                    <tr class="border-bottom" v-if="accsizes.length>0">
                                        <th scope="row text-start" >{{ $t('shop-table1') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">1:100</td>
                                    </tr>
                                    <tr v-else>
                                        <th scope="row text-start" >{{ $t('shop-table2') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">-</td>
                                    </tr>
                                    <tr class="border-bottom" v-if="accsizes.length>0">
                                        <th scope="row text-start" >{{ $t('shop-table2') }}</th>
                                        <template v-for="(element) in accsizes" :key="element">
                                            <td :class="element.id.includes('80')?'opacita':''" class="text-center" v-if="element.id.includes(selectedCurrency)">5%</td>
                                        </template>
                                    </tr>
                                    <tr v-else>
                                        <th scope="row text-start" >{{ $t('shop-table2') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">-</td>
                                    </tr>
                                    <tr class="border-bottom" v-if="accsizes.length>0">
                                        <th scope="row text-start" >{{ $t('shop-table3') }}</th>
                                        <template v-for="(element) in accsizes" :key="element">
                                            <td :class="element.id.includes('80')?'opacita':''" class="text-center" v-if="element.id.includes(selectedCurrency)">10%</td>
                                        </template>
                                    </tr>
                                    <tr v-else>
                                        <th scope="row text-start" >{{ $t('shop-table3') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">-</td>
                                    </tr>
                                    <tr class="border-bottom" v-if="accsizes.length>0">
                                        <th scope="row text-start" >{{ $t('shop-table4') }}</th>
                                        <template v-for="(element) in accsizes" :key="element">
                                            <td  :class="element.id.includes('80')?'opacita':''" class="text-center" v-if="element.id.includes(selectedCurrency) && element.name.includes('Monthly')">25%</td>
                                            <td  :class="element.id.includes('80')?'opacita':''" class="text-center" v-if="element.id.includes(selectedCurrency) && element.name.includes('Demand')">20%</td>
                                        </template>
                                    </tr>
                                    <tr v-else>
                                        <th scope="row text-start" >{{ $t('shop-table4') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">-</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <th scope="row text-start" >{{ $t('shop-table5') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">{{ item.title.split(' ')[0] }}</td>
                                    </tr>
                                    <tr class="border-bottom" v-if="accsizes.length>0">
                                        <th scope="row text-start fw-bold" >{{ $t('Price') }}</th>
                                        <template v-for="(element) in accsizes" :key="element">
                                            <td  :class="element.id.includes('80')?'opacita':''" class="text-center fw-bold text-white" v-if="element.id.includes(selectedCurrency) && element.off30" >
                                                 <p class="text-decoration-line-through">{{ element.price.toLocaleString('it-IT',) }} USD</p>
                                                 <p class="text-success">{{ element.off30.toLocaleString('it-IT',) }} USD</p>
                                            </td>
                                            <td  :class="element.id.includes('80')?'opacita':''" class="text-center fw-bold text-white" v-else-if="element.id.includes(selectedCurrency) && element.off50" >
                                                 <p class="text-decoration-line-through">{{ element.price.toLocaleString('it-IT',) }} USD</p>
                                                 <p class="text-success">{{ element.off50.toLocaleString('it-IT',) }} USD</p>
                                            </td>
                                            <td  :class="element.id.includes('80')?'opacita':''" class="text-center fw-bold text-white" v-else-if="element.id.includes(selectedCurrency)" >
                                                 <p>{{ element.price.toLocaleString('it-IT',) }} USD</p>
                                            </td>
                                        </template>
                                    </tr>
                                    <tr v-else>
                                        <th scope="row text-start" >{{ $t('Price') }}</th>
                                        <td v-for="item in type" :key="item" class="text-center" :class="item.title.includes('80')?'opacita':''">-</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <th scope="row"></th>
                                        <td class="text-center text-white" :class="element.title.includes('80')?'opacita':''" v-for="element in type" :key="element.title">
                                            <div href=""
                                                 :class="order.name || selectedCurrency=='' || selectedSize=='' || ((selectedType || '').length)  ? 'btn disabled' : element.title.includes('80') ? 'btn btn-primary text-white' : 'btn btn-outline-primary text-white'"
                                                 @click="addOrder(element.title)"  >
                                                {{ $t('navbar-buy') }}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <div v-if="modalOn || modalOnBF" class="p-3 text-center d-flex flex-column justify-content-center card col-10 positionPopUp">
            <h3 class="text-danger fw-bold mb-2" v-if="order.name">{{ $t('shop-checkoutText') }}</h3>
            <a v-if="modalOn==true" class="text-white fs-1" href="/checkout">{{ $t('btn-checkout') }}</a>
            <a v-if="modalOnBF==true" class="text-white fs-1" href="/checkout/bundle">{{ $t('btn-checkout') }}</a>
        </div>
        <div v-if="showModalSelections" class="p-3 text-center d-flex flex-column justify-content-center card col-10 positionPopUp" >
            <div class="">
                <h5 class="">{{ $t('Warning') }}</h5>
                <p>{{ textErrCat }}</p>
                <div class="">
                    <button type="button" class="btn btn-danger" @click="showModalSelections=false" >{{ $t('Close') }}</button>
                </div>
            </div>
        </div>
    </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>

</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/statics/navbar.vue";
import Footer from "../components/statics/footer.vue";
import Spinner from "../components/Spinner.vue"
export default {
    components: {Footer, Navbar, Sidebar, Spinner},
    data(){
        return{
            options: [
                { value: 'AF', label: 'Afghanistan'},
                { value: 'AX', label: 'Aland Islands'},
                { value: 'AL', label: 'Albania'},
                { value: 'DZ', label: 'Algeria'},
                { value: 'AS', label: 'American Samoa'},
                { value: 'AD', label: 'Andorra'},
                { value: 'AO', label: 'Angola'},
                { value: 'AI', label: 'Anguilla'},
                { value: 'AQ', label: 'Antarctica'},
                { value: 'AG', label: 'Antigua And Barbuda'},
                { value: 'AR', label: 'Argentina'},
                { value: 'AM', label: 'Armenia'},
                { value: 'AW', label: 'Aruba'},
                { value: 'AU', label: 'Australia'},
                { value: 'AT', label: 'Austria'},
                { value: 'AZ', label: 'Azerbaijan'},
                { value: 'BS', label: 'Bahamas'},
                { value: 'BH', label: 'Bahrain'},
                { value: 'BD', label: 'Bangladesh'},
                { value: 'BB', label: 'Barbados'},
                { value: 'BY', label: 'Belarus'},
                { value: 'BE', label: 'Belgium'},
                { value: 'BZ', label: 'Belize'},
                { value: 'BJ', label: 'Benin'},
                { value: 'BM', label: 'Bermuda'},
                { value: 'BT', label: 'Bhutan'},
                { value: 'BO', label: 'Bolivia'},
                { value: 'BA', label: 'Bosnia And Herzegovina'},
                { value: 'BW', label: 'Botswana'},
                { value: 'BV', label: 'Bouvet Island'},
                { value: 'BR', label: 'Brazil'},
                { value: 'IO', label: 'British Indian Ocean Territory'},
                { value: 'BN', label: 'Brunei Darussalam'},
                { value: 'BG', label: 'Bulgaria'},
                { value: 'BF', label: 'Burkina Faso'},
                { value: 'BI', label: 'Burundi'},
                { value: 'KH', label: 'Cambodia'},
                { value: 'CM', label: 'Cameroon'},
                { value: 'CA', label: 'Canada'},
                { value: 'CV', label: 'Cape Verde'},
                { value: 'KY', label: 'Cayman Islands'},
                { value: 'CF', label: 'Central African Republic'},
                { value: 'TD', label: 'Chad'},
                { value: 'CL', label: 'Chile'},
                { value: 'CN', label: 'China'},
                { value: 'CX', label: 'Christmas Island'},
                { value: 'CC', label: 'Cocos (Keeling) Islands'},
                { value: 'CO', label: 'Colombia'},
                { value: 'KM', label: 'Comoros'},
                { value: 'CG', label: 'Congo'},
                { value: 'CD', label: 'Congo}, Democratic Republic'},
                { value: 'CK', label: 'Cook Islands'},
                { value: 'CR', label: 'Costa Rica'},
                { value: 'CI', label: 'Cote D\'Ivoire'},
                { value: 'HR', label: 'Croatia'},
                { value: 'CU', label: 'Cuba'},
                { value: 'CY', label: 'Cyprus'},
                { value: 'CZ', label: 'Czech Republic'},
                { value: 'DK', label: 'Denmark'},
                { value: 'DJ', label: 'Djibouti'},
                { value: 'DM', label: 'Dominica'},
                { value: 'DO', label: 'Dominican Republic'},
                { value: 'EC', label: 'Ecuador'},
                { value: 'EG', label: 'Egypt'},
                { value: 'SV', label: 'El Salvador'},
                { value: 'GQ', label: 'Equatorial Guinea'},
                { value: 'ER', label: 'Eritrea'},
                { value: 'EE', label: 'Estonia'},
                { value: 'ET', label: 'Ethiopia'},
                { value: 'FK', label: 'Falkland Islands (Malvinas)'},
                { value: 'FO', label: 'Faroe Islands'},
                { value: 'FJ', label: 'Fiji'},
                { value: 'FI', label: 'Finland'},
                { value: 'FR', label: 'France'},
                { value: 'GF', label: 'French Guiana'},
                { value: 'PF', label: 'French Polynesia'},
                { value: 'TF', label: 'French Southern Territories'},
                { value: 'GA', label: 'Gabon'},
                { value: 'GM', label: 'Gambia'},
                { value: 'GE', label: 'Georgia'},
                { value: 'DE', label: 'Germany'},
                { value: 'GH', label: 'Ghana'},
                { value: 'GI', label: 'Gibraltar'},
                { value: 'GR', label: 'Greece'},
                { value: 'GL', label: 'Greenland'},
                { value: 'GD', label: 'Grenada'},
                { value: 'GP', label: 'Guadeloupe'},
                { value: 'GU', label: 'Guam'},
                { value: 'GT', label: 'Guatemala'},
                { value: 'GG', label: 'Guernsey'},
                { value: 'GN', label: 'Guinea'},
                { value: 'GW', label: 'Guinea-Bissau'},
                { value: 'GY', label: 'Guyana'},
                { value: 'HT', label: 'Haiti'},
                { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
                { value: 'VA', label: 'Holy See (Vatican City State)'},
                { value: 'HN', label: 'Honduras'},
                { value: 'HK', label: 'Hong Kong'},
                { value: 'HU', label: 'Hungary'},
                { value: 'IS', label: 'Iceland'},
                { value: 'IN', label: 'India'},
                { value: 'ID', label: 'Indonesia'},
                { value: 'IR', label: 'Iran}, Islamic Republic Of'},
                { value: 'IQ', label: 'Iraq'},
                { value: 'IE', label: 'Ireland'},
                { value: 'IM', label: 'Isle Of Man'},
                { value: 'IL', label: 'Israel'},
                { value: 'IT', label: 'Italia'},
                { value: 'JM', label: 'Jamaica'},
                { value: 'JP', label: 'Japan'},
                { value: 'JE', label: 'Jersey'},
                { value: 'JO', label: 'Jordan'},
                { value: 'KZ', label: 'Kazakhstan'},
                { value: 'KE', label: 'Kenya'},
                { value: 'KI', label: 'Kiribati'},
                { value: 'KR', label: 'Korea'},
                { value: 'KW', label: 'Kuwait'},
                { value: 'KG', label: 'Kyrgyzstan'},
                { value: 'LA', label: 'Lao People\'s Democratic Republic'},
                { value: 'LV', label: 'Latvia'},
                { value: 'LB', label: 'Lebanon'},
                { value: 'LS', label: 'Lesotho'},
                { value: 'LR', label: 'Liberia'},
                { value: 'LY', label: 'Libyan Arab Jamahiriya'},
                { value: 'LI', label: 'Liechtenstein'},
                { value: 'LT', label: 'Lithuania'},
                { value: 'LU', label: 'Luxembourg'},
                { value: 'MO', label: 'Macao'},
                { value: 'MK', label: 'Macedonia'},
                { value: 'MG', label: 'Madagascar'},
                { value: 'MW', label: 'Malawi'},
                { value: 'MY', label: 'Malaysia'},
                { value: 'MV', label: 'Maldives'},
                { value: 'ML', label: 'Mali'},
                { value: 'MT', label: 'Malta'},
                { value: 'MH', label: 'Marshall Islands'},
                { value: 'MQ', label: 'Martinique'},
                { value: 'MR', label: 'Mauritania'},
                { value: 'MU', label: 'Mauritius'},
                { value: 'YT', label: 'Mayotte'},
                { value: 'MX', label: 'Mexico'},
                { value: 'FM', label: 'Micronesia}, Federated States Of'},
                { value: 'MD', label: 'Moldova'},
                { value: 'MC', label: 'Monaco'},
                { value: 'MN', label: 'Mongolia'},
                { value: 'ME', label: 'Montenegro'},
                { value: 'MS', label: 'Montserrat'},
                { value: 'MA', label: 'Morocco'},
                { value: 'MZ', label: 'Mozambique'},
                { value: 'MM', label: 'Myanmar'},
                { value: 'NA', label: 'Namibia'},
                { value: 'NR', label: 'Nauru'},
                { value: 'NP', label: 'Nepal'},
                { value: 'NL', label: 'Netherlands'},
                { value: 'AN', label: 'Netherlands Antilles'},
                { value: 'NC', label: 'New Caledonia'},
                { value: 'NZ', label: 'New Zealand'},
                { value: 'NI', label: 'Nicaragua'},
                { value: 'NE', label: 'Niger'},
                { value: 'NG', label: 'Nigeria'},
                { value: 'NU', label: 'Niue'},
                { value: 'NF', label: 'Norfolk Island'},
                { value: 'MP', label: 'Northern Mariana Islands'},
                { value: 'NO', label: 'Norway'},
                { value: 'OM', label: 'Oman'},
                { value: 'PK', label: 'Pakistan'},
                { value: 'PW', label: 'Palau'},
                { value: 'PS', label: 'Palestinian Territory}, Occupied'},
                { value: 'PA', label: 'Panama'},
                { value: 'PG', label: 'Papua New Guinea'},
                { value: 'PY', label: 'Paraguay'},
                { value: 'PE', label: 'Peru'},
                { value: 'PH', label: 'Philippines'},
                { value: 'PN', label: 'Pitcairn'},
                { value: 'PL', label: 'Poland'},
                { value: 'PT', label: 'Portugal'},
                { value: 'PR', label: 'Puerto Rico'},
                { value: 'QA', label: 'Qatar'},
                { value: 'RE', label: 'Reunion'},
                { value: 'RO', label: 'Romania'},
                { value: 'RU', label: 'Russian Federation'},
                { value: 'RW', label: 'Rwanda'},
                { value: 'BL', label: 'Saint Barthelemy'},
                { value: 'SH', label: 'Saint Helena'},
                { value: 'KN', label: 'Saint Kitts And Nevis'},
                { value: 'LC', label: 'Saint Lucia'},
                { value: 'MF', label: 'Saint Martin'},
                { value: 'PM', label: 'Saint Pierre And Miquelon'},
                { value: 'VC', label: 'Saint Vincent And Grenadines'},
                { value: 'WS', label: 'Samoa'},
                { value: 'SM', label: 'San Marino'},
                { value: 'ST', label: 'Sao Tome And Principe'},
                { value: 'SA', label: 'Saudi Arabia'},
                { value: 'SN', label: 'Senegal'},
                { value: 'RS', label: 'Serbia'},
                { value: 'SC', label: 'Seychelles'},
                { value: 'SL', label: 'Sierra Leone'},
                { value: 'SG', label: 'Singapore'},
                { value: 'SK', label: 'Slovakia'},
                { value: 'SI', label: 'Slovenia'},
                { value: 'SB', label: 'Solomon Islands'},
                { value: 'SO', label: 'Somalia'},
                { value: 'ZA', label: 'South Africa'},
                { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
                { value: 'ES', label: 'Spain'},
                { value: 'LK', label: 'Sri Lanka'},
                { value: 'SD', label: 'Sudan'},
                { value: 'SR', label: 'Suriname'},
                { value: 'SJ', label: 'Svalbard And Jan Mayen'},
                { value: 'SZ', label: 'Swaziland'},
                { value: 'SE', label: 'Sweden'},
                { value: 'CH', label: 'Switzerland'},
                { value: 'SY', label: 'Syrian Arab Republic'},
                { value: 'TW', label: 'Taiwan'},
                { value: 'TJ', label: 'Tajikistan'},
                { value: 'TZ', label: 'Tanzania'},
                { value: 'TH', label: 'Thailand'},
                { value: 'TL', label: 'Timor-Leste'},
                { value: 'TG', label: 'Togo'},
                { value: 'TK', label: 'Tokelau'},
                { value: 'TO', label: 'Tonga'},
                { value: 'TT', label: 'Trinidad And Tobago'},
                { value: 'TN', label: 'Tunisia'},
                { value: 'TR', label: 'Turkey'},
                { value: 'TM', label: 'Turkmenistan'},
                { value: 'TC', label: 'Turks And Caicos Islands'},
                { value: 'TV', label: 'Tuvalu'},
                { value: 'UG', label: 'Uganda'},
                { value: 'UA', label: 'Ukraine'},
                { value: 'AE', label: 'United Arab Emirates'},
                { value: 'GB', label: 'United Kingdom'},
                { value: 'US', label: 'United States'},
                { value: 'UM', label: 'United States Outlying Islands'},
                { value: 'UY', label: 'Uruguay'},
                { value: 'UZ', label: 'Uzbekistan'},
                { value: 'VU', label: 'Vanuatu'},
                { value: 'VE', label: 'Venezuela'},
                { value: 'VN', label: 'Viet Nam'},
                { value: 'VG', label: 'Virgin Islands}, British'},
                { value: 'VI', label: 'Virgin Islands}, U.S.'},
                { value: 'WF', label: 'Wallis And Futuna'},
                { value: 'EH', label: 'Western Sahara'},
                { value: 'YE', label: 'Yemen'},
                { value: 'ZM', label: 'Zambia'},
                { value: 'ZW', label: 'Zimbabwe'}
            ],
            platform:['MT4','MT5'],
            currencies:['EUR','USD', 'GBP'],
            firstName:'',
            lastName:'',
            address:'',
            city:'',
            phone:'',
            birth:'',
            zip:'',
            email:'',
            id:'',
            country:'',
            client:[],
            type:[],
            baseSize:[],
            sizes:[],
            selectedCurrency:'EUR',
            selectedPlatform:false,
            selectedSize:5,
            selectedType:'',
            order:'',
            textErrCat:'',
            accsizes:[],
            items:[],
            modalOn:false,
            modalOnBF:false,
            showModalSelections:false,
            loading:true,
            typeAccSelcet:'',
            accountAff:0,
            showCountdown:false,
            countDownAVG:''
        }
    },
    methods:{
        openExplain(value){
            this.type.forEach((element)=>{
                if(element.title == value){
                    let docs= document.getElementById(value)
                    docs.classList.remove('d-none')
                    docs.classList.add('openDettails')
                }else{
                    let docs= document.getElementById(element.title)
                    docs.classList.add('d-none')
                }
            })
        },
        closeDettails(value){
            this.type.forEach((element)=>{
                if(element.title == value){
                    let docs= document.getElementById(value)
                    docs.classList.remove('openDettails')
                    docs.classList.add('d-none')
                }
            })
        },
        selectAccType(value){
            this.$cookies.set('typeBuy', value)
            this.typeAccSelcet=value
            this.getItems()
        },
        takeCurr(value){
            this.selectedCurrency=value
        },
        formatCurrency(curr,value){
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: curr,
                minimumFractionDigits: 2,
            });
            return formatter.format(value);
        },
        getItems(){
            this.type=[]
            this.items=[]
            this.sizes=[]
            axios.get(`${this.$baseUrl}/ecommerce/getItems?tags=${this.typeAccSelcet}`).then((response)=>{
                this.items=response.data
                console.log(this.items)
                this.items.forEach(element => {
                    this.type.push({
                        title:element.name,
                        text:element.description.toLowerCase(),

                    })
                    element.versions.forEach(version=>{
                        this.baseSize.push(version.size)
                        this.baseSize = [...new Set(this.baseSize)];
                        this.sizes.push({
                            id:version.id,
                            size:version.size,
                            price:version.price,
                            name:version.name
                        })
                    })
                });
                this.clientInfo()
                this.choosingAcc(5)
                this.loading=false
            })
        },
        addOrder(type){
            // Ottieni la data attuale
            const currentDate = new Date().getTime();
            const endDate = new Date('2024-02-10').getTime();

            this.selectedType=type
            if(this.order!=''){
                this.textErrCat='You already have an item in your cart, confirm the order or remove the item'
            }
            else if(this.order=='' && this.selectedCurrency!='' && this.selectedSize!=''){
                this.textErrCat=''
                this.accsizes.forEach(( async element=>{
                    //Inserisco Platform
                    if(element.id.includes(`${this.selectedSize}K${this.selectedCurrency}${type.split('%')[0]}`)){
                        let plat=''
                        if(this.selectedPlatform==true){
                            plat = "MT5"
                        }else if(this.selectedPlatform==false){
                            plat = "MT4"
                        }

                        let itemsToAdd=[]
                        // Check per B1G1 e definizione item to add
                        if(currentDate <= endDate){
                            if(this.accountAff>0 || this.typeAccSelcet=='on-demand' || this.selectedSize<50.000){
                            itemsToAdd.push({
                                "customer_id": this.id,
                                "item": element.id,
                                "platform": plat,
                                "tag": this.typeAccSelcet
                            });
                            }else if(this.accountAff==0 && this.typeAccSelcet=='' && (this.selectedSize>=50.000)){
                                let sizeFree=0
                                if(this.selectedSize==50.000){
                                    sizeFree=5.000
                                }else if(this.selectedSize==100.000){
                                    sizeFree=10.000
                                }else if(this.selectedSize==200.000){
                                    sizeFree=25.000
                                }
                                let freeElementID= `${sizeFree}K${this.selectedCurrency}${type.split('%')[0]}`
                                console.log(freeElementID)
                                itemsToAdd.push(
                                    {
                                    "customer_id": this.id,
                                    "item": element.id,
                                    "platform": plat,
                                    "tag": this.typeAccSelcet
                                    },
                                    {
                                    "customer_id": this.id,
                                    "item": freeElementID,
                                    "platform": plat,
                                    "tag": 'B1G1'
                                    },
                                );
                            }
                        }else{
                            itemsToAdd.push({
                                "customer_id": this.id,
                                "item": element.id,
                                "platform": plat,
                                "tag": this.typeAccSelcet
                            });
                        }

                        // UTM Campaign management
                        let utm_medium = this.$cookies.get('utm_medium');
                        let utm_campaign = this.$cookies.get('utm_campaign');

                        if (utm_medium || utm_campaign) {
                            itemsToAdd.ref_origin = {
                                utm_medium: utm_medium || '',
                                utm_campaign: utm_campaign || ''
                            };
                        }

                        try {
                            const res = window.sendinblue.track(
                                'cart_updated',
                                {
                                    email: this.client.email,
                                    FIRSTNAME: this.client.firstName,
                                    LASTNAME: this.client.lastName,
                                },
                                {
                                    data: {
                                        ITEM: element.id,
                                        PRICE: element.price,
                                        CURR: this.selectedCurrency,
                                        SIZE: new Intl.NumberFormat('en-US').format(this.selectedSize * 1000),
                                        NAME: element.name,
                                    }
                                }
                            );
                        } catch (e) {
                            console.error(e);
                        }
                        try {
                            this.$gtm.trackEvent({
                                event: 'add_to_cart',
                                value: {
                                    ITEM: element.id,
                                    PRICE: element.price,
                                    CURR: this.selectedCurrency,
                                    SIZE: new Intl.NumberFormat('en-US').format(this.selectedSize * 1000),
                                }
                            })
                        } catch (e) {
                            console.error('gtm', e);
                        }
                        //End UTC

                        //Api Add item to Cart
                        for (const element of itemsToAdd) {
                            console.log("element", element);
                            try {
                                let config = {
                                    method: 'post',
                                    maxBodyLength: Infinity,
                                    url: `${this.$baseUrl}/ecommerce/cart/addItem`,
                                    headers: { 'Content-Type': 'application/json' },
                                    data: element
                                };
                                const response = await axios.request(config);
                                console.log(response);
                            } catch (error) {
                                console.error(error);
                            }
                        }
                        this.$router.push('/checkout')
                    }
                }))
            }else if( this.selectedSize==''|| this.selectedSize==0){
                this.showModalSelections=true
                this.textErrCat='Choose all option for the account'
            }

        },
        clientInfo(){
            axios.get(`${this.$baseUrl}/customers/getCustomerInfo/${this.$cookies.get('emailAccount')}`).then((response)=>{
                this.client= response.data
                this.firstName=this.client.firstName
                this.lastName=this.client.lastName
                this.address=this.client.address
                this.zip=this.client.zip
                this.city=this.client.city
                this.country=this.client.country
                this.phone=this.client.phone
                this.birth= this.client.birthday
                this.id=this.client._id
                this.email=this.client.email

                //check se nel carrello ha item -> banner checkout
                axios.get(`${this.$baseUrl}/ecommerce/cart/getCart?customer_id=${this.id}`).then((response)=>{
                    let tagCart=response.data.tag
                    this.sizes.forEach(element => {
                        if(response.data.items[0] && element.id == response.data.items[0].itemId){
                            this.order=element
                        }
                        if( (tagCart=="black-friday"||tagCart=='black-friday1') && this.order!=''){ //check BlackFriday
                            this.modalOnBF=true
                        }
                        else if(this.order!='' ){
                            this.modalOn = true;
                        }
                    });
                })
                //check Acc del costumer per B1G1
                axios.get(`${this.$baseUrl}/accounts/getAllCustomerAccounts/${this.id}`).then((response)=>{
                    console.log(response.data.accounts)
                    this.accountAff= response.data.accounts.length
                })
            })
        },
        choosingAcc(n) {
            this.selectedSize=n
            console.log(n)
            this.accsizes = [];
            this.sizes.forEach((element) => {
                const idParts = element.id.match(/\d+/);  //Trova tutte le sequenze di numeri nell'ID
                if (idParts && idParts.includes(n.toString())) {
                    this.accsizes.push(element);
                }
            });
            const now = new Date();
            const year = now.getFullYear();
            const startDate30 = new Date(year, 1,21, 14 ,30);
            const endDate30 = new Date(year, 2,7,23,59);
            const startDate50=new Date(year, 2,11)
            const endDate50 = new Date(year, 2,25,23,59);
            if(now>=startDate30 && now<=endDate30){
                this.accsizes.forEach((element)=>{
                    element.off30= element.price*70/100
                })
            }else if(now>=startDate50 && now<=endDate50){
                this.accsizes.forEach((element)=>{
                    element.off50= element.price*50/100
                })
            }
        },
        updateCountdown() {
            const now = new Date();
            const year = now.getFullYear();
            const startDate30 = new Date(year, 1,21, 14,30);
            const endDate30 = new Date(year, 2,7,23,59); // Febbraio è il mese 1 (zero-based)
            const diff30 = endDate30 - now;

            const startDate50 = new Date(year, 2,11);
            const endDate50 = new Date(year, 2,25,23,59); // Febbraio è il mese 1 (zero-based)
            const diff50 = endDate50 - now;
            if(now>=startDate30 && now<=endDate30){
                this.showCountdown=true
                const days = Math.floor(diff30 / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diff30 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((diff30 % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff30 % (1000 * 60)) / 1000);

                document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
                + minutes + "m " + seconds + "s ";

                // Aggiornamento della barra di progresso
                const totalDuration = endDate30 - startDate30;
                const elapsedTime = now - startDate30;
                const progress = (elapsedTime / totalDuration) * 100;
                document.getElementById("progressBar").style.width = (100 - progress) + "%";
                this.countDownAVG='30%'
            }else if(now>=startDate50 && now<=endDate50){
                this.showCountdown=true
                const days = Math.floor(diff50 / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diff50 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((diff50 % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff50 % (1000 * 60)) / 1000);

                document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
                + minutes + "m " + seconds + "s ";

                // Aggiornamento della barra di progresso
                const totalDuration = endDate50 - startDate50;
                const elapsedTime = now - startDate50;
                const progress = (elapsedTime / totalDuration) * 100;
                document.getElementById("progressBar").style.width = (100 - progress) + "%";
                this.countDownAVG='50%'
           }else{
            this.showCountdown=false
           }
        },
        timingPromo(){
            setInterval(this.updateCountdown, 1000);
        }
    },
    mounted(){
        this.$cookies.set('typeBuy', "")
        this.getItems()
        this.timingPromo()
    },
    beforeMount(){
        this.modalOn=false
        this.modalOnBF=false
        if(!this.$cookies.get('emailAccount')){
            this.$router.push({ path: '/',})
        }
    }

}
</script>

<style scoped>
.positionPopUp{
    position: absolute;
    top: 10%;
    right: 50%;
    transform: translate(50%,50%);
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.opacita{
    background-color: rgba(194,79,53,0.2);
}
#flexSwitchCheckDefault{
    background-color: rgb(231, 176, 142);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}
#flexSwitchCheckDefault:checked{
    background-color: rgb(251, 144, 77);
}
.openDettails{
    z-index: 99999;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#progressBarContainer {
    width: 200px;
    background-color: white;
    padding: 2px;
    border-radius: 5px;
    margin-top: 10px;
}

#progressBar {
    height: 5px;
    width: 100%;
    background-color: red;
    text-align: center;
    line-height: 5px;
    color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 1400px) {
    .openDettails{
        z-index: 99999;
        padding: 10px;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 40%);
        width: 90%;
    }
}
</style>
