<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar"  >
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar"  >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <div class="page-wrapper" :class="showRank?'opacity-25':''" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid pt-5" >
                <h2 class="text-white fw-semibold">{{ $t('sidebar-tradingCalendar') }}</h2>
                <div class="d-flex justify-content-between col-12">
                    <div class=" d-none d-lg-flex flex-row justify-content-between">
                        <select name="profitType" id="" v-model="selectedTypeProfit" class="p-2 text-white">
                            <option class="p-2 text-white" value="pDay" :selected="selectedTypeProfit=='pDay'" >{{ $t('ProfitDay') }}</option>
                            <option class="p-2 text-white" value="pAvg" :selected="selectedTypeProfit=='pAvg'" >{{ $t('ProfitFactor') }}</option>
                        </select>
                    </div>
                    <div class="d-flex flex-row justify-content-between" style="display: flex!important;">
                        <select name="account" id="" v-model="selectedAccount" class="p-2 text-white">
                            <option class="p-2 text-white" v-for="(account, index) in accounts" :selected="index==0" :key="account._id" :value="account.userID">Account {{ account.username }}</option>
                        </select>
                    </div>
                </div>
                <!--- Start Content --->
                <v-calendar is-dark :style="clicked?'opacity:.5':''" class="custom-calendar max-w-full text-uppercase d-none d-lg-block mt-5"
                :masks="masks"
                :attributes="attributes"
                disable-page-swipe
                is-expanded
                >
                <template v-slot:day-content="{ day, attributes }" >
                    <div class="flex flex-col z-10 overflow-hidden mt-1 mx-2 p-2 calendar-box" style=" border-radius:8px ;" >
                    <span class="day-label text-md text-white">{{ day.day }}</span>
                    <div class="flex-grow overflow-y-auto overflow-x-auto" style="width: 100%;height: 100%;aspect-ratio: 1 / 1;" >
                        <div
                        @click="popUp(attr.key)"
                        v-for="attr in attributes"
                        :key="attr.key"
                        class="fw-bold fs-4 d-flex justify-content-center align-items-center rounded-sm  m-0 d-flex flex-column "
                        :class="attr.customData.title.includes('-') ? 'lostday' : (attr.customData.title.includes('No')||attr.customData.title.includes('Sem') ||attr.customData.title.includes('Nessun') ) ? 'noTrade' : 'plusday'  "
                        style="height:100%; border-radius: 10px;"
                        :style="!attr.customData.title.includes('No')? 'cursor:pointer' :''"
                        >
                        <div class="d-flex" v-if="selectedTypeProfit=='pDay'">
                            <h5 class="me-2" v-if="!attr.customData.title.includes('No') && !attr.customData.title.includes('Nessun') && !attr.customData.title.includes('Sem')">{{ currencySelAcc }}</h5>
                            <h5 v-if="!attr.customData.title.includes('No') && !attr.customData.title.includes('Nessun') && !attr.customData.title.includes('Sem')">{{ attr.customData.title }}</h5>
                            <h5 v-else>{{ $t('Notrades') }}</h5>
                        </div>
                        <div class="d-flex" v-else-if="selectedTypeProfit=='pAvg'">
                            <h5>{{ attr.customData.profitAver }} <span  v-if="!attr.customData.profitAver.includes('No')">%</span></h5>
                        </div>
                        <span v-if="attr.customData.title.includes('-')" class=" pt-3 text-white fs-6 fw-normal text-capitalize">{{ $t('Loss') }}</span>
                        <span v-else-if="attr.customData.title.includes('No') || attr.customData.title.includes('Nessun') || attr.customData.title.includes('Sem')" class=" pt-3 text-white fs-6 fw-normal text-capitalize"></span>
                        <span v-else class=" pt-3 text-white fs-6 fw-normal text-capitalize">{{ $t('Profit') }}</span>
                        </div>

                    </div>
                    </div>
                </template>
                </v-calendar>
                <div v-if="clicked && !popDayDate.customData.title.includes('No')" class="popUp d-none d-lg-flex flex-column text-black " style="background-image: url(assets/images/sfondo.jpg);" >
                  <div class="d-flex justify-content-between">
                    <h2 class="text-white fw-bolder">{{ popdate }}  {{ $t('calendar-DailyOv') }}</h2>
                    <font-awesome-icon icon="fa-solid fa-circle-xmark"  style="cursor: pointer;color: white; Salign-self: flex-end;"  @click="popUp(-5)"/>
                  </div>
                    <div class="py-4">
                        <div :class="popDayDate.customData.title.includes('-')? 'text-danger': (popDayDate.customData.title.includes('No')|| popDayDate.customData.title.includes('Nessun') || popDayDate.customData.title.includes('Sem'))? 'text-white':'text-success'" class="pb-5">
                            <h5 class="text-uppercase" v-if="popDayDate.customData.title.includes('-')">{{ $t('Loss') }}</h5>
                            <h5 v-else-if="popDayDate.customData.title.includes('No') || popDayDate.customData.title.includes('Nessun')||popDayDate.customData.title.includes('Sem')"></h5>
                            <h5 class="text-uppercase" v-else>{{ $t('Profit') }}</h5>
                            <div class="d-flex">
                                <h2 v-if="!popDayDate.customData.title.includes('No') && !popDayDate.customData.title.includes('Sem')&&!popDayDate.customData.title.includes('Nessun') " class="me-2">{{ currencySelAcc }}</h2>
                                <h2 v-if="popDayDate.customData.title" class="fw-bold" >{{ popDayDate.customData.title }} </h2>
                            </div>

                        </div>
                        <div class="d-flex justify-content-between pb-2">
                            <p class="text-white">{{ $t('calendar-%totProfits') }}</p>
                            <p v-if="popDayDate.customData.profitAver" :class="popDayDate.customData.profitAver<0? 'text-danger':'text-success'" >{{ popDayDate.customData.profitAver}} %</p>
                        </div>
                        <hr class="text-white-50">
                        <div class="d-flex justify-content-between pb-2">
                            <p class="text-white">{{ $t('calendar-DailyBal') }}</p>
                            <p v-if="popDayDate.customData.balance" class="text-white">{{currencySelAcc}} {{ popDayDate.customData.balance.toLocaleString('it-IT') }} </p>
                        </div>
                        <hr class="text-white-50">
                        <div class="d-flex justify-content-between pb-2">
                            <p class="text-white">{{ $t('calendar-lotsopen') }}</p>
                            <p v-if="popDayDate.customData.lots"  class="text-white">{{ popDayDate.customData.lots.toFixed(2) }}</p>

                        </div>
                        <hr class="text-white-50">
                        <div class="d-flex justify-content-between pb-2">
                            <p class="text-white">{{ $t('calendar-Dailygain') }}</p>
                            <p v-if="popDayDate.customData.gains" :class="popDayDate.customData.gains<0? 'text-danger':'text-success'">{{ popDayDate.customData.gains.toFixed(2) }} %</p>

                        </div>
                        <hr class="text-white-50">
                        </div>
                </div>
                <div class="d-block d-lg-none m-auto mt-5">
                    <v-date-picker @dayclick="showInfo(date)" daystyle="height:100px" v-model="date"  is-dark  :attributes="attributesMobile" is-expanded   style="width:100%; background-color:transparent ;border:none"/>
                    <div v-if="info.title" class="text-center my-4 p-4 " style="color: white; border-radius: 10px;">
                        <div class="py-4">
                            <div :class="info.title.includes('-')? 'text-danger':(info.title.includes('No')||info.title.includes('Nessun')||info.title.includes('Sem'))? 'text-white':'text-success'" class="pb-5">
                                <h5 class="text-uppercase" v-if="info.title.includes('-')">{{ $t('Loss') }}</h5>
                                <h5 v-else-if="info.title.includes('No')"></h5>
                                <h5 class="text-uppercase" v-else>{{ $t('Profit') }}</h5>
                            <div style="display: flex!important; justify-content: center;">
                                    <h2 class="me-2" v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') ">{{ currencySelAcc }}</h2>
                                    <h2 v-if="info.title && !info.title.includes('No') && !info.title.includes('Nessun') && !info.title.includes('Sem')" class="fw-bold" >{{ info.title }} </h2>
                                    <h2 v-else class="fw-bold" >{{ $t('Notrades') }} </h2>
                            </div>
                            </div>
                            <hr class="text-white-50" v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') ">
                            <div v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="d-flex justify-content-between pb-2">
                                <p class="text-white-50">{{ $t('calendar-%totProfits') }}</p>
                                <p v-if="info.profitAver" :class="info.profitAver<0? 'text-danger':'text-success'" >{{ info.profitAver }} %</p>
                            </div>
                            <div v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="d-flex justify-content-between pb-2">
                                <p class="text-white-50">{{ $t('calendar-DailyBal') }}</p>
                                <p v-if="info.balance" class="text-white">{{ info.balance }}</p>
                            </div>
                            <hr v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="text-white-50">
                            <div v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="d-flex justify-content-between pb-2">
                                <p class="text-white-50">{{ $t('calendar-daylots') }}</p>
                                <p v-if="info.lots"  class="text-white">{{ info.lots.toFixed(2) }}</p>

                            </div>
                            <hr v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="text-white-50">
                            <div v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="d-flex justify-content-between pb-2">
                                <p class="text-white-50">{{ $t('calendar-Dailygain') }}</p>
                                <p v-if="info.gains" :class="info.gains<0? 'text-danger':'text-success'">{{ info.gains.toFixed(2) }} %</p>
                            </div>
                            <hr v-if="!info.title.includes('No')&&!info.title.includes('Nessun') &&!info.title.includes('Sem') " class="text-white-50">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RankModal :showRank="showRank" @close="closeRank"/>
        <Footer/>
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import Spinner from './Spinner'
import BannerKyc from './statics/bannerKyc';
import RankModal from "./RankModal";

export default {
    name: "Calendar",
    components: {Footer, Navbar, Sidebar,Spinner,BannerKyc, RankModal},
    data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
        selectedTypeProfit:'pDay',
        popdate:'',
        popDayDate:{},
        clicked:false,
        selectedAccount:'',
        accounts:[],
        emptyDays:[],
        loading:true,
        info:{},
        dateClicked:[],
        currencySelAcc:'',
        masks: {
            weekdays: 'WWW'
        },
        attributes: [],
        attributesMobile: [],
        showRank:false,
        tradesLang: {
            en :'No Trades',
            it :'Nessun Trade',
            es :'No Canjes',
            pt :'Sem negociações',
        }
    };
  },
  mounted() {
   this.getaccounts()

  },
  methods:{
    closeRank(){
            this.showRank=false
        },
        watchRank(value){
            console.log(value)
            this.showRank=value
        },
    popUp($event){
        if(this.clicked){
            this.clicked=false
        }
        else{
        this.attributes.forEach(element=>{
            if(element.key==$event){
                this.popDayDate=element
                if(!this.popDayDate.customData.title.includes('No')){
                    this.clicked=true
                }
            }
        })}
        this.popdate=this.popDayDate.dates.toLocaleString().split(',')[0]

    },
     isSameDate(date1, date2) {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
        },
    getaccounts(){
        this.accounts=[]
        let list = (sessionStorage.getItem('accounts'))
        let elemento=JSON.parse(list).list_account
        elemento.forEach((elemento)=>{
            let acc= (sessionStorage.getItem(elemento))
            let element= JSON.parse(acc)
            if(!this.accounts.includes(element))
            this.accounts.push(element)
        })
        if(this.accounts.length>0){
            this.loading=false
        }
        this.info={}
        this.popDayDate={}
        this.popdate=''
        if(this.selectedAccount.length>0){
        }else{
            this.selectedAccount= this.accounts[0].userID
        }

    },
    getMetrics(){
        axios.get(`${this.$baseUrl}/accounts/getAccountInfo/${this.selectedAccount}`).then((response)=>{
                this.currencySelAcc=''
            this.currencySelAcc = new Intl.NumberFormat('en-US', { style: 'currency', currency: `${response.data.currency}` }).formatToParts(1)[0].value;
        })
        axios.get(`${this.$baseUrl}/getUserMetrics?user_id=${this.selectedAccount}`).then((response)=>{
            const dataInizio = new Date(response.data.dailyGrowth[0].date);
            const dataOggi = new Date();
            let dataCorrente = new Date(dataInizio);
            const profittoTotale= response.data.profit
            console.log(profittoTotale)
            while (dataCorrente <= dataOggi) {
            const dataEsistente = response.data.dailyGrowth.some((oggetto) =>
                this.isSameDate(new Date(oggetto.date), dataCorrente)
            );
            if (!dataEsistente) {
                response.data.dailyGrowth.push({
                date: new Date(dataCorrente),
                });
            }
            dataCorrente.setDate(dataCorrente.getDate() + 1);
            }
            response.data.dailyGrowth.forEach((element,index) => {
                let profitfix=''
                let profitAverage=''
                if(element.profit){
                     profitfix=`${element.profit.toFixed(2)}`
                     profitAverage= ((element.profit*100)/profittoTotale).toFixed(2)

                }else{
                    let tradeText= this.tradesLang[this.$cookies.get('client').language]
                    profitfix=tradeText
                    profitAverage=tradeText
                }
                this.attributes.push({
                    key:index,
                    customData:{
                        title : profitfix ,
                        profitAver: profitAverage,
                        pips: element.pips,
                        lots:element.lots,
                        gains:element.gains,
                        balance:element.balance,
                        currency: element.currency || ''
                    },
                    dates: new Date(element.date)

                })
                this.attributesMobile.push({
                    key:index,
                    customData:{
                        title : profitfix,
                        profitAver: profitAverage,
                        pips: element.pips,
                        lots:element.lots,
                        gains:element.gains,
                        balance:element.balance
                    },
                    dates: new Date(element.date)

                })

                const today = new Date();

                // Crea una data di riferimento per il primo gennaio dell'anno corrente
                const startDate = new Date(today.getFullYear(), 0, 1);

                // Crea un array per le date
                const datesArray = [];
                // Itera attraverso le date dall'inizio dell'anno a oggi
                for (let date = startDate; date <= today; date.setDate(date.getDate() + 1)) {
                datesArray.push(new Date(date));
                }
                this.attributesMobile.forEach(item => {
      const title = item.customData.title;
      if(title && title.includes('-')){
        item.highlight = {
          color: 'red',
          fillMode: 'light',
        };
      }else if (title && title.includes('No')){
        item.highlight={
            color: 'gray',
            fillMode:'light'
        }
      }else if (title && title.includes('Nessun')){
        item.highlight={
            color: 'gray',
            fillMode:'light'
        }
      }else if (title && title.includes('Sem')){
        item.highlight={
            color: 'gray',
            fillMode:'light'
        }
      }else if (title) {
        // Apply 'highlight' to the item
        item.highlight = {
          color: 'green',
          fillMode: 'light',
        };
      }
    });

        });

        })
    },
    showInfo(event){
            this.attributesMobile.forEach((element)=>{
            if(element.dates.getFullYear() === event.getFullYear()){

                if(element.dates.getMonth() === event.getMonth()){

                    if(element.dates.getDate() === event.getDate()){

                        this.dateClicked.push(element)

                    }
                }
            }
        })
        if(this.dateClicked.length==1){

            this.info=this.dateClicked[0].customData
            this.dateClicked=[]
        }else{
            this.info={}
        }

    }
  },
  watch: {
        selectedAccount() {
        this.currencySelAcc=''
        this.attributes=[]
        this.attributesMobile=[]
        this.getMetrics()
    }
  },
  beforeMount(){
        if(!this.$cookies.get('emailAccount') ){
            this.$router.push({ path: '/',})
        }
    }
}
</script>

<style scoped>
@import 'v-calendar/dist/style.css';
.custom-calendar.vc-container {
 border:none;
  border-radius: 0;
  width: 100%;
    height:100%;
    background-color:transparent!important ;
}

.lostday{
    background-color: rgba(244, 129, 129, 0.1);
    border: 1px solid #EB5757;
    color: #EB5757;
}

.noTrade{
    background-color: rgba(90, 89, 89, 0.1);
    border: 1px solid rgb(46, 46, 46);
    color: white;
}
.plusday{
    background-color: rgb(158, 242, 165, 0.1);
    border: 1px solid #6FCF97;
    color:#6FCF97
}

.reviewday{
    background-color: rgba(245, 251, 168, 0.2);
    border: 2px solid rgb(255, 230, 0);
}
.reviewday:hover{
    box-shadow: 0 0 25px rgb(255, 230, 0);
}
.popUp{

    background-size: cover;
    background-attachment: fixed;
    width: 800px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
    padding: 50px;
    border-radius: 10px;
    border: 1px solid rgb(98, 98, 98);
}
.ill:hover{
    box-shadow:5px 5px 30px -10px #ffffff;
}
select{
    background-color:#010916;
}

</style>
