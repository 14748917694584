<template >
<div style="background-image: url(assets/images/sfondo.jpg);background-size: cover;background-attachment: fixed; height: 100vh; ">
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400">
  <circle id="arc5" class="circle" cx="200" cy="200" r="120" opacity=".89" fill="none" stroke="#632b26" stroke-width="8" stroke-linecap="square" stroke-opacity=".99213" paint-order="fill markers stroke"/>
  <circle id="arc6" class="circle" cx="200" cy="200" r="100" opacity=".49" fill="none" stroke="#632b26" stroke-width="16" stroke-linecap="square" stroke-opacity=".99213" paint-order="fill markers stroke"/>
  <circle id="arc7" class="circle" cx="200" cy="200" r="100" opacity=".89" fill="none" stroke="#632b26" stroke-width="8" stroke-linecap="square" stroke-opacity=".99213" paint-order="fill markers stroke"/>
  <circle id="arc8" class="circle" cx="200" cy="200" r="90" opacity=".79" fill="#4DD0E1"
fill-opacity="0"         stroke="#632b26" stroke-width="8" stroke-linecap="square" stroke-opacity=".99213" paint-order="fill markers stroke"/>

</svg>
<img class="svg-logo" src="/assets/images/OFP2.png" alt="">
</div>
</template>

<script>
export default {
name:"Spinner"
}
</script>

<style scoped>
 .svg-logo{
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%,50%);
    width: 100px;
    z-index: 999;

 }
body{
  background: #000000;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Cg fill-rule="evenodd"%3E%3Cg fill="%239C92AC" fill-opacity="0.4"%3E%3Cpath opacity=".5" d="M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z"/%3E%3Cpath d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
}

svg{
  position: absolute;
  top: 50vh;
  bottom: 0%;
  left: 0;
  right: 0;
  transform: translate(0,-50%);
  margin:100px auto;
  z-index: 999;
}
svg circle{
  stroke: #fb9678
}

.circle{
  transform-origin: center ;
}
#arc1{
  stroke-dasharray: 30;
  stroke-dashoffset: 18;
  animation: 10s -2s rota infinite linear;
}
#arc3{
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: 16s 1s rota infinite linear;
}
#arc4{
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: 6s 1s rota infinite linear reverse;
}
#arc5{
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: 4s 1s rota infinite linear;
}
#arc6{
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: 8s 1s rota infinite linear alternate;
}
#arc7{
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: 4s 1s rota infinite linear reverse;
}
text{
 fill: #ffffff;
 font: bold 24px sans-serif;
}
@keyframes rota{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
</style>
